import AppendixesCollection from "../api/agreements/AppendixesCollection";
import ArticleBundlesCollection from "../api/articles/ArticleBundlesCollection";
import ArtifactsCollection from "../api/artifacts/ArtifactsCollection";
import ExportsCollection from "../api/exports/ExportsCollection";
import ImprintsCollection from "../api/imprints/ImprintsCollection";
import OrganizationsCollection from "../api/organizations/OrganizationsCollection";
import ProductionsCollection from "../api/productions/ProductionsCollection";
import ScriptsCollection from "../api/scripts/ScriptsCollection";
import SeriesCollection from "../api/series/SeriesCollection";
import StudiosCollection from "../api/studios/StudiosCollection";
import UsersCollection from "../api/users/UsersCollection";

import createFirestoreUtils from "./createFirestoreUtils";

export default class FirestoreAPI {
	constructor(firebase, { plugins, getUser = () => null, debug = false } = {}) {
		this.debug = debug;
		this.firebase = firebase;
		this.firestore = firebase.firestore();

		this.utils = createFirestoreUtils(this.firebase, { debug: this.debug });
		this.plugins = plugins;
		this.getUser = getUser;

		this.appendixes = new AppendixesCollection(this);
		this.articleBundles = new ArticleBundlesCollection(this);
		this.artifacts = new ArtifactsCollection(this);
		this.exports = new ExportsCollection(this);
		this.imprints = new ImprintsCollection(this);
		this.organizations = new OrganizationsCollection(this);
		this.productions = new ProductionsCollection(this);
		this.scripts = new ScriptsCollection(this);
		this.series = new SeriesCollection(this);
		this.studios = new StudiosCollection(this);
		this.users = new UsersCollection(this);
	}
}
