import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import contributorRoles from "astrid-firestore/src/api/articles/constants/contributorRoles";

export default function useArticleContributorRolesTranslations() {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			[contributorRoles.AUTHOR]: t("author", "Author"),
			[contributorRoles.TRANSLATOR]: t("translator", "Translator"),
			[contributorRoles.NARRATOR]: t("narrator", "Narrator"),
		}),
		[t],
	);
}
