import React from "react";

import useCollectionDataOptions from "astrid-firestore/src/hooks/useCollectionDataOptions";

import FormDocumentSelect from "../FormSelect/FormDocumentSelect";

export default function FormCollectionSelect({ query, mapOption, ...props }) {
	const [options, loading, error] = useCollectionDataOptions(query, { mapOption });

	return <FormDocumentSelect search options={options} loading={loading} error={error} {...props} />;
}
