import firebase from "firebase";
import uniqid from "uniqid";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import updateDistributionChannel from "astrid-firestore/src/api/distribution/updateDistributionChannel";
import { Pipeline } from "astrid-firestore/src/api/pipelines/types/Pipeline";

import { useProfile } from "../../../authentication/state/profile";
import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

export default function CreateDistributionPipelineForm({ channel, onClose }) {
	const user = useProfile();
	const navigate = useNavigate();

	const { t } = useTranslation();

	const { form, onSubmit, error } = useSimpleForm({
		schema: Pipeline,
		defaultValues: {
			name: "",
		},
		onSubmit: async (data) => {
			const id = uniqid();

			await updateDistributionChannel(firebase, {
				ref: channel.ref,
				pipelines: {
					[id]: { id, ...data },
				},
				updatedBy: user,
			});

			navigate(id);
		},
	});

	return (
		<Form.Modal
			header={t("createPipeline", "Create pipeline")}
			size="tiny"
			form={form}
			error={error}
			onSubmit={onSubmit}
			onClose={onClose}
		>
			<Form.Input name="name" label={t("name", "Name")} />
		</Form.Modal>
	);
}
