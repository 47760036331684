import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function useSuperAdminUsersMenuOptions() {
	const { t } = useTranslation();

	const location = useLocation();

	return [
		{
			path: "/admin/users",
			name: t("all", "All"),
			active:
				location.pathname.includes("users") &&
				!location.pathname.includes("narrators") &&
				!location.pathname.includes("producers") &&
				!location.pathname.includes("publishers") &&
				!location.pathname.includes("unassigned"),
		},
		{
			path: "/admin/users/narrators",
			name: t("readers", "Narrators"),
		},
		{
			path: "/admin/users/producers",
			name: t("producers", "Producers"),
		},
		{
			path: "/admin/users/publishers",
			name: t("publishers", "Publishers"),
		},
		{
			path: "/admin/users/unassigned",
			name: t("unassigned", "Unassigned"),
		},
	];
}
