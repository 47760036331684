import PageView from "../../../ui/views/PageView/PageView";

import useHasRole from "../../../authorization/hooks/useHasRole";
import VendorSettings from "../../../vendors/components/VendorSettings/VendorSettings";

import UserDropdownMenu from "./components/UserDropdownMenu";
import UserLanguageSkills from "./components/UserLanguageSkills";
import UserNarratorSettings from "./components/UserNarratorSettings";
import UserOrganizations from "./components/UserOrganizations";
import UserPermissions from "./components/UserPermissions";
import UserProducersAdmin from "./components/UserProducersAdmin";
import UserStorytoolArchiveSettings from "./components/UserStorytoolArchiveSettings";
import UserVendors from "./components/UserVendors";

export default function SuperAdminUserView({ user }) {
	const hasRole = useHasRole();

	const name = `${user.firstName} ${user.lastName}`;

	return (
		<PageView
			header={name}
			extra={
				<>
					{user.email}

					<UserDropdownMenu user={user} />
				</>
			}
		>
			<UserVendors user={user} />

			{hasRole("owner") && <UserPermissions user={user} />}

			<UserStorytoolArchiveSettings user={user} />
			{hasRole("owner") && user.permissions?.role === "admin" && <UserProducersAdmin user={user} />}

			<UserNarratorSettings user={user} />

			<UserLanguageSkills user={user} />

			<VendorSettings type="user" uid={user.id} currentVendors={user.vendors} />

			<UserOrganizations user={user} />
		</PageView>
	);
}
