import selectHasRight from "astrid-permissions/selectors/selectHasRight";

import { useProfile } from "../../authentication/state/profile";
import { useOrganizationId } from "../../organizations/state/organizationId";

import useHasRole from "./useHasRole";

export default function useHasRight() {
	const profile = useProfile();
	const orgId = useOrganizationId();
	const hasRole = useHasRole();

	const hasRight = selectHasRight(profile, orgId);

	return (right, checkRole = true) => (checkRole && hasRole("owner", "admin")) || hasRight(right);
}
