import { useNavigate } from "react-router-dom";

import DataTable from "../../../ui/components/DataTable/DataTable";

import useApproveAgreementTemplatesColumns from "./hooks/useApproveAgreementTemplatesColumns";

export default function SuperAdminApproveAgreementTemplatesView({ agreementTemplates, loading, error }) {
	const navigate = useNavigate();
	const columns = useApproveAgreementTemplatesColumns();

	return (
		<DataTable
			data={agreementTemplates}
			error={error}
			loading={loading}
			columns={columns}
			onClick={({ id, firstParty }) =>
				navigate(`/${firstParty.type}/${firstParty.id}/admin/agreements/templates/${id}`)
			}
		/>
	);
}
