import { Route } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import App from "../../App";
import StudioRedirect from "../../components/StudioRedirect";
import DropboxApprove from "../../components/organization/DropboxApprove";
import ListProductionsLegacy from "../../components/production/ListProductions";
import renderComponent from "../../hocs/renderComponent";
import withMatch from "../../hocs/withMatch";
import withRight from "../../hocs/withRight";
import withUserProfile from "../../hocs/withUserProfile";
import Billing from "../../layouts/Billing";
import CalendarLegacy from "../../layouts/Calendar";
import ComputeJobs from "../../layouts/ComputeJobs";
import Distribution from "../../layouts/Distribution";
import ExternalUpload from "../../layouts/ExternalUpload";
import ForwardContext from "../../layouts/ForwardContext";
import Policy from "../../layouts/Policy";
import Publication from "../../layouts/Publication";
import PublisherLayout from "../../layouts/Publisher";
import Reader from "../../layouts/Reader";
import ReaderPreview from "../../layouts/ReaderPreview";
import ReaderSignup from "../../layouts/ReaderSignup";
import Scheduled from "../../layouts/Scheduled";
import ProducerSettings from "../../layouts/Settings";
import SharedExport from "../../layouts/SharedExport";
import Superadmin from "../../layouts/Superadmin";
import Upload from "../../layouts/Upload";
import Words from "../../layouts/Words";
import HomeOld from "../../pages/Home/HomeOld";
import PageNotFound from "../ui/pages/PageNowFound/PageNotFound";

import Account from "../account/pages/Account";
import AccountAgreements from "../account/pages/AccountAgreements";
import AccountOrders from "../account/pages/AccountOrders";
import AccountRevenues from "../account/pages/AccountRevenues";
import AccountSettings from "../account/pages/AccountSettings";
import Admin from "../admin/pages/Admin";
import AdminAgreement from "../admin/pages/AdminAgreement";
import AdminAgreementTemplate from "../admin/pages/AdminAgreementTemplate";
import AdminAgreementTemplates from "../admin/pages/AdminAgreementTemplates";
import AdminArticleArtifacts from "../admin/pages/AdminArticle/AdminArticleArtifacts";
import AdminArticleBundle from "../admin/pages/AdminArticleBundle/AdminArticleBundle";
import AdminArticleBundleArticles from "../admin/pages/AdminArticleBundle/AdminArticleBundleArticles";
import AdminArticleBundleDistribution from "../admin/pages/AdminArticleBundle/AdminArticleBundleDistribution";
import AdminArticleBundles from "../admin/pages/AdminArticles/AdminArticleBundles";
import AdminArtifacts from "../admin/pages/AdminArticles/AdminArtifacts";
import AdminArtifact from "../admin/pages/AdminArtifact/AdminArtifact";
import AdminArtifactArticles from "../admin/pages/AdminArtifact/AdminArtifactArticles";
import AdminArtifactFiles from "../admin/pages/AdminArtifact/AdminArtifactFiles";
import AdminCost from "../admin/pages/AdminCost";
import AdminDistributionSettings from "../admin/pages/AdminDistributionSettings";
import AdminExpenses from "../admin/pages/AdminExpenses";
import AdminExpiringAgreements from "../admin/pages/AdminExpiringAgreements";
import AdminImprint from "../admin/pages/AdminImprint/AdminImprint";
import AdminImprintArticles from "../admin/pages/AdminImprint/AdminImprintArticles";
import AdminImprints from "../admin/pages/AdminImprints";
import AdminInvoices from "../admin/pages/AdminInvoices";
import AdminOffer from "../admin/pages/AdminOffer";
import AdminOrder from "../admin/pages/AdminOrder";
import AdminPendingAgreements from "../admin/pages/AdminPendingAgreements";
import AdminPrice from "../admin/pages/AdminPrice";
import AdminPrices from "../admin/pages/AdminPrices";
import AdminProducerDashboard from "../admin/pages/AdminProducerDashboard";
import AdminProductions from "../admin/pages/AdminProductions/AdminProductions";
import AdminProductionsCompleted from "../admin/pages/AdminProductions/AdminProductionsCompleted";
import AdminProductionsDashboard from "../admin/pages/AdminProductions/AdminProductionsDashboard";
import AdminProductionsDrafts from "../admin/pages/AdminProductions/AdminProductionsDrafts";
import AdminProductionsOngoing from "../admin/pages/AdminProductions/AdminProductionsOngoing";
import AdminProductionsRequests from "../admin/pages/AdminProductions/AdminProductionsRequests";
import AdminProductionsUnfinalized from "../admin/pages/AdminProductions/AdminProductionsUnfinalized";
import AdminPublisher from "../admin/pages/AdminPublisher";
import AdminPublisherDashboard from "../admin/pages/AdminPublisherDashboard";
import AdminPublishers from "../admin/pages/AdminPublishers";
import AdminPurchaseOrders from "../admin/pages/AdminPurchaseOrders";
import AdminReaders from "../admin/pages/AdminReaders";
import AdminRevenues from "../admin/pages/AdminRevenues";
import AdminSalesOrders from "../admin/pages/AdminSalesOrders";
import AdminScripts from "../admin/pages/AdminScripts";
import AdminSerie from "../admin/pages/AdminSerie/AdminSerie";
import AdminSerieArticles from "../admin/pages/AdminSerie/AdminSerieArticles";
import AdminSeries from "../admin/pages/AdminSeries";
import AdminStudio from "../admin/pages/AdminStudio";
import AdminStudios from "../admin/pages/AdminStudios";
import AdminUser from "../admin/pages/AdminUser";
import AdminUsers from "../admin/pages/AdminUsers";
import CreateAgreementTemplate from "../admin/pages/CreateAgreementTemplate";
import CreatePrices from "../admin/pages/CreatePrices";
import OrganizationSettingsFinance from "../admin/pages/OrganizationSettingsFinance";
import OrganizationSettingsLanguage from "../admin/pages/OrganizationSettingsLanguage";
import OrganizationSettingsStudio from "../admin/pages/OrganizationSettingsStudio";
import OrganizationSettingsVendors from "../admin/pages/OrganizationSettingsVendors";
import PrepareAgreements from "../admin/pages/PrepareAgreements";
import adminArticlesRoute from "../admin/routes/articles/adminArticlesRoute";
import adminDistributionsRoute from "../admin/routes/distributions/adminDistributionsRoute";
import SiteLayout from "../app/layouts/SiteLayout/SiteLayout";
import Apps from "../apps/pages/Apps";
import Article from "../articles/pages/Article";
import Articles from "../articles/pages/Articles";
import Login from "../authentication/pages/Login";
import LoginForm from "../authentication/pages/LoginForm";
import ResetPassword from "../authentication/pages/ResetPassword";
import Calendar from "../calendar/pages/Calendar";
import Documents from "../documents/pages/Documents";
import Home from "../home/pages/Home";
import ListProductions from "../listproductions/pages/ListProductions";
import CompletedProductions from "../myproductions/pages/CompletedProductions";
import MyProductionOffers from "../myproductions/pages/MyProductionOffers";
import MyProductions from "../myproductions/pages/MyProductions";
import OngoingProductions from "../myproductions/pages/OngoingProductions";
import ProductionRequests from "../myproductions/pages/ProductionRequests";
import PublisherMyProductions from "../myproductions/pages/PublisherMyProductions";
import SentOffers from "../myproductions/pages/SentOffers";
import NarratorOrganizationContext from "../organizations/layouts/OrganizationContext/NarratorOrganizationContext";
import OrganizationContext from "../organizations/layouts/OrganizationContext/OrganizationContext";
import CreateProductionProducer from "../productions/pages/CreateProductionProducer";
import CreateProductionPublisher from "../productions/pages/CreateProductionPublisher";
import Production from "../productions/pages/Production";
import ProductionArticles from "../productions/pages/ProductionArticles";
import ProductionAudiobook from "../productions/pages/ProductionAudiobook";
import ProductionFinance from "../productions/pages/ProductionFinance";
import ProductionOverview from "../productions/pages/ProductionOverview";
import ProductionReaders from "../productions/pages/ProductionReaders";
import ProductionTeam from "../productions/pages/ProductionTeam";
import ProfilePage from "../profile/pages/ProfilePage";
import ProducerStatistics from "../statistics/pages/ProducerStatistics";
import StorytoolArchive from "../storytoolArchive/pages/StorytoolArchive";
import SuperAdmin from "../superadmin/pages/SuperAdmin";
import SuperAdminAgreements from "../superadmin/pages/SuperAdminAgreements/SuperAdminAgreements";
import SuperAdminAppendixes from "../superadmin/pages/SuperAdminAppendixes";
import SuperAdminApprove from "../superadmin/pages/SuperAdminApprove/SuperAdminApprove";
import SuperAdminApproveAgreementTemplates from "../superadmin/pages/SuperAdminApprove/SuperAdminApproveAgreementTemplates";
import SuperAdminApproveAgreements from "../superadmin/pages/SuperAdminApprove/SuperAdminApproveAgreements";
import SuperAdminApproveOffers from "../superadmin/pages/SuperAdminApprove/SuperAdminApproveOffers";
import SuperAdminApprovePrices from "../superadmin/pages/SuperAdminApprove/SuperAdminApprovePrices";
import SuperAdminApproveProductionPrices from "../superadmin/pages/SuperAdminApprove/SuperAdminApproveProductionPrices";
import SuperAdminArticles from "../superadmin/pages/SuperAdminArticles";
import SuperAdminBiblioCompletedTasks from "../superadmin/pages/SuperAdminBiblioCompletedTasks";
import SuperAdminBiblioSettings from "../superadmin/pages/SuperAdminBiblioSettings";
import SuperAdminBiblioTasks from "../superadmin/pages/SuperAdminBiblioTasks";
import SuperAdminBiblioUncompletedTasks from "../superadmin/pages/SuperAdminBiblioUncompletedTasks";
import SuperAdminDashboard from "../superadmin/pages/SuperAdminDashboard";
import SuperAdminDistribution from "../superadmin/pages/SuperAdminDistribution";
import SuperAdminDistributionChannel from "../superadmin/pages/SuperAdminDistributionChannel/SuperAdminDistributionChannel";
import SuperAdminDistributionChannelPipelines from "../superadmin/pages/SuperAdminDistributionChannel/SuperAdminDistributionChannelPipelines";
import SuperAdminDistributionChannelSettings from "../superadmin/pages/SuperAdminDistributionChannel/SuperAdminDistributionChannelSettings";
import SuperAdminDistributionChannels from "../superadmin/pages/SuperAdminDistributionChannels";
import SuperAdminDistributionPipeline from "../superadmin/pages/SuperAdminDistributionPipeline/SuperAdminDistributionPipeline";
import SuperAdminDistributionPipelineSettings from "../superadmin/pages/SuperAdminDistributionPipeline/SuperAdminDistributionPipelineSettings";
import SuperAdminDistributions from "../superadmin/pages/SuperAdminDistributions";
import SuperAdminProducer from "../superadmin/pages/SuperAdminProducer";
import SuperAdminProducers from "../superadmin/pages/SuperAdminProducers";
import SuperAdminProductionPrice from "../superadmin/pages/SuperAdminProductionPrice";
import SuperAdminPublisher from "../superadmin/pages/SuperAdminPublisher";
import SuperAdminPublishers from "../superadmin/pages/SuperAdminPublishers";
import SuperAdminUser from "../superadmin/pages/SuperAdminUser";
import SuperAdminUsers from "../superadmin/pages/SuperAdminUsers";

const routes = (
	<Route path="/" element={<App />}>
		<Route path="readerPreview/:ids" element={renderComponent(withMatch(ReaderPreview))} />
		<Route path="words" element={renderComponent(withMatch(Words))}>
			<Route path=":productionId/:email" element={null} />
			<Route path=":productionId" element={null} />
		</Route>
		<Route path="external/upload/:publisherId/:language" element={renderComponent(withMatch(ExternalUpload))} />
		<Route path="export" element={renderComponent(withMatch(SharedExport))}>
			<Route path=":productionId/:article/:id" element={null} />
			<Route path=":productionId/:article" element={null} />
			<Route path=":productionId" element={null} />
		</Route>
		<Route path="policy" element={<Policy />} />
		<Route path="login" element={<Login />}>
			<Route index element={<LoginForm />} />
			<Route path="reset" element={<ResetPassword />} />
			<Route path=":provider" element={<LoginForm />} />
		</Route>
		<Route element={<SiteLayout />}>
			<Route index element={<Home />} />
			<Route path="studio-redirect" element={<StudioRedirect />} />
			<Route path="home-old" element={renderComponent(withMatch(withUserProfile(HomeOld)))} />
			<Route path="apps" element={<Apps />}>
				<Route path=":channel" element={null} />
			</Route>
			<Route path="superadmin" element={renderComponent(withMatch(withUserProfile(Superadmin)))} />
			<Route path="computejobs" element={renderComponent(withMatch(withUserProfile(ComputeJobs)))} />
			<Route path="upload" element={renderComponent(withMatch(withUserProfile(Upload)))} />
			<Route path="settings/dropbox" element={renderComponent(withMatch(withUserProfile(DropboxApprove)))} />
			<Route path="settings" element={renderComponent(withMatch(withUserProfile(ProducerSettings)))}>
				<Route path=":producerId" element={null} />
			</Route>

			<Route path="billing" element={renderComponent(withMatch(withUserProfile(Billing)))} />

			<Route
				path="distribution"
				element={renderComponent(withMatch(withRight(Distribution, "distribution.editDistributors")))}
			/>

			<Route
				path="scheduled"
				element={renderComponent(withMatch(withRight(Scheduled, "distribution.editDistributors")))}
			/>

			<Route path="reader" element={<Reader />} />

			<Route path="readerSignup" element={<ReaderSignup signedIn />} />

			<Route path="readerPreview/:ids" element={renderComponent(withMatch(withUserProfile(ReaderPreview)))} />

			<Route path="words" element={renderComponent(withMatch(withUserProfile(Words)))}>
				<Route path=":productionId" element={null} />
			</Route>
			<Route path="export" element={renderComponent(withMatch(SharedExport))}>
				<Route path=":productionId/:article/:id" element={null} />
				<Route path=":productionId/:article" element={null} />
				<Route path=":productionId" element={null} />
			</Route>
			<Route path="profile/:userId" element={<ProfilePage />} />
			<Route
				path="calendar"
				element={renderComponent(withMatch(withRight(withUserProfile(CalendarLegacy), "viewCalendar")))}
			>
				<Route path=":productionId" element={null} />
			</Route>
			<Route path="publication" element={renderComponent(withMatch(withUserProfile(Publication)))} />
			<Route path="production" element={renderComponent(withMatch(withUserProfile(ListProductionsLegacy)))} />
			<Route path="production/:productionId" element={<Production />}>
				<Route index element={<ProductionOverview />} />
				<Route path="info" element={<ProductionOverview />}>
					<Route path=":modal" element={null} />
				</Route>
				<Route path="discussion" element={<ProductionOverview discussion />} />
				<Route path="discussionInternal" element={<ProductionOverview discussion="internal" />} />
				<Route path="articles" element={<ProductionArticles />} />
				<Route path="audiobook" element={<ProductionAudiobook tab="audiobook" />} />
				<Route path="readers" element={<ProductionReaders />} />
				<Route path="team" element={<ProductionTeam />} />
				<Route path="finance" element={<ProductionFinance />}>
					<Route path=":partyId" element={null} />
				</Route>
			</Route>
			<Route path="policy" element={<Policy />} />
			<Route path="admin" element={<SuperAdmin />}>
				<Route index element={<SuperAdminDashboard />} />

				<Route path="approve" element={<SuperAdminApprove />}>
					<Route path="costs" element={<SuperAdminApproveProductionPrices />} />
					<Route path="prices" element={<SuperAdminApprovePrices />} />
					<Route path="offers" element={<SuperAdminApproveOffers />} />
					<Route path="agreements" element={<SuperAdminApproveAgreements />} />
					<Route path="templates" element={<SuperAdminApproveAgreementTemplates />} />
				</Route>

				<Route
					path="producers"
					element={<ForwardContext />}
					handle={{ crumb: ({ t }) => t("producers", "Producers") }}
				>
					<Route index element={<SuperAdminProducers />} />
					<Route
						path=":id"
						element={<SuperAdminProducer />}
						handle={{ crumb: ({ organization }) => organization?.name }}
					/>
				</Route>

				<Route
					path="publishers"
					element={<ForwardContext />}
					handle={{ crumb: ({ t }) => t("publishers", "Publishers") }}
				>
					<Route index element={<SuperAdminPublishers />} />
					<Route
						path=":id"
						element={<SuperAdminPublisher />}
						handle={{ crumb: ({ organization }) => organization?.name }}
					/>
				</Route>

				<Route path="articles" element={<ForwardContext />}>
					<Route index element={<SuperAdminArticles />} />
				</Route>

				<Route path="imprints" element={<ForwardContext />}>
					<Route index element={<AdminImprints />} />
				</Route>

				<Route path="series" element={<ForwardContext />}>
					<Route index element={<AdminSeries />} />
				</Route>

				<Route path="distribution" element={<SuperAdminDistribution />}>
					<Route path="distributions" element={<ForwardContext />}>
						<Route index element={<SuperAdminDistributions />} />
					</Route>

					<Route path="channels" element={<SuperAdminDistributionChannels />} />
				</Route>

				<Route path="distribution/channels/:channel" element={<SuperAdminDistributionChannel />}>
					<Route index element={<SuperAdminDistributionChannelSettings />} />

					<Route path="pipelines">
						<Route index element={<SuperAdminDistributionChannelPipelines />} />

						<Route path=":id" element={<SuperAdminDistributionPipeline />}>
							<Route index element={<SuperAdminDistributionPipelineSettings />} />
						</Route>
					</Route>
				</Route>

				<Route path="users" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("users", "Users") }}>
					<Route index element={<SuperAdminUsers />} />

					<Route
						path="narrators"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("narrators", "Narrators") }}
					>
						<Route index element={<SuperAdminUsers type="narrator" />} />
						<Route
							path=":id"
							element={<SuperAdminUser />}
							handle={{ crumb: ({ user }) => user?.firstName + " " + user?.lastName }}
						/>
					</Route>

					<Route
						path="producers"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("producers", "Producers") }}
					>
						<Route index element={<SuperAdminUsers type="producer" />} />
						<Route
							path=":id"
							element={<SuperAdminUser />}
							handle={{ crumb: ({ user }) => user?.firstName + " " + user?.lastName }}
						/>
					</Route>

					<Route
						path="publishers"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("publishers", "Publishers") }}
					>
						<Route index element={<SuperAdminUsers type="publisher" />} />
						<Route
							path=":id"
							element={<SuperAdminUser />}
							handle={{ crumb: ({ user }) => user?.firstName + " " + user?.lastName }}
						/>
					</Route>

					<Route
						path="unassigned"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("unassigned", "Unassigned") }}
					>
						<Route index element={<SuperAdminUsers type="unassigned" />} />
						<Route
							path=":id"
							element={<SuperAdminUser />}
							handle={{ crumb: ({ user }) => user?.firstName + " " + user?.lastName }}
						/>
					</Route>

					<Route
						path=":id"
						element={<SuperAdminUser />}
						handle={{ crumb: ({ user }) => user?.firstName + " " + user?.lastName }}
					/>
				</Route>

				<Route path="costs" element={<ForwardContext />}>
					<Route path=":productionId/:id" element={<SuperAdminProductionPrice />} />
				</Route>
				<Route path="agreements" element={<SuperAdminAgreements />}>
					<Route path="appendixes" element={<SuperAdminAppendixes />} />
				</Route>
				<Route path="biblio" element={<ForwardContext />}>
					<Route path="settings" element={<SuperAdminBiblioSettings />} />
					<Route path="tasks" element={<SuperAdminBiblioTasks />}>
						<Route index element={<SuperAdminBiblioUncompletedTasks />} />
						<Route path="completed" element={<SuperAdminBiblioCompletedTasks />} />
						<Route path="uncompleted" element={<SuperAdminBiblioUncompletedTasks />} />
					</Route>
				</Route>
			</Route>
			<Route path="account/:id" element={<Account />}>
				<Route path="agreements" element={<AccountAgreements />} />
				<Route path="revenues" element={<AccountRevenues />} />
				<Route path="orders" element={<AccountOrders />} />
				<Route path="settings" element={<AccountSettings />} />
			</Route>
			<Route path="producer/:orgId" element={<OrganizationContext />}>
				<Route path="create-production" element={<CreateProductionProducer />} />
				<Route path="articles/:id" element={<Article />} />
				<Route path="admin" element={<Admin />}>
					<Route index element={<AdminProducerDashboard />} />

					<Route path="settings" element={<ForwardContext />}>
						<Route path="studio" element={<OrganizationSettingsStudio />} />
						<Route path="vendors" element={<OrganizationSettingsVendors />} />
						<Route path="finance" element={<OrganizationSettingsFinance />} />
						<Route path="language" element={<OrganizationSettingsLanguage />} />
					</Route>

					<Route
						path="agreements"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("agreements", "Agreements") }}
					>
						<Route index element={<AdminPendingAgreements />} />
						<Route path=":id" element={<AdminAgreement />} handle={{ crumb: ({ id }) => id }} />
					</Route>

					<Route
						path="agreements-expiring"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("expiringAgreements", "Expiring agreements") }}
					>
						<Route index element={<AdminExpiringAgreements />} />
						<Route path=":id" element={<AdminAgreement />} handle={{ crumb: ({ id }) => id }} />
					</Route>

					<Route
						path="agreements-prepare"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("prepareAgreements", "Prepare agreements") }}
					>
						<Route index element={<PrepareAgreements />} />
						<Route path=":templateId" element={<PrepareAgreements />} />
					</Route>

					<Route
						path="agreements-templates"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("agreementTemplates", "Agreement templates") }}
					>
						<Route index element={<AdminAgreementTemplates />} />
						<Route path=":id" element={<AdminAgreementTemplate />} handle={{ crumb: ({ id }) => id }} />
						<Route path="create" element={<CreateAgreementTemplate />} />
					</Route>

					<Route
						path="prices"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("globalPrices", "Global prices") }}
					>
						<Route index element={<AdminPrices />} />
						<Route path=":id" element={<AdminPrice />} handle={{ crumb: ({ id }) => id }} />
						<Route path="create" element={<CreatePrices />} />
					</Route>

					<Route path="users" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("users", "Users") }}>
						<Route index element={<AdminUsers />} />
						<Route
							path=":id"
							element={<AdminUser />}
							handle={{ crumb: ({ user }) => user.firstName + " " + user.lastName }}
						/>
					</Route>

					<Route
						path="readers"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("readers", "Readers") }}
					>
						<Route index element={<AdminReaders />} />
						<Route
							path=":id"
							element={<AdminUser />}
							handle={{ crumb: ({ user }) => user.firstName + " " + user.lastName }}
						/>
					</Route>

					<Route
						path="studios"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("studios", "Studios") }}
					>
						<Route index element={<AdminStudios gen={2} />} />
						<Route
							path=":id"
							element={<AdminStudio gen={2} />}
							handle={{ crumb: ({ studio }) => studio?.name }}
						/>
					</Route>

					<Route
						path="studios-gen1"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("studios", "Studios") }}
					>
						<Route index element={<AdminStudios gen={1} />} />
						<Route
							path=":id"
							element={<AdminStudio gen={1} />}
							handle={{ crumb: ({ studio }) => studio?.name }}
						/>
					</Route>

					<Route
						path="publishers"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("publishers", "Publishers") }}
					>
						<Route index element={<AdminPublishers />} />
						<Route path=":id" element={<AdminPublisher />} handle={{ crumb: ({ id }) => id }} />
					</Route>

					<Route
						path="expenses"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("expenses", "Expenses") }}
					>
						<Route index element={<AdminExpenses />} />
						<Route path=":id" element={<AdminCost />} handle={{ crumb: ({ id }) => id }} />
					</Route>

					<Route
						path="revenues"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("revenues", "Revenues") }}
					>
						<Route index element={<AdminRevenues />} />
						<Route path=":id" element={<AdminCost />} handle={{ crumb: ({ id }) => id }} />
					</Route>

					<Route path="purchases" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("POs") }}>
						<Route index element={<AdminPurchaseOrders />} />
						<Route path=":id" element={<AdminOrder />} handle={{ crumb: ({ id }) => id }} />
					</Route>

					<Route
						path="sales"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("salesOrders", "Sales orders") }}
					>
						<Route index element={<AdminSalesOrders />} />
						<Route path=":id" element={<AdminOrder />} handle={{ crumb: ({ id }) => id }} />
					</Route>

					<Route path="invoices" element={<ForwardContext />}>
						<Route index element={<AdminInvoices />} />
					</Route>

					<Route path="offers" element={<ForwardContext />}>
						<Route path=":id" element={<AdminOffer />} />
					</Route>

					<Route
						path="productions"
						element={<ForwardContext />}
						handle={{
							crumb: ({ t }) => t("productions", "Productions"),
						}}
					>
						<Route index element={<AdminProductionsDashboard />} />

						<Route path="all" element={<AdminProductions />} />

						<Route path="drafts" element={<AdminProductionsDrafts />} />

						<Route path="requests" element={<AdminProductionsRequests />} />

						<Route path="ongoing" element={<AdminProductionsOngoing />} />

						<Route path="completed" element={<AdminProductionsCompleted />} />

						<Route path="unfinalized" element={<AdminProductionsUnfinalized />} />
					</Route>

					<Route path="artifacts" handle={{ crumb: ({ t }) => t("artifacts", "Artifacts") }}>
						<Route index element={<AdminArticleArtifacts />} />

						<Route
							path=":artifact"
							element={<AdminArtifact />}
							handle={{ crumb: ({ artifact }) => artifact?.name }}
						>
							<Route index element={<AdminArtifactFiles />} />

							<Route path="articles" element={<AdminArtifactArticles />} />
						</Route>
					</Route>
				</Route>

				<Route path="list-productions" element={<ListProductions type={organizationTypes.PRODUCER} />} />
				<Route path="my-productions" element={<MyProductions type={organizationTypes.PRODUCER} />}>
					<Route path="current" element={<OngoingProductions />} />
					<Route path="completed" element={<CompletedProductions />} />
					<Route path="offers" element={<MyProductionOffers />} />
					<Route path="sent" element={<SentOffers />} />
					<Route path="offers/:id" element={<MyProductionOffers />} />
					<Route path="requests" element={<ProductionRequests />} />
				</Route>

				<Route path="documents" element={<Documents />} />

				<Route path="statistics" element={<ProducerStatistics />} />

				<Route path="calendar" element={<Calendar />}>
					<Route path=":productionId" element={null} />
				</Route>
			</Route>
			<Route path="publisher/:orgId" element={<OrganizationContext />}>
				<Route index element={renderComponent(withMatch(withUserProfile(PublisherLayout)))} />

				<Route path="create-production" element={<CreateProductionPublisher />}>
					<Route path=":articleId" element={null} />
				</Route>

				<Route path="articles" element={<ForwardContext />}>
					<Route index element={<Articles />} />
					<Route path=":id" element={<Article />} />
				</Route>

				<Route path="admin" element={<Admin />}>
					<Route index element={<AdminPublisherDashboard />} />

					<Route path="settings" element={<ForwardContext />}>
						<Route index path="distribution" element={<AdminDistributionSettings />} />
						<Route path="vendors" element={<OrganizationSettingsVendors />} />
						<Route path="language" element={<OrganizationSettingsLanguage />} />
						<Route path="finance" element={<OrganizationSettingsFinance />} />
					</Route>
					<Route path="agreements" element={<ForwardContext />}>
						<Route path=":id" element={<AdminAgreement />} />
					</Route>
					<Route path="users" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("users", "Users") }}>
						<Route index element={<AdminUsers />} />
						<Route
							path=":id"
							element={<AdminUser />}
							handle={{ crumb: ({ user }) => user.firstName + " " + user.lastName }}
						/>
					</Route>

					{adminArticlesRoute}

					<Route
						path="articles/bundles"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("bundles", "Bundles") }}
					>
						<Route index element={<AdminArticleBundles />} />
						<Route
							path=":bundle"
							element={<AdminArticleBundle />}
							handle={{ crumb: ({ bundle }) => bundle?.name }}
						>
							<Route index element={<AdminArticleBundleArticles />} />
							<Route path="distribution" element={<AdminArticleBundleDistribution />} />
						</Route>
					</Route>

					<Route path="articles/artifacts" element={<AdminArtifacts />} />

					<Route
						path="series"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("serie", "Series") }}
					>
						<Route index element={<AdminSeries />} />
						<Route path=":serie" element={<AdminSerie />} handle={{ crumb: ({ serie }) => serie?.name }}>
							<Route path="articles" element={<AdminSerieArticles />} />
						</Route>
					</Route>

					<Route
						path="imprints"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("imprints", "Imprints") }}
					>
						<Route index element={<AdminImprints />} />
						<Route
							path=":imprint"
							element={<AdminImprint />}
							handle={{ crumb: ({ imprint }) => imprint?.name }}
						>
							<Route path="articles" element={<AdminImprintArticles />} />
						</Route>
					</Route>

					{adminDistributionsRoute}

					<Route
						path="scripts"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("scripts", "Scripts") }}
					>
						<Route index element={<AdminScripts />} />
					</Route>

					<Route path="costs" element={<ForwardContext />}>
						<Route path="expenses" element={<AdminExpenses />} />
						<Route path=":id" element={<AdminCost />} />
					</Route>

					<Route path="orders" element={<ForwardContext />}>
						<Route path="purchases" element={<AdminPurchaseOrders />} />
						<Route path=":id" element={<AdminOrder />} />
					</Route>
				</Route>
				<Route path="list-productions" element={<ListProductions type={organizationTypes.PUBLISHER} />} />
				<Route path="my-productions" element={<PublisherMyProductions />}>
					<Route path="current" element={<OngoingProductions />} />
					<Route path="completed" element={<CompletedProductions />} />
				</Route>
			</Route>
			<Route path="narrator/:orgId" element={<NarratorOrganizationContext />}>
				<Route path="calendar" element={<Calendar />} />
				<Route path="my-productions" element={<MyProductions type={organizationTypes.NARRATOR} />}>
					<Route path="current" element={<OngoingProductions />} />
					<Route path="completed" element={<CompletedProductions />} />
					<Route path="offers" element={<MyProductionOffers />} />
					<Route path="offers/:id" element={<MyProductionOffers />} />
				</Route>
			</Route>
			<Route path="storytool-archive" element={<StorytoolArchive />} />
		</Route>

		<Route path="readerSignup" element={renderComponent(withMatch(ReaderSignup))}>
			<Route path=":referrer/:campaign" element={null} />
			<Route path=":referrer" element={null} />
		</Route>

		<Route path="*" element={<PageNotFound />} />
	</Route>
);

export default routes;
