import { useTranslation } from "react-i18next";

import useDebounceCallback from "astrid-hooks/src/useDebounceCallback";

import api from "../../../../api";
import InstantSearchSelect from "../../../ui/components/InstantSearch/InstantSearchSelect";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

import useArticleTypeOptions from "../../hooks/useArticleTypeOptions";

export default function ArticlesSearchForm({ onChange, organization }) {
	const { t } = useTranslation();

	const { form, onSubmit } = useSimpleForm({
		defaultValues: {
			searchQuery: "",
			titleId: null,
			type: null,
			serieId: null,
			imprintId: null,
			language: null,
		},
		onSubmit: useDebounceCallback(onChange, 100),
	});

	const typeOptions = useArticleTypeOptions();

	const [imprintOptions] = api.imprints.useOptions({ publisher: organization });

	return (
		<>
			<Form form={form}>
				<Form.Group widths="equal" style={{ marginBottom: 0 }}>
					<Form.Input
						name="searchQuery"
						placeholder={`${t("articleSearchQuery", "Search by title or ISBN")}...`}
						label={t("titleOrISBN", "Title or ISBN")}
						rules={{ required: false }}
					/>

					<Form.Select
						name="type"
						label={t("type", "Type")}
						options={typeOptions}
						allowEmpty
						clearable
						rules={{ required: false }}
					/>
				</Form.Group>

				<Form.Group widths="equal" style={{ marginBottom: 0 }}>
					<Form.Select
						name="imprintId"
						label={t("imprint", "Imprint")}
						options={imprintOptions}
						allowEmpty
						clearable
						rules={{ required: false }}
					/>

					<Form.LanguageSelect
						name="language"
						label={t("language", "Language")}
						rules={{ required: false }}
						allowEmpty
						clearable
					/>

					<Form.Field name="serieId" label={t("serie", "Series")} rules={{ required: false }}>
						<InstantSearchSelect
							indexName="series"
							allowEmpty
							clearable
							getOption={(data) => ({
								key: data.id,
								value: data.id,
								text: data.name,
							})}
						/>
					</Form.Field>
				</Form.Group>

				<Form.AutoSave onSubmit={onSubmit} />
			</Form>
		</>
	);
}
