import articleTypes from "astrid-firestore/src/api/articles/constants/articleTypes";

import createAudioArtifact from "./createAudioArtifact";
import createChannels from "./createChannels";
import createCover from "./createCover";
import createDocumentIdAndRef from "./createDocumentIdAndRef";
import secondsToDuration from "./secondsToDuration";

export default async function createAudiobookSeries({ api, env }, { production, commonArticleData }) {
	return await Promise.all(
		production.deliveryParts.map(async (part, index) => {
			const { id, ref } = createDocumentIdAndRef({ api, env }, { collection: "articles" });
			const article = {
				id,
				ref,
				type: articleTypes.AUDIOBOOK,
				name: production.deliveryPartsTitle || production.title,
				episodeName: part.title,
				isbn: part.isbn,
				synopsis: part.synopsis || production.synopsis || null,
				serieNumber: index + 1,
				...commonArticleData,
			};
			const cover = await createCover(
				{ api, env },
				{
					img: production.deliveryPartsCover?.[index]?.img,
					imgThumb: production.deliveryPartsCover?.[index]?.imgThumb,
				},
			);
			const artifact = await createAudioArtifact(
				{ api, env },
				{
					production,
					article,
					articleType: `part_${index}`,
				},
			);
			const duration = secondsToDuration(artifact.files.reduce((sum, file) => sum + file.duration, 0));
			const channels = await createChannels({ api, env }, { production, articleType: `part_${index}` });
			return {
				...article,
				artifact,
				cover,
				duration,
				channels,
				channelIds: Object.keys(channels),
			};
		}),
	);
}
