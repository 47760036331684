import { Outlet } from "react-router-dom";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import useNeedsApproval from "../../hooks/useNeedsApproval";
import SuperAdminApproveView from "../../views/SuperAdminApproveView/SuperAdminApproveView";

export default function SuperAdminApprove() {
	const needsApproval = useNeedsApproval();

	const { loading, error } = needsApproval;

	return (
		<LoadingContext loading={loading} error={error}>
			<SuperAdminApproveView needsApproval={needsApproval}>
				<Outlet context={{ needsApproval }} />
			</SuperAdminApproveView>
		</LoadingContext>
	);
}
