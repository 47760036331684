import { Outlet } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../authorization/constants/featureFlags";
import useFeatureFlag from "../../authorization/hooks/useFeatureFlag";
import useHasRight from "../../authorization/hooks/useHasRight";

import SuperAdminDistributionView from "../views/SuperAdminDistributionView/SuperAdminDistributionView";

export default function SuperAdminDistribution() {
	const hasRight = useHasRight();
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature || !hasRight("distributions.admin")) {
		return <Unauthorized />;
	}

	return (
		<SuperAdminDistributionView>
			<Outlet />
		</SuperAdminDistributionView>
	);
}
