import firebase from "firebase/app";

import { useState } from "react";

import getDistributionChannelsQuery from "astrid-firestore/src/api/distribution/getDistributionChannelsQuery";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";

import DataTable from "../../../ui/components/DataTable/DataTable";

import useDistributionArgsTableColumns from "../../hooks/useDistributionArgsTableColumns";

import CreateDistributionArgsForm from "../CreateDistributionCredentials/CreateDistributionArgsForm";

export default function DistributionArgsDataTable({ publisher, ...props }) {
	const [openEdit, setOpenEdit] = useState(false);
	const [distributionChannels] = useCollectionData(getDistributionChannelsQuery(firebase));

	const data =
		Object.entries(publisher?.distribution?.channels || {}).flatMap(([channelId, value]) => {
			const channel = distributionChannels?.find((channel) => channel.id === channelId);

			return Object.entries(value?.actions || {}).map(([actionName, action]) => {
				return {
					channelId: channel?.id,
					channel: channel?.name,
					action: actionName,
					args: action.args,
				};
			});
		}) || [];

	const columns = useDistributionArgsTableColumns({ publisher, setOpenEdit });

	return (
		<>
			{openEdit && (
				<CreateDistributionArgsForm
					publisher={publisher}
					editData={openEdit}
					onClose={() => setOpenEdit(false)}
				/>
			)}
			<DataTable data={data} columns={columns} {...props} />
		</>
	);
}
