import getCollectionData from "../../utils/getCollectionData";
import parseData from "../../utils/parseData";

import { Article } from "./types/Article";

export default async function validateArticleData(data, partial) {
	if (data.isbn) {
		const articles = await getCollectionData(data.ref.parent.where("isbn", "==", data.isbn).limit(2));

		if (articles.find((article) => article.id !== data.ref.id)) {
			throw new Error("An article with the same ISBN already exists");
		}
	}

	return parseData(Article, data, partial);
}
