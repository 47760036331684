import createStorageFile from "./createStorageFile";

function extractBucketInfoFromURL(url) {
	const parsedUrl = new URL(url);

	if (parsedUrl.hostname === "firebasestorage.googleapis.com") {
		const bucket = parsedUrl.pathname.split("/")[3];
		const objectPath = parsedUrl.pathname.split("/")[5];
		return {
			bucket: bucket,
			path: decodeURIComponent(objectPath),
		};
	}

	if (parsedUrl.hostname === "storage.googleapis.com") {
		const bucket = parsedUrl.pathname.split("/")[1];
		const objectPath = parsedUrl.pathname.split("/").slice(2).join("/");
		return {
			bucket: bucket,
			path: decodeURIComponent(objectPath),
		};
	}

	throw new Error("Unknown URL format");
}

export default async function createCover({ api, env }, { img, imgThumb }) {
	if (img) {
		const { bucket, path } = extractBucketInfoFromURL(img);
		const [metadata] = await api[env].getStorageMetadata(bucket, path);
		return createStorageFile({
			file: { url: img, fullPath: path, thumbUrl: imgThumb, size: Number(metadata.size) },
			metadata,
		});
	}
	return null;
}
