import { useNavigate } from "react-router-dom";

import DataTable from "../../../ui/components/DataTable/DataTable";

import useApprovePricesColumns from "./hooks/useApprovePricesColumns";

export default function SuperAdminApprovePricesView({ prices, loading, error }) {
	const navigate = useNavigate();
	const columns = useApprovePricesColumns();

	return (
		<DataTable
			data={prices}
			error={error}
			loading={loading}
			columns={columns}
			onClick={({ id, producer }) => navigate(`/producer/${producer.id}/admin/prices/${id}`)}
		/>
	);
}
