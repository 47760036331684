import db from "astrid-firebase/src/db";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import AdminActions from "../../components/AdminActions/AdminActions";

import CostInfo from "./components/CostInfo";

export default function AdminCostView({ id }) {
	const [cost, loading, error] = useDocumentData(id && db.collection("costs").doc(id));

	return (
		<LoadingContext data={cost} loading={loading} error={error}>
			{cost && (
				<>
					<CostInfo cost={cost} />

					<AdminActions data={cost}>
						<AdminActions.DeletionAction right="costs.delete" />
					</AdminActions>
				</>
			)}
		</LoadingContext>
	);
}
