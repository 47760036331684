import React from "react";
import { useTranslation } from "react-i18next";

import agreementStatuses from "astrid-firestore/src/api/agreements/constants/agreementStatuses";
import useLoadMore from "astrid-firestore/src/hooks/useLoadMore";

import LoadMore from "../../../../components/LoadMore/LoadMore";
import FlexTable from "../../../ui/components/FlexTable/FlexTable";
import PageView from "../../../ui/views/PageView/PageView";

import useAgreementsQuery from "../../../agreements/hooks/useAgreementsQuery";

import useAccountAgreementsColumns from "./hooks/useAccountAgreementsColumns";

export default function AccountAgreementsView({ user }) {
	const { t } = useTranslation();

	const query = useAgreementsQuery({
		secondPartyId: user.id,
		status: agreementStatuses.SIGNED,
	});

	const [agreements = [], loading, error, loadMore] = useLoadMore(query.orderBy("expires", "desc"), { limit: 30 });

	const columns = useAccountAgreementsColumns();

	return (
		<PageView header={t("agreements", "Agreements")}>
			<FlexTable selectable data={agreements} columns={columns} />

			{loadMore && (
				<LoadMore loading={loading} error={error} onClick={loadMore}>
					{t("showMore")}
				</LoadMore>
			)}
		</PageView>
	);
}
