import firebase from "firebase/app";

import { useTranslation } from "react-i18next";

import softDeleteDocument from "astrid-firestore/src/api/documents/softDeleteDocument";

import { useProfile } from "../../authentication/state/profile";

export default function useDistributionDropdownMenuOptions(distribution) {
	const user = useProfile();
	const { t } = useTranslation();

	return [
		{
			text: t("view", "View"),
			linkTo: `/publisher/${distribution.publisher.id}/admin/distributions/${distribution.id}`,
		},
		{
			text: t("viewArticle", "View article"),
			linkTo: `/publisher/${distribution.publisher.id}/admin/articles/${distribution.article.id}`,
		},
		{
			text: t("delete", "Delete"),
			action: () => {
				return softDeleteDocument(firebase, distribution, user);
			},
		},
	];
}
