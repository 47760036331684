import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import useStore from "../../ui/hooks/useStore";

import { getOrganizationId } from "../state/organizationId";
import { getOrganizationType } from "../state/organizationType";
import selectOrganization from "../utils/selectOrganization";

export default function useSelectOrganization() {
	const navigate = useNavigate();
	const location = useLocation();

	const { pathname } = location;

	const { setProducerId: legacySetProducerId } = useStore();

	return useCallback(
		(organization) => {
			if (!organization) {
				return;
			}

			const currentId = getOrganizationId();
			const currentType = getOrganizationType();

			if (currentId === organization.id && currentType === organization.type) {
				return;
			}

			selectOrganization({ organization, legacySetProducerId });

			if (currentId) {
				if (organization.id === organizationTypes.ADMIN) {
					navigate("/admin");
				} else if (pathname.includes("/admin") && organization.id !== organizationTypes.NARRATOR) {
					navigate(`${organization.type}/${organization.id}/admin`);
				} else {
					navigate("/");
				}
			}
		},
		[pathname, legacySetProducerId, navigate],
	);
}
