import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useLocation, useNavigate } from "react-router-dom";

export default function Anchor({ id }) {
	const location = useLocation();
	const navigate = useNavigate();

	const { hash, state } = location;
	const { noScroll } = state || {};

	const { ref, entry } = useInView({
		threshold: 0,
		rootMargin: "-10% 0% -70% 0%",
	});

	const { isIntersecting, target } = entry || {};

	useEffect(() => {
		if (hash.length > 0 && target && !noScroll) {
			if (hash.slice(1) === id) {
				const top = target.getBoundingClientRect().top + window.scrollY - window.innerHeight * 0.1;

				window.scrollTo({ top, behavior: "smooth" });
			}
		}
	}, [hash, id, noScroll, target]);

	useEffect(() => {
		if (isIntersecting) {
			navigate(`#${id}`, {
				replace: true,
				state: {
					noScroll: true,
				},
			});
		}
	}, [id, isIntersecting, navigate]);

	return <div ref={ref} id={id} />;
}
