import createDocument from "../../utils/createDocument";
import createDocumentData from "../../utils/createDocumentData";

import validateArticleData from "./validateArticleData";

export default async function createArticle(firebase, data) {
	const article = await validateArticleData(createDocumentData(firebase, "articles", data));

	return createDocument(article);
}
