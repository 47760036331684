import { Icon } from "semantic-ui-react";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowSize from "react-use/lib/useWindowSize";

import { useProfile } from "../../../../authentication/state/profile";
import useSelectOrganization from "../../../../organizations/hooks/useSelectOrganization";
import useUserOrganizations from "../../../../organizations/hooks/useUserOrganizations";
import { useOrganizationId } from "../../../../organizations/state/organizationId";
import DropdownMenu from "../../../../ui/components/DropdownMenu/DropdownMenu";

import getInitialOrganization from "../utils/getInitialOrganization";

export default function OrganizationMenu() {
	const { t } = useTranslation();
	const { width } = useWindowSize();

	const user = useProfile();
	const organizationId = useOrganizationId();

	const navigate = useNavigate();
	const location = useLocation();
	const organizations = useUserOrganizations(user);
	const selectOrganization = useSelectOrganization();

	const large = width > 600;

	useEffect(() => {
		selectOrganization(getInitialOrganization({ user, organizations }));
	}, [organizations, selectOrganization, user]);

	if (organizations.length <= 1) {
		return null;
	}

	const isAccount = location.pathname.startsWith("/account");

	const options = [
		{
			text: t("myAccount", "My Account"),
			active: isAccount,
			onClick: () => navigate(`/account/${user?.id}/settings`),
		},
		...organizations.map((organization) => ({
			text: organization.name,
			active: !isAccount && organizationId === organization.id,
			onClick: () => selectOrganization(organization),
		})),
	];

	const activeText = options.find(({ active }) => active)?.text;

	return (
		<DropdownMenu
			item
			icon={<Icon size="small" name="chevron down outline" fitted style={{ paddingLeft: 5 }} />}
			direction="center"
			options={options}
			text={activeText}
			style={{ margin: 0, maxWidth: large ? 220 : 80 }}
		/>
	);
}
