import { Outlet } from "react-router-dom";

import SuperAdminAgreementsView from "../../views/SuperAdminAgreementsView/SuperAdminAgreementsView";

export default function SuperAdminAgreements() {
	return (
		<SuperAdminAgreementsView>
			<Outlet />
		</SuperAdminAgreementsView>
	);
}
