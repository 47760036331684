import { Icon, Modal } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import stopPropagation from "astrid-helpers/src/stopPropagation";

import PrimaryButton from "../../../ui/components/Buttons/PrimaryButton";
import SecondaryButton from "../../../ui/components/Buttons/SecondaryButton";
import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";

import Form from "../../../forms/components/Form/Form";

export default function FormModal({
	form,
	icon,
	dense,
	header,
	error,
	disabled,
	alwaysEnableSubmit = false,
	children,
	submitText,
	onSubmit,
	onClose,
	scrolling,
	...props
}) {
	const { t } = useTranslation();

	const { formState } = form;

	return (
		<Modal open onClose={onClose} onKeyDown={stopPropagation} onClick={stopPropagation} {...props}>
			{header && (
				<Modal.Header>
					{icon && <Icon name={icon} />} {header}
				</Modal.Header>
			)}

			<Modal.Content scrolling={scrolling} style={{ padding: dense && 0 }}>
				<Form form={form}>{children}</Form>

				{error && <ErrorMessage error={error} />}
			</Modal.Content>

			<Modal.Actions>
				<SecondaryButton content={t("cancel")} onClick={onClose} />

				<PrimaryButton
					disabled={alwaysEnableSubmit ? false : disabled || formState.isSubmitting || !formState.isDirty}
					loading={formState.isSubmitting}
					onClick={onSubmit}
				>
					{submitText || t("save", "Save")}
				</PrimaryButton>
			</Modal.Actions>
		</Modal>
	);
}
