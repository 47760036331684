import { Header, Segment } from "semantic-ui-react";

export default function Section({ children, maxWidth = 1350, style }) {
	return (
		<Segment padded vertical style={{ margin: "0 -15px", ...style }}>
			<div style={{ maxWidth, margin: "0 auto", padding: "0 15px" }}>{children}</div>
		</Segment>
	);
}

Section.Header = function SectionHeader({ style, ...props }) {
	return <Header {...props} style={{ margin: 0, ...style }} />;
};
