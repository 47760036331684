import firebase from "firebase/app";

import { useNavigate } from "react-router-dom";

import getArticlesQuery from "astrid-firestore/src/api/articles/getArticlesQuery";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import useArticlesTableColumns from "../../hooks/useArticlesTableColumns";

import ArticlesTableFooter from "../ArticlesTableFooter/ArticlesTableFooter";

export default function ArticlesCollectionDataTable({ publisher, production, bundle, imprint, serie, ...props }) {
	const navigate = useNavigate();

	const columns = useArticlesTableColumns({ bundle, production });

	const query = getArticlesQuery(firebase, {
		publisher,
		production,
		bundle,
		imprint,
		serie,
	});

	return (
		<CollectionDataTable
			selectable={publisher && (({ bundle }) => !bundle)}
			query={query}
			columns={columns}
			onClick={({ id, publisher }) => navigate(`/publisher/${publisher.id}/admin/articles/${id}`)}
			sortBy={{
				id: "created",
				desc: true,
			}}
			footer={publisher ? <ArticlesTableFooter publisher={publisher} /> : false}
			{...props}
		/>
	);
}
