import createDocument from "../../utils/createDocument";
import createDocumentData from "../../utils/createDocumentData";

import { DistributionChannel } from "./types/DistributionChannel";

export default function createDistributionChannel(firebase, data) {
	const channel = DistributionChannel.parse(createDocumentData(firebase, "distributionChannels", data));

	return createDocument(channel);
}
