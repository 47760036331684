import VerticalMenu from "../../../ui/components/VerticalMenu/VerticalMenu";
import PageView from "../../../ui/views/PageView/PageView";

import useSuperAdminDistributionChannelMenuOptions from "./hooks/useSuperAdminDistributionChannelMenuOptions";

export default function SuperAdminDistributionChannelView({ channel, ...props }) {
	const options = useSuperAdminDistributionChannelMenuOptions({ channel });

	return <PageView header={channel.name} sidebar={<VerticalMenu options={options} />} {...props} />;
}
