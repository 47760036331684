import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import InstantSearchDataTable from "../../../ui/components/InstantSearch/InstantSearchDataTable";
import VerticalMenu from "../../../ui/components/VerticalMenu/VerticalMenu";
import useSearchInput from "../../../ui/hooks/useSearchInput";
import PageView from "../../../ui/views/PageView/PageView";

import { useProfile } from "../../../authentication/state/profile";
import AddUserButton from "../../../users/components/AddUser/AddUserButton";

import useSuperAdminUsersColumns from "./hooks/useSuperAdminUsersColumns";
import useSuperAdminUsersMenuOptions from "./hooks/useSuperAdminUsersMenuOptions";

function getAdminProducerIdsFilter({ type, adminProducerIds }) {
	if (type === organizationTypes.PRODUCER && adminProducerIds?.length) {
		return adminProducerIds.map((producerId) => `organizationIds:${producerId}`).join(" OR ");
	}
}

export default function SuperAdminUsersView({ type }) {
	const { t } = useTranslation();
	const { adminProducerIds } = useProfile();

	const navigate = useNavigate();

	const options = useSuperAdminUsersMenuOptions();
	const columns = useSuperAdminUsersColumns({ type });

	const [searchInput, searchQuery] = useSearchInput();

	const filters = [type && `organizationTypes:${type}`, getAdminProducerIdsFilter({ type, adminProducerIds })]
		.filter(Boolean)
		.join(" AND ");

	return (
		<PageView
			header={t("users", "Users")}
			sidebar={<VerticalMenu options={options} />}
			extra={
				<>
					{searchInput}

					<AddUserButton />
				</>
			}
		>
			<InstantSearchDataTable
				indexName="users"
				searchQuery={searchQuery}
				columns={columns}
				filters={filters}
				onClick={({ id }) => navigate(id)}
			/>
		</PageView>
	);
}
