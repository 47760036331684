import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";
import useSearchInput from "../../../ui/hooks/useSearchInput";
import PageView from "../../../ui/views/PageView/PageView";

import CreateOrganizationButton from "../../../organizations/components/CreateOrganization/CreateOrganizationButton";
import useOrganizationsQuery from "../../../organizations/hooks/useOrganizationsQuery";

import useOrganizationsColumns from "./hooks/useOrganizationsColumns";

export default function SuperAdminOrganizationsView({ type, admin }) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const query = useOrganizationsQuery({ type, admin });

	const headers = {
		producer: t("producers", "Producers"),
		publisher: t("publishers", "Publishers"),
	};

	const columns = useOrganizationsColumns();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<PageView
			header={headers[type]}
			extra={
				<>
					{searchInput}

					<CreateOrganizationButton primary type={type} />
				</>
			}
		>
			<CollectionDataTable
				filterable={false}
				query={query}
				columns={columns}
				onClick={({ id }) => navigate(id)}
				searchQuery={searchQuery}
				searchableKeys={["name"]}
				sortBy={{ id: "name", desc: false }}
			/>
		</PageView>
	);
}
