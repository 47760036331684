import getCollectionQuery from "../../utils/getCollectionQuery";

export default function getDistributionsQuery(firebase, { article, bundle, limit = 20 }) {
	return getCollectionQuery(firebase.firestore().collection("distributions"), {
		fields: {
			article,
			bundle,
		},
		limit,
		orderBy: ["created", "desc"],
		softDeletes: true,
	});
}
