import StudioDataForm from "./components/StudioDataForm";
import StudioHeader from "./components/StudioHeader";
import StudioSettingsForm from "./components/StudioSettingsForm";

export default function AdminStudioView({ studio, gen }) {
	return (
		<>
			<StudioHeader studio={studio} />
			<StudioDataForm studio={studio} />

			{gen === 2 && <StudioSettingsForm studio={studio} />}
		</>
	);
}
