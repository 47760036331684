import { useState } from "react";
import { useController, useFormContext } from "react-hook-form";

import { dotPick } from "astrid-firestore/src/utils/dotObject";

import { getFormDataValue, getFormFieldValue } from "../utils/form";

export default function useFormField({
	name,
	rules = { required: true },
	className = "",
	valueField = "value",
	defaultValue,
	shouldUnregister = true,
	transformValue = (value) => value,
	validateErrorMessage = "",
	error,
	...props
}) {
	const form = useFormContext();

	const { control, locked, alteredValues } = form;

	const { field, fieldState, formState } = useController({
		name,
		rules,
		control,
		defaultValue,
		shouldUnregister,
	});

	const [data, setData] = useState({});

	const { value, onChange: onChangeField, ...restField } = field;

	const onChange = (e, data = null) => {
		if (!locked) {
			setData(data);

			const value = getFormDataValue({ e, data, valueField });

			onChangeField(transformValue(value));
		}
	};

	const altered = !!dotPick(name, alteredValues);

	return {
		locked,
		field: {
			value: getFormFieldValue({ data, value }),
			...restField,
			onChange,
			error:
				fieldState?.error && (fieldState?.error?.message || validateErrorMessage)
					? {
							content: fieldState?.error?.message || validateErrorMessage,
					  }
					: !!fieldState?.error || error,
			className: altered ? `${className} altered` : undefined,
			...props,
		},
		fieldState,
		formState,
	};
}
