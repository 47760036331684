import firebase from "firebase";

import { useTranslation } from "react-i18next";

import updateDistributionChannel from "astrid-firestore/src/api/distribution/updateDistributionChannel";

import { useProfile } from "../../authentication/state/profile";

export default function useDistributionPipelineDropdownMenuOptions({ channel }) {
	const user = useProfile();

	const { t } = useTranslation();

	return (pipeline) => {
		return [
			{
				text: t("view", "View"),
				linkTo: `/admin/distributions/channels/${channel.id}/pipelines/${pipeline.id}`,
			},
			{
				text: t("delete", "Delete"),
				action: () => {
					return updateDistributionChannel(firebase, {
						ref: channel.ref,
						pipelines: { [pipeline.id]: firebase.firestore.FieldValue.delete() },
						updatedBy: user,
					});
				},
			},
		];
	};
}
