import { useEffect } from "react";
import { InstantSearch, useConfigure, usePagination, useSearchBox } from "react-instantsearch";
import { useSearchParams } from "react-router-dom";

import renderChildren from "../../../../utils/renderChildren";

import { useAlgoliaSearchClient } from "../../../algolia/state/algoliaSearchClient";

import LoadingContext from "../LoadingContext/LoadingContext";

import useInstantSearchDataTableHits from "./hooks/useInstantSearchDataTableHits";

export function InstantSearchConfigure({ configure, filters, hitsPerPage }) {
	useConfigure({
		...configure,
		filters: filters || configure?.filters,
		hitsPerPage: hitsPerPage || configure?.hitsPerPage || 10,
	});

	return null;
}

export function InstantSearchHits({ children, searchQuery, ...props }) {
	const [, setParams] = useSearchParams();

	const { query, refine: setSearchQuery } = useSearchBox();

	const hits = useInstantSearchDataTableHits();

	const pagination = usePagination();

	const { nbHits, nbPages, currentRefinement: page, refine: refinePage } = pagination;

	const setPage = (page) => {
		refinePage(page);
		setParams({ page: page + 1 });
	};

	useEffect(() => {
		if (searchQuery !== query) {
			setSearchQuery(searchQuery);
		}
	}, [setSearchQuery, searchQuery, query]);

	return renderChildren(children, {
		data: hits,
		hits,
		nbHits,
		page,
		nbPages,
		setPage,
		searchQuery,
		setSearchQuery,
		...props,
	});
}

export default function InstantSearchData({ indexName, configure, filters, pagination = true, ...props }) {
	const [params] = useSearchParams();

	const page = Math.max(params.get("page"), 0);

	const searchClient = useAlgoliaSearchClient();

	useEffect(() => {
		if (searchClient) {
			searchClient.clearCache();
		}
	}, [searchClient]);

	return (
		<LoadingContext data loading={!searchClient}>
			<InstantSearch
				indexName={indexName}
				searchClient={searchClient}
				initialUiState={pagination && { [indexName]: { page } }}
			>
				<InstantSearchConfigure configure={configure} filters={filters} />
				<InstantSearchHits {...props} />
			</InstantSearch>
		</LoadingContext>
	);
}
