import { useOutletContext, useParams } from "react-router-dom";

import { db } from "astrid-firebase";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import featureFlags from "../../../authorization/constants/featureFlags";

import AdminArticleView from "../../views/AdminArticleView/AdminArticleView";

export default function AdminArticle() {
	const params = useParams();
	const { organization } = useOutletContext();

	const [article, loading, error] = useDocumentData(db.collection("articles").doc(params.article));

	return (
		<LoadingContext data={article} loading={loading} error={error} featureFlag={featureFlags.FLATTENING}>
			<AdminArticleView organization={organization} article={article} />
		</LoadingContext>
	);
}
