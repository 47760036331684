import firebase from "firebase/app";

import { Card, Segment } from "semantic-ui-react";

import React from "react";

import getArticlesQuery from "astrid-firestore/src/api/articles/getArticlesQuery";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import ArticleCardFull from "../../../articles/components/ArticleCard/ArticleCardFull";

export default function ProductionOverviewView({ production }) {
	const [articles, loading, error] = useCollectionData(getArticlesQuery(firebase, { production }));

	return (
		<Segment basic padded>
			<LoadingContext data={articles} loading={loading} error={error}>
				<Card.Group>
					{articles?.map((article) => (
						<ArticleCardFull key={article.id} article={article} />
					))}
				</Card.Group>
			</LoadingContext>
		</Segment>
	);
}
