import setDocument from "../../utils/setDocument";
import updateDocumentData from "../../utils/updateDocumentData";

import { DistributionChannel } from "./types/DistributionChannel";

export default async function updateDistributionChannel(firebase, data) {
	const channel = DistributionChannel.partial().parse(updateDocumentData(firebase, data));

	return setDocument(channel);
}
