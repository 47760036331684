import { useTranslation } from "react-i18next";

import { useProfile } from "../../authentication/state/profile";

import AccountCostsView from "../views/AccountCostsView/AccountCostsView";

export default function AccountRevenues() {
	const { t } = useTranslation();

	const user = useProfile();

	return <AccountCostsView header={t("revenues", "Revenues")} secondPartyId={user?.id} />;
}
