import mapDocument from "./mapDocument";

export default async function createDocument(data) {
	await data.ref.set(data);

	return mapDocument({
		id: data.ref.id,
		ref: data.ref,
		exists: true,
		data: () => data,
	});
}
