import React from "react";
import { useTranslation } from "react-i18next";

import useLoadMore from "astrid-firestore/src/hooks/useLoadMore";

import LoadMore from "../../../../components/LoadMore/LoadMore";
import PageView from "../../../ui/views/PageView/PageView";

import OrdersAccordion from "../../../orders/components/OrdersAccordion/OrdersAccordion";
import useOrdersQuery from "../../../orders/hooks/useOrdersQuery";

export default function AccountOrdersView({ user, expense = true, header }) {
	const { t } = useTranslation();

	const query = useOrdersQuery({ expense, secondPartyId: user?.id || null }).orderBy("created", "desc");

	const [orders = [], loading, error, loadMore] = useLoadMore(query, { limit: 30 });

	return (
		<PageView header={header}>
			<OrdersAccordion orders={orders} />

			<LoadMore loading={loading} error={error} onClick={loadMore}>
				{t("showMore")}
			</LoadMore>
		</PageView>
	);
}
