import { useState } from "react";

import DataTable from "../../../ui/components/DataTable/DataTable";

import useDistributionPresetsTableColumns from "../../hooks/useDistributionPresetsTableColumns";

import CreateDistributionPresetForm from "../CreateDistributionPreset/CreateDistributionPresetForm";

export default function DistributionPresetsDataTable({ publisher, ...props }) {
	const [selectedPreset, setSelectedPreset] = useState(null);
	const data = Object.values(publisher?.distribution?.presets || []).sort((a, b) =>
		a.channel.name.localeCompare(b.channel.name),
	);

	const columns = useDistributionPresetsTableColumns({ publisher });

	return (
		<>
			<DataTable
				data={data}
				sortBy={{ id: "channel", desc: false }}
				onClick={(preset) => {
					setSelectedPreset(preset);
				}}
				columns={columns}
				{...props}
			/>
			{selectedPreset && (
				<CreateDistributionPresetForm
					publisher={publisher}
					preset={selectedPreset}
					onClose={() => setSelectedPreset(null)}
				/>
			)}
		</>
	);
}
