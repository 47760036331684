import React from "react";
import { useTranslation } from "react-i18next";
import { useBlocker } from "react-router-dom";
import useBeforeUnload from "react-use/lib/useBeforeUnload";

import ConfirmationModal from "../../../ui/components/ConfirmationModal/ConfirmationModal";

import Form from "../Form/Form";

export default function FormBlocker(props) {
	const { t } = useTranslation();
	const { formState } = Form.useFormContext();

	const { isDirty } = formState;

	const text = t("navigateWithUnsavedChangesMessage", "You have unsaved changes. Are you sure you want to leave?");

	useBeforeUnload(isDirty, text);

	const blocker = useBlocker(
		({ currentLocation, nextLocation }) => isDirty && currentLocation.pathname !== nextLocation.pathname,
	);

	if (blocker.state !== "blocked") {
		return null;
	}

	return <ConfirmationModal text={text} onConfirm={blocker.proceed} onClose={blocker.reset} {...props} />;
}
