import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { ref } from "../../../types/_types";

import { Article } from "./Article";

export const ArticleVersion = FirestoreDocument.extend({
	after: Article,
	before: Article,
}).merge(FirestoreLifecycle);

export const ArticleVersionRef = ref(ArticleVersion, {
	created: true,
	createdBy: true,
});
