import createDocumentData from "../../utils/createDocumentData";
import runTransaction from "../../utils/runTransaction";

import { Imprint } from "../imprints/types/Imprint";
import { Serie } from "../series/types/Serie";

import { ArticleVersion } from "./types/ArticleVersion";
import validateArticleData from "./validateArticleData";

export default async function updateArticle(firebase, data, { saveVersion = false } = {}) {
	const { ref, imprint, serie } = data;

	return runTransaction(firebase, [ref], async (transaction, [article]) => {
		// If the imprint does not exist, create it
		if (imprint?.exists === false) {
			transaction.set(imprint.ref, Imprint.parse(imprint));
		}

		// If the serie does not exist, create it
		if (serie?.exists === false) {
			transaction.set(serie.ref, Serie.parse(serie));
		}

		// Save the updated article in the version history
		if (saveVersion) {
			const version = ArticleVersion.parse(
				createDocumentData(firebase, `articles/${article.id}/versions`, {
					before: article,
					after: {
						...article,
						...data,
					},
				}),
			);

			transaction.set(version.ref, version);
		}

		// Update the article with the new data
		transaction.set(article.ref, await validateArticleData(data, true), { merge: true });
	});
}
