import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import CreateDistributionChannelForm from "./CreateDistributionChannelForm";

export default function CreateDistributionChannelButton(props) {
	const { t } = useTranslation();

	return (
		<OpenButton text={t("createDistributionChannel", "Create distribution channel")} {...props}>
			{({ setOpen }) => <CreateDistributionChannelForm onClose={() => setOpen(false)} />}
		</OpenButton>
	);
}
