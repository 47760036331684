import firebase from "firebase/app";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import createDistributionChannel from "astrid-firestore/src/api/distribution/createDistributionChannel";
import { DistributionChannel } from "astrid-firestore/src/api/distribution/types/DistributionChannel";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

export default function CreateDistributionChannelForm({ onClose }) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const { form, onSubmit, error } = useSimpleForm({
		schema: DistributionChannel,
		defaultValues: {
			name: "",
		},
		onSubmit: (data) => {
			return createDistributionChannel(firebase, data);
		},
		onSuccess: ({ id }) => navigate(id),
	});

	return (
		<Form.Modal
			header={t("createDistributionChannel", "Create distribution channel")}
			size="tiny"
			form={form}
			error={error}
			onSubmit={onSubmit}
			onClose={onClose}
		>
			<Form.Input name="name" label={t("name", "Name")} />
		</Form.Modal>
	);
}
