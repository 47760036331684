import firebase from "firebase/app";

import { useTranslation } from "react-i18next";

import { DistributionChannel } from "astrid-firestore/src/api/distribution/types/DistributionChannel";
import updateDistributionChannel from "astrid-firestore/src/api/distribution/updateDistributionChannel";

import AdminCard from "../../../../admin/components/AdminCard/AdminCard";
import AdminForm from "../../../../admin/components/AdminForm/AdminForm";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

import metadataTemplateTypes from "../constants/metadataTemplateTypes";

import MetadataEditor from "./MetadataEditor";

export default function SuperAdminDistributionChannelMetaDataTemplates({ channel }) {
	const { t } = useTranslation();

	const form = useSimpleForm({
		schema: DistributionChannel,
		defaultValues: {
			ref: channel.ref,
			enabledTemplates: channel.enabledTemplates,
		},
		onSubmit: (data) => {
			return updateDistributionChannel(firebase, data);
		},
	});
	return (
		<AdminCard header={t("metadataTemplates", "Metadata templates")}>
			<AdminForm {...form}>
				<Form.Select
					multiple
					name="enabledTemplates"
					label={t("enabledTemplates", "Enabled templates")}
					options={Object.entries(metadataTemplateTypes).map(([key, value]) => ({
						key,
						value,
						text: value,
					}))}
				/>
			</AdminForm>
			{channel.enabledTemplates?.map((templateType) => (
				<MetadataEditor key={templateType} channel={channel} templateType={templateType} />
			))}
		</AdminCard>
	);
}
