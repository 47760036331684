import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import DataTable from "../../../ui/components/DataTable/DataTable";

import useFormField from "../../hooks/useFormField";

export default function FormCheckboxTable({ name, options, ...props }) {
	const { t } = useTranslation();

	const {
		field: { value, onChange },
	} = useFormField({ name, ...props });

	const selectedOptions = options.filter((option) => value.includes(option.value));
	const unselectedOptions = options.filter((option) => !value.includes(option.value));

	const columns = useMemo(
		() => [
			{
				id: "text",
				Header: t("search", "Search") + "...",
				filter: "text",
			},
		],
		[t],
	);

	return (
		<DataTable
			data={[...selectedOptions, ...unselectedOptions]}
			columns={columns}
			filterable
			selectable
			sortable={false}
			searchableKeys={["text"]}
			selectedRowIds={value?.reduce((acc, value) => ({ ...acc, [value]: true }), {})}
			onSelectedRowIds={(selectedRowIds) => {
				onChange(null, { name, value: Object.keys(selectedRowIds) });
			}}
		/>
	);
}
