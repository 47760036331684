import { useTranslation } from "react-i18next";

import useSearchInput from "../../../ui/hooks/useSearchInput";

import AdminHeader from "../../../admin/components/AdminHeader/AdminHeader";
import AgreementAppendixesCollectionDataTable from "../../../agreements/components/AgreementAppendixesCollectionDataTable/AgreementAppendixesCollectionDataTable";
import CreateAppendixButton from "../../../agreements/components/CreateAppendix/CreateAppendixButton";

export default function SuperAdminAppendixesView() {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("appendixes", "Appendixes")}>
				{searchInput}

				<CreateAppendixButton primary />
			</AdminHeader>

			<AgreementAppendixesCollectionDataTable searchQuery={searchQuery} />
		</>
	);
}
