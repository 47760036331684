import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../../authorization/hooks/useHasRight";

import SuperAdminApproveProductionPricesView from "../../views/SuperAdminApproveProductionPricesView/SuperAdminApproveProductionPricesView";

export default function SuperAdminApproveProductionPrices() {
	const hasRight = useHasRight();
	const { needsApproval } = useOutletContext();

	if (!hasRight("costs.admin")) {
		return <Unauthorized />;
	}

	const { productionPrices, loading, error } = needsApproval;

	return (
		<SuperAdminApproveProductionPricesView productionPrices={productionPrices} loading={loading} error={error} />
	);
}
