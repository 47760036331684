import firebase from "firebase";

import React from "react";
import { Outlet } from "react-router-dom";

import getUncompletedTasksQuery from "astrid-firestore/src/api/tasks/queries/getUncompletedTasksQuery";
import { useCollectionCount } from "astrid-firestore/src/hooks";

import useNeedsApproval from "../../hooks/useNeedsApproval";

export default function SuperAdminView() {
	const needsApproval = useNeedsApproval();

	const [uncompletedBiblioTaskCount] = useCollectionCount(getUncompletedTasksQuery(firebase));

	// const menuOptions = useSuperAdminMenuOptions({ needsApproval, uncompletedBiblioTaskCount });

	return <Outlet context={{ needsApproval, uncompletedBiblioTaskCount }} />;
}
