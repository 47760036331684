import firebase from "firebase/app";

import { useMemo } from "react";

import getDistributionChannelsQuery from "astrid-firestore/src/api/distribution/getDistributionChannelsQuery";
import { filterDistributionChannelsByArticle } from "astrid-firestore/src/api/distribution/utils/filterDistributionChannels";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";

export default function useArticleDistributionChannels({ publisher, article }) {
	const [channels, loading, error] = useCollectionData(getDistributionChannelsQuery(firebase));

	const presetChannels = useMemo(
		() => filterDistributionChannelsByArticle({ publisher, article, channels }),
		[article, channels, publisher],
	);

	const articleChannels = presetChannels.filter(
		(channel) => !article.channelIds || article.channelIds.includes(channel.id),
	);

	return [presetChannels, articleChannels, loading, error];
}
