import mergeArticleChannelDataWithPresetChannelData from "astrid-firestore/src/api/distribution/utils/mergeArticleChannelDataWithPresetChannelData";
import { getDocumentData } from "astrid-firestore/src/helpers";

export default async function fetchMetadata({ document }) {
	const { article, artifact, publisher } = document;

	const linkedArticle = await getDocumentData(article.linkedArticle?.ref);

	return {
		...document,
		article: {
			...article,
			artifact,
			channels: mergeArticleChannelDataWithPresetChannelData({
				channelIds: [document.channel.id],
				article,
				publisher,
			}),
		},
		linkedArticle: linkedArticle
			? {
					...linkedArticle,
					channels: mergeArticleChannelDataWithPresetChannelData({
						channelIds: [document.channel.id],
						article: linkedArticle,
						publisher,
					}),
			  }
			: null,
	};
}
