export default function getCollectionQuery(collection, { fields, orderBy, limit, softDeletes }) {
	let query = softDeletes ? collection.where("deleted", "==", false) : collection;

	query = Object.entries(fields || {}).reduce((query, [key, value]) => {
		if (value?.id) {
			return query.where(`${key}.id`, "==", value.id);
		}

		if (Array.isArray(value)) {
			return query.where(key, value[0], value[1]);
		}

		if (value !== undefined) {
			return query.where(key, "==", value);
		}

		return query;
	}, query);

	if (orderBy) {
		query = query.orderBy(...orderBy);
	}

	if (limit) {
		query = query.limit(limit);
	}

	return query;
}
