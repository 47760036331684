import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import AddUserForm from "./AddUserForm";

export default function AddUserButton({ organization, reader }) {
	const { t } = useTranslation();

	const text = reader ? t("addReader", "Add narrator") : t("addUser", "Add user");

	return (
		<OpenButton text={text} primary>
			{({ setOpen }) => (
				<AddUserForm header={text} organization={organization} reader={reader} onClose={() => setOpen(false)} />
			)}
		</OpenButton>
	);
}
