import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import { localStorage } from "../../../helpers/fnc";

import { getProfile } from "../../authentication/state/profile";

import { setOrganizationId } from "../state/organizationId";
import { setOrganizationType } from "../state/organizationType";

export default function selectOrganization({ organization, legacySetProducerId }) {
	const user = getProfile();

	setOrganizationId(organization.id);
	setOrganizationType(organization.type);

	switch (organization?.type) {
		case organizationTypes.PRODUCER:
			legacySetProducerId(organization.id);
			break;
		case organizationTypes.PUBLISHER:
		case organizationTypes.NARRATOR:
		case organizationTypes.ADMIN:
			legacySetProducerId(undefined);
			break;
		default:
			break;
	}

	localStorage("organizations", user.id, {
		id: organization.id,
		type: organization.type,
	});
}
