import { Divider, Header } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import Flex from "astrid-components/lib/components/Layout/Flex";

import useVoiceTranslations from "../hooks/useVoiceTranslations";

import UserProfileInformation from "./UserProfileInformation";

export default function ReaderInformation({ user }) {
	const { t } = useTranslation();
	const voiceTranslations = useVoiceTranslations();

	if (!user.readerData) {
		return null;
	}

	const { presentation, currency, voice, genre, cost, userData } = user.readerData;

	const samples = Object.entries(user?.readerData?.samples || {});

	return (
		<>
			{user.readerData.presentation && (
				<>
					<Divider />
					<Header.Subheader>{user.readerData.presentation}</Header.Subheader>
				</>
			)}

			<Divider />

			<Flex style={{ flexDirection: "column", marginTop: 5 }} gap={5} alignItems="flex-start">
				<UserProfileInformation icon="currency" value={currency}>
					{currency || "SEK"} - {cost}/{t("hour", "hour")}
				</UserProfileInformation>

				<UserProfileInformation icon="microphone" value={voice}>
					{voiceTranslations[voice]}
				</UserProfileInformation>

				<UserProfileInformation icon="book" value={genre}>
					{userData?.genre?.map?.((genre) => t(`${genre}`, `${genre}`))?.join(", ")}
				</UserProfileInformation>
			</Flex>

			{samples.length > 0 && (
				<>
					<Divider />

					{samples.map(([id, { file }]) => {
						return (
							<Flex key={id} gap={15} style={{ marginTop: 10 }}>
								<audio
									style={{
										width: "100%",
										height: 39,
									}}
									src={file}
									controls
									onPlay={(e) => {
										const allAudio = document.querySelectorAll("audio");

										for (const audio of allAudio) {
											if (audio !== e.target) audio.pause();
										}
									}}
								/>
							</Flex>
						);
					})}
				</>
			)}
		</>
	);
}
