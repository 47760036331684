import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import { useProfile } from "../../../../authentication/state/profile";
import featureFlags from "../../../../authorization/constants/featureFlags";
import useFeatureFlag from "../../../../authorization/hooks/useFeatureFlag";
import useHasRight from "../../../../authorization/hooks/useHasRight";
import { useOrganizationId } from "../../../../organizations/state/organizationId";
import { useOrganizationType } from "../../../../organizations/state/organizationType";

export default function useMainMenuOptions() {
	const { t } = useTranslation();

	const user = useProfile();
	const orgId = useOrganizationId();
	const orgType = useOrganizationType();

	const hasRight = useHasRight();
	const location = useLocation();
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	const { pathname } = location;

	if (pathname.startsWith("/account")) {
		return [
			{
				to: `/account/${user?.id}/settings`,
				text: t("settings", "Settings"),
				active: pathname.includes("settings"),
			},
			{
				to: `/account/${user?.id}/agreements`,
				text: t("agreements", "Agreements"),
				active: pathname.includes("agreements"),
			},
			{
				to: `/account/${user?.id}/revenues`,
				text: t("revenues", "Revenues"),
				active: pathname.includes("revenues"),
			},
			{
				to: `/account/${user?.id}/orders`,
				text: t("POs"),
				active: pathname.includes("orders"),
			},
		];
	}

	if (orgType === organizationTypes.ADMIN) {
		return [
			{
				to: "/admin",
				text: t("overview", "Overview"),
				active: pathname === "/admin",
			},
			{
				to: "/admin/approve/costs",
				text: t("needsApproval", "Needs approval"),
				active: pathname.includes("admin/approve"),
			},
			{
				to: "/admin/users",
				text: t("users", "Users"),
				active: pathname.includes("admin/users"),
			},
			{
				to: "/admin/producers",
				text: t("producers", "Producers"),
				active: pathname.includes("admin/producers"),
			},
			{
				to: "/admin/publishers",
				text: t("publishers", "Publishers"),
				active: pathname.includes("admin/publishers"),
			},
			{
				to: "/admin/agreements/appendixes",
				text: t("agreements", "Agreements"),
				active: pathname.includes("admin/agreements"),
			},
			{
				to: "/admin/distribution/distributions",
				text: t("distribution", "Distribution"),
				active: pathname.includes("admin/distribution"),
				hidden: !hasFlatteningFeature,
			},
		];
	}

	if (orgType === organizationTypes.PRODUCER) {
		return [
			{
				to: "/",
				text: t("start", "Start"),
				active: pathname === "/",
			},
			{
				to: `/${orgType}/${orgId}/admin`,
				text: t("admin", "Admin"),
				hidden: !hasRight("admin"),
				active: pathname.includes("admin"),
			},
			{
				to: `/${orgType}/${orgId}/create-production`,
				text: t("createProduction", "Create production"),
				active: pathname.includes("create-production"),
				hidden: !hasRight("createProduction"),
			},
			{
				to: `/${orgType}/${orgId}/my-productions/current`,
				text: t("myProductions", "My productions"),
				active: pathname.includes("my-productions"),
			},
			{
				to: `/${orgType}/${orgId}/list-productions`,
				text: t("productions", "Productions"),
				active: pathname.includes("list-productions"),
				hidden: !hasRight("list-productions.beta"),
			},
			{
				to: `/${orgType}/${orgId}/calendar`,
				text: t("calendar", "Calendar"),
				active: pathname.includes("/calendar"),
			},
			{
				to: `/${orgType}/${orgId}/documents`,
				text: t("documents", "Documents"),
				active: pathname.includes("/documents"),
			},
			{
				to: "/reader",
				text: t("narrators", "Narrators"),
				active: pathname.startsWith("/reader"),
				hidden: !hasRight("readerRegistry.view"),
			},
			{
				to: `/${orgType}/${orgId}/statistics`,
				text: t("statistics"),
				active: pathname.includes("/statistics"),
				hidden: !hasRight("viewProductionStatistics"),
			},
		];
	}

	if (orgType === organizationTypes.PUBLISHER) {
		return [
			{
				to: "/",
				text: t("start", "Start"),
				active: pathname === "/",
			},
			{
				to: `/${orgType}/${orgId}/admin`,
				text: t("admin", "Admin"),
				hidden: !hasRight("admin"),
				active: pathname.includes("admin"),
			},
			{
				to: `/${orgType}/${orgId}/create-production`,
				text: t("createProduction", "Create production"),
				active: pathname.includes("create-production"),
			},
			{
				to: `/${orgType}/${orgId}/my-productions/current`,
				text: t("myProductions", "My productions"),
				active: pathname.includes("my-productions"),
			},
			{
				to: `/${orgType}/${orgId}/list-productions`,
				text: t("productions", "Productions"),
				active: pathname.includes("list-productions"),
			},
			{
				to: `/${orgType}/${orgId}/articles`,
				text: t("articles", "Articles"),
				active: pathname.includes("/articles"),
				hidden: !hasFlatteningFeature, // TODO: Remove feature flag check
			},
			{
				to: "/reader",
				text: t("narrators", "Narrators"),
				active: pathname.startsWith("/reader"),
			},
		];
	}

	if (orgType === organizationTypes.NARRATOR) {
		return [
			{
				to: `/${orgType}/${orgId}/my-productions/current`,
				text: t("myProductions", "My productions"),
				active: pathname.includes("my-productions"),
			},
			{
				to: `/${orgType}/${orgId}/calendar`,
				text: t("calendar", "Calendar"),
				active: pathname.includes("/calendar"),
			},
		];
	}

	return [];
}
