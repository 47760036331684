import React from "react";
import { useTranslation } from "react-i18next";

import VerticalMenu from "../../../ui/components/VerticalMenu/VerticalMenu";
import PageView from "../../../ui/views/PageView/PageView";

import useSuperAdminDistributionMenuOptions from "./hooks/useSuperAdminDistributionMenuOptions";

export default function SuperAdminDistributionView(props) {
	const { t } = useTranslation();

	const options = useSuperAdminDistributionMenuOptions();

	return (
		<PageView header={t("distribution", "Distribution")} sidebar={<VerticalMenu options={options} />} {...props} />
	);
}
