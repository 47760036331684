import { format } from "date-fns";
import Handlebars from "handlebars";

import bisacToThema from "astrid-config/src/genres/BisacToThema.json";
import languages from "astrid-config/src/languages";
import contributorRoles from "astrid-firestore/src/api/articles/constants/contributorRoles";

import getArticle from "./utils/getArticle";

/*
TODO:
 Duration
 Categorization
 Synopsis
 Publish.ele("Imprint").ele("ImprintName", publisher); ->  <ImprintName>{{article.imprint.name}}}</ImprintName>
*/

const mimeTypes = {
	wav: { mime: "audio/wave", description: "Broadcast Wave" },
	mp3: { mime: "audio/mpeg", description: "MPEG 1/2 Audio Layer 3" },
	jpg: { mime: "image/jpeg", description: "JPEG File Interchange Format" },
	jpeg: { mime: "image/jpeg", description: "JPEG File Interchange Format" },
	epub: { mime: "application/epub+zip", description: "ePub format" },
	zip: { mime: "application/zip", description: "ZIP archive" },
};

const contributorCodes = {
	[contributorRoles.AUTHOR]: "A01",
	[contributorRoles.NARRATOR]: "E07",
	[contributorRoles.TRANSLATOR]: "B06",
};

function base64ToHex(base64) {
	const raw = atob(base64);
	let result = "";
	for (let i = 0; i < raw.length; i++) {
		const hex = raw.charCodeAt(i).toString(16);
		result += hex.length === 2 ? hex : "0" + hex;
	}
	return result;
}

function isEbook(article) {
	return article.type === "ebook";
}

function compareDistributionDateWithToday(distributionDate) {
	const distDate = new Date(distributionDate).setHours(0, 0, 0, 0);
	const today = new Date().setHours(0, 0, 0, 0);

	return distDate > today;
}

function isArticleActive(article, channel) {
	const takeDownDate = getDistributionDates(article, channel)?.takedownDate;
	if (takeDownDate) {
		return compareDistributionDateWithToday(takeDownDate);
	}
	return true;
}

function getDistributionDates(article, channel) {
	return article.channels?.[channel.id]?.distribution;
}

Handlebars.registerHelper("join", function (array, separator) {
	return array.join(separator);
});

Handlebars.registerHelper("getAbridged", function (article) {
	return article.abridged ? "ABR" : "UBR";
});

Handlebars.registerHelper("isEbook", (article) => {
	return isEbook(article);
});

Handlebars.registerHelper("getArticleLanguageIso", (article) => {
	return languages[article.language]?.iso6392;
});

Handlebars.registerHelper("getCountriesIncluded", (article, channel) => {
	return article.channels[channel.id].countries.join(" ");
});

Handlebars.registerHelper("getCreatedDate", () => {
	return new Date().toISOString();
});

Handlebars.registerHelper("isRelevantContributor", (contributor, article) => {
	if (contributor.role === contributorRoles.NARRATOR && isEbook(article)) {
		return false;
	}
	return !!contributorCodes?.[contributor.role];
});

Handlebars.registerHelper("mapContributorRole", (contributor) => {
	return contributorCodes?.[contributor.role];
});

Handlebars.registerHelper("getContributorByRole", (article, role) => {
	return article.contributors?.filter((contributor) => contributor.role === role);
});

Handlebars.registerHelper("getContributorFullNameInverted", ({ firstName, lastName }) =>
	lastName && firstName ? `${lastName}, ${firstName}` : firstName || lastName,
);

Handlebars.registerHelper("isTranslated", (article) => {
	return article.originalLanguage && article.language !== article.originalLanguage;
});

Handlebars.registerHelper("getIsbn", (type, article, linkedArticle) => {
	const { isbn } = getArticle({ type, article, linkedArticle });

	return isbn;
});

Handlebars.registerHelper("getPublisherOrganizationNumber", (publisher) => {
	if (publisher && Object.values(publisher.vendors || {}).length) {
		const primaryVendor = Object.values(publisher.vendors).find((vendor) => vendor.primary);
		if (primaryVendor && primaryVendor.organizationNumber) {
			return primaryVendor.organizationNumber.replace("-", "");
		}
	}
	return "unknown-org-number";
});

Handlebars.registerHelper("getFileId", (index) => {
	return String(index + 1).padStart(5, "0");
});

Handlebars.registerHelper("getMimeType", (file) => {
	const extensions = file.name.split(".");
	const ext = extensions.pop();
	return mimeTypes[ext]?.mime;
});

Handlebars.registerHelper("getRoyalLibraryMimeType", (file) => {
	const extensions = file.name.split(".");
	const ext = extensions.pop();
	return mimeTypes[ext]?.description;
});

Handlebars.registerHelper("getFileCreationISODate", (file) => {
	const millis = Math.floor(file.generation / 1000);
	return new Date(millis).toISOString();
});

Handlebars.registerHelper("getMD5checksum", (file) => {
	return base64ToHex(file.md5Hash);
});

Handlebars.registerHelper("getCoverId", (article, files) => {
	return String(files.length + 1).padStart(5, "0");
});

Handlebars.registerHelper("getFullYear", () => {
	return new Date().getFullYear();
});

Handlebars.registerHelper("getFullYearFromDate", (date) => {
	return date?.getFullYear() || "";
});

Handlebars.registerHelper("getPublicationDate", (type, article, linkedArticle, channel) => {
	const art = getArticle({ type, article, linkedArticle });
	return getDistributionDates(art, channel, "releaseDate");
});

Handlebars.registerHelper("getTakedownDate", (type, article, linkedArticle, channel) => {
	const art = getArticle({ type, article, linkedArticle });
	return getDistributionDates(art, channel, "takedownDate");
});

Handlebars.registerHelper("getFormattedPublicationDate", (article, channel, formatting = "yyyy-MM-dd") => {
	const releaseDate = getDistributionDates(article, channel, "releaseDate");
	if (!releaseDate) {
		return "";
	}
	formatting = article.language === "pl" ? "yyyymmdd" : formatting; // polish date format
	return format(new Date(releaseDate), formatting);
});

Handlebars.registerHelper("formatDate", (date, formatting = "yyyy-MM-dd") => {
	return date ? format(new Date(date), formatting) : "";
});

Handlebars.registerHelper("mapAllContributors", (article) => {
	const authors = article.authors?.map((author) => author.name).join("; ") || "";
	const narrators = article.narrators?.map((narrator) => `${narrator.name} (narrator)`).join("; ") || "";
	const translators = article.translators?.map((translator) => `${translator.name} (translator)`).join("; ") || "";
	return [authors, narrators, translators].join("; ");
});

Handlebars.registerHelper("mapAudibleDEGenre", (genres) => {
	// audible only supports one genre, grab the first and run with it (comment from Emil)
	const genre = genres?.[0];

	const audibleGenre = {
		crime: "Krimi",
		biographies: "Bildung & Wissen",
		nonfiction: "Bildung & Wissen",
		fact: "Bildung & Wissen",
		thriller: "Thriller",
		kids: "Kinder-Hörbucher",
		history: "Bildung & Wissen",
		teen: "Jugend-Hörbücher",
		finance: "Wirtschaft & Karriere",
		erotica: "Romane",
		shortstories: "Romane",
		language: "Sprachkurse",
		harlequin: "Romane",
		selfhelp: "Freizeit & Leben",
		fantasy: "Fantasy",
		classics: "Klassiker",
		poetry: "Romane",
		feature: "Bildung & Wissen",
		religion: "Spiritualität & Religion",
		romance: "Romane/Liebesromane",
	};

	return audibleGenre?.[genre] || "";
});

Handlebars.registerHelper("getLanguageName", (article) => {
	return languages?.[article.language]?.name;
});

Handlebars.registerHelper("getAudibleDECopyrightHolder", (article) => {
	return `${article?.authors?.map((author) => author.name).join(",")} / ${article?.publisher?.name}`;
});

Handlebars.registerHelper("getDurationInMinutes", (artifact) => {
	const totalDuration = artifact?.files.reduce((acc, file) => acc + file.duration, 0);
	return Math.round(totalDuration / 60);
});

Handlebars.registerHelper("hasDate", (article, channel, dateType) => {
	return !!getDistributionDates(article, channel)?.[dateType];
});

Handlebars.registerHelper("getDateByType", (article, channel, dateType) => {
	return getDistributionDates(article, channel)?.[dateType];
});

Handlebars.registerHelper("getMarketPublishingStatus", (article, channel) => {
	const isActive = isArticleActive(article, channel);
	if (!isActive) {
		return "08";
	}

	if (compareDistributionDateWithToday(getDistributionDates(article, channel)?.releaseDate)) {
		return "02";
	}

	return "04";
});

Handlebars.registerHelper("getProductAvailability", (article, channel) => {
	return isArticleActive(article, channel) ? "20" : "01";
});

Handlebars.registerHelper("mapThemaCodesToBisacCodes", (article) => {
	const bisacEntries = Object.entries(bisacToThema);

	const themaToBisac = {};

	for (const themaCode of article.themaCodes || []) {
		let currentWeight = 0;

		const bisacs = bisacEntries.filter(([, map]) => map.includes(themaCode));

		for (const [bisacCode, map] of bisacs) {
			const weight = map.length;
			const hasAll = map.every((code) => article.themaCodes.includes(code));

			if (hasAll && weight > currentWeight) {
				currentWeight = weight;
				themaToBisac[themaCode] = bisacCode;
			}
		}
	}

	return [...new Set(Object.values(themaToBisac))];
});
