import { useTranslation } from "react-i18next";

import { useProfile } from "../../../../authentication/state/profile";

export default function useAccountSettingsMenuOptions() {
	const user = useProfile();

	const { t } = useTranslation();

	const isReader = user.permissions?.reader;

	return [
		{
			path: "#general",
			name: t("general", "General"),
		},
		{
			path: "#languages",
			name: t("languageSkills", "Language skills"),
		},
		{
			path: "#narration",
			name: t("narration", "Narration"),
			hidden: !isReader,
		},
		{
			path: "#samples",
			name: t("soundSamples", "Audio samples"),
			hidden: !isReader,
		},
		{
			path: "#vendors",
			name: t("vendorSettings"),
		},
	];
}
