import { useTranslation } from "react-i18next";

export default function useSuperAdminApproveMenuOptions({ needsApproval }) {
	const { t } = useTranslation();

	const { productionPrices, prices, offers, agreements, agreementTemplates } = needsApproval;

	return [
		{
			path: "costs",
			name: t("costs", "Costs"),
			label: productionPrices.length ? productionPrices.length : undefined,
		},
		{
			path: "prices",
			name: t("prices", "Prices"),
			label: prices.length ? prices.length : undefined,
		},
		{
			path: "offers",
			name: t("offers", "Offers"),
			label: offers.length ? offers.length : undefined,
		},
		{
			path: "agreements",
			name: t("agreements", "Agreements"),
			label: agreements.length ? agreements.length : undefined,
		},
		{
			path: "templates",
			name: t("agreementTemplates", "Agreement templates"),
			label: agreementTemplates.length ? agreementTemplates.length : undefined,
		},
	];
}
