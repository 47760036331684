import firebase from "firebase";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import getArticlesQuery from "astrid-firestore/src/api/articles/getArticlesQuery";
import { useCollectionCount } from "astrid-firestore/src/hooks";

export default function useAdminImprintMenuOptions({ imprint }) {
	const { t } = useTranslation();

	const location = useLocation();

	const { pathname } = location;

	const [articlesCount, articlesCountLoading] = useCollectionCount(getArticlesQuery(firebase, { imprint }));

	return [
		{
			to: "articles",
			active: pathname.endsWith("/articles"),
			text: t("articles", "Articles"),
			label: articlesCount,
			loading: articlesCountLoading,
		},
	];
}
