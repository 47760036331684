import AdminPageView from "../../components/AdminPage/AdminPageView";

import AdminDistributionHeader from "./components/AdminDistributionHeader";

export default function AdminDistributionView({ organization, article, distribution }) {
	return (
		<AdminPageView organization={organization} article={article} distribution={distribution}>
			<AdminDistributionHeader distribution={distribution} />
		</AdminPageView>
	);
}
