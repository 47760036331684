import Popup from "../../../../ui/components/Popup/Popup";
import ProgressLabel from "../../../../ui/components/ProgressLabel/ProgressLabel";

export default function ArticleDeliveryStatusLabel({ text, status, sent, color, active, percent, success, disabled }) {
	return (
		<Popup
			content={sent ? `${status}: ${sent}` : status}
			color={color}
			trigger={
				<ProgressLabel
					percent={percent}
					success={success}
					color={color}
					active={active}
					style={{ opacity: disabled && 0.4 }}
				>
					{/*{disabled && <Icon name="ban" />}*/}

					{/*{loading && <Icon name="circle notch" loading />}*/}

					{text}
				</ProgressLabel>
			}
		/>
	);
}
