import firebase from "firebase/app";

import { useTranslation } from "react-i18next";

import distributeArticles from "astrid-firestore/src/api/distribution/distributeArticles";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

import useArticlesDistributionChannels from "../../hooks/useArticlesDistributionChannels";

import DistributeArticlesFormArticles from "./components/DistributeArticlesFormArticles";
import DistributeArticlesFormChannels from "./components/DistributeArticlesFormChannels";
import DistributeArticlesFormDeliver from "./components/DistributeArticlesFormDeliver";
import DistributeArticlesFormDistribution from "./components/DistributeArticlesFormDistribution";
import DistributeArticlesFormOperation from "./components/DistributeArticlesFormOperation";
import { DistributeArticlesSchema } from "./types/DistributeArticlesSchema";

export default function DistributeArticlesForm({ publisher, articles, channels, onClose }) {
	const { t } = useTranslation();

	const [filteredChannels, loading] = useArticlesDistributionChannels({ publisher, articles });

	const selectedChannels = channels || filteredChannels;

	const { form, onSubmit, error } = useSimpleForm({
		schema: DistributeArticlesSchema,
		defaultValues: {
			operation: null,
			delivery: {
				metadata: true,
				artifact: true,
			},
		},
		transform: ({ delivery, channels }) => {
			const channelIds = Object.entries(channels)
				.filter(([, value]) => value)
				.map(([id]) => id);

			return {
				delivery,
				channels: filteredChannels.filter(({ id }) => channelIds.includes(id)),
			};
		},
		onSubmit: ({ delivery, channels }) => {
			return distributeArticles(firebase, { publisher, articles, channels, delivery });
		},
		onSuccess: onClose,
	});

	return (
		<Form.Modal
			size="medium"
			header={t("distribute", "Distribute")}
			form={form}
			error={error}
			submitText={t("distribute", "Distribute")}
			onSubmit={onSubmit}
			onClose={onClose}
		>
			<LoadingContext data={filteredChannels} loading={loading}>
				<DistributeArticlesFormDeliver />
				<DistributeArticlesFormOperation />
				<DistributeArticlesFormChannels
					filteredChannels={filteredChannels}
					selectedChannels={selectedChannels}
				/>
				<DistributeArticlesFormDistribution articles={articles} />
				<DistributeArticlesFormArticles articles={articles} />
			</LoadingContext>
		</Form.Modal>
	);
}
