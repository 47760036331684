import firebase from "firebase/app";

import React from "react";
import { useTranslation } from "react-i18next";

import getDistributionChannelsQuery from "astrid-firestore/src/api/distribution/getDistributionChannelsQuery";

import api from "../../../../api";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";
import PipelineStepActionArgs from "../../../pipelines/components/PipelineForm/components/PipelineStepActionArgs";
import PipelineStepActionSelect from "../../../pipelines/components/PipelineForm/components/PipelineStepActionSelect";

export default function CreateDistributionArgsForm({ publisher, editData = null, onClose }) {
	const { t } = useTranslation();

	const { form, onSubmit, error, watch } = useSimpleForm({
		// schema: DistributionChannelSettings,
		values: {
			channel: editData ? { id: editData?.channelId } : null,
			action: editData ? { action: editData.action } : null,
			args: editData ? { args: editData?.args } : null,
		},
		onSubmit: ({ channel, action, args }) => {
			return api.organizations.addDistributionArgs({
				publisher,
				channel,
				action: action.action,
				args: args.args,
			});
		},
		onSuccess: onClose,
	});

	const { action } = watch();

	return (
		<Form.Modal
			form={form}
			header={t("addArgs", "Add args")}
			size="tiny"
			onSubmit={onSubmit}
			onClose={onClose}
			error={error}
		>
			<Form.CollectionSelect
				name="channel"
				label={t("channel", "Channel")}
				query={getDistributionChannelsQuery(firebase)}
			/>

			<PipelineStepActionSelect name="action" />

			<Form.Field name="args">
				<PipelineStepActionArgs name="root" action={action?.action} />
			</Form.Field>
		</Form.Modal>
	);
}
