import { dotObject } from "./dotObject";

export default function parseData(schema, data, partial = false) {
	if (!schema) {
		throw new Error("schema is required");
	}

	if (!data) {
		throw new Error("Data is required");
	}

	const objectData = dotObject(data);

	const name = schema.name || "Schema";
	const type = partial ? schema.partial() : schema;

	const result = type.safeParse(objectData);

	if (!result.success) {
		throw new Error(`${name} ${result.error?.message}`);
	}

	return result.data;
}
