import { useTranslation } from "react-i18next";

import { dropdownMenuColumn, timestampColumn } from "../../../../ui/utils/columns";

import ArticleDeliveryStatus from "../../ArticleDeliveryStatus/ArticleDeliveryStatus";

import useArticleDistributionChannelDropdownMenuOptions from "./useArticleDistributionChannelDropdownMenuOptions";

export default function useArticleDistributionChannelsTableColumns({ article }) {
	const { t } = useTranslation();

	const dropdownMenuOptions = useArticleDistributionChannelDropdownMenuOptions({ article });

	return [
		{
			id: "name",
			Header: t("channel", "Channel"),
		},
		{
			id: "distribution.announcementDate",
			Header: t("announceAt", "Announce"),
			accessor: ({ id }) =>
				article.channels[id]?.distribution?.announcementDate || article.distribution?.announcementDate,
		},
		{
			id: "distribution.releaseDate",
			Header: t("release", "Release"),
			accessor: ({ id }) => article.channels[id]?.distribution?.releaseDate || article.distribution?.releaseDate,
		},
		{
			id: "distribution.takedownDate",
			Header: t("takedownAt", "Unpublish"),
			accessor: ({ id }) =>
				article.channels[id]?.distribution?.takedownDate || article.distribution?.takedownDate,
		},
		{
			id: "status",
			collapsing: true,
			disableSortBy: true,
			Header: t("status", "Status"),
			Cell: ({ row }) => {
				return <ArticleDeliveryStatus article={article} channel={row.original} />;
			},
		},
		dropdownMenuColumn(dropdownMenuOptions),
	];
}
