import { Input, Menu, Search } from "semantic-ui-react";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useDebounce from "astrid-hooks/src/useDebounce";

import InstantSearchData from "../../../ui/components/InstantSearch/InstantSearchData";

import { useOrganizationId } from "../../../organizations/state/organizationId";
import { useOrganizationType } from "../../../organizations/state/organizationType";

import resultRenderer from "./components/resultRenderer";

export default function QuickSearch() {
	const { t } = useTranslation();

	const orgId = useOrganizationId();
	const orgType = useOrganizationType();

	const navigate = useNavigate();

	const [search, setSearch] = useState("");
	const debouncedSearch = useDebounce(search, 500);

	const onSearch = (e, { value }) => {
		setSearch(value);
	};

	const onSelect = (e, { result }) => {
		setSearch("");

		setTimeout(() => {
			document.getElementById("search").blur();
		}, 10);

		navigate("/production/" + result.id);
	};

	return (
		<Menu.Item as="label" style={{ cursor: "pointer", margin: 0, width: 140 }}>
			<InstantSearchData
				indexName="productions"
				searchQuery={debouncedSearch}
				configure={{
					restrictSearchableAttributes: [
						"title",
						"series",
						"isbn",
						"ebookISBN",
						"partISBN",
						"readerNames",
						"author",
					],
					filters: `${orgType}:${orgId}`,
				}}
			>
				{({ hits }) => (
					<Search
						placeholder={t("search", "Search") + "..."}
						size="small"
						icon={false}
						input={<Input transparent />}
						value={search}
						onSearchChange={onSearch}
						results={hits.map((hit) => {
							const author = hit.author?.join(", ");

							return {
								key: hit.objectID,
								id: hit.objectID,
								title: hit.title,
								description: author,
								image: hit.imgThumb,
								language: hit.language,
							};
						})}
						resultRenderer={(value) => resultRenderer({ value })}
						onResultSelect={onSelect}
						noResultsMessage={t("noMatches")}
						noResultsDescription={<small>{t("quickSearchText")}</small>}
					/>
				)}
			</InstantSearchData>
		</Menu.Item>
	);
}
