import firebase from "firebase/app";

import React from "react";
import { useTranslation } from "react-i18next";

import { Article } from "astrid-firestore/src/api/articles/types/Article";
import updateArticle from "astrid-firestore/src/api/articles/updateArticle";

import ArticleDistributionDatesTable from "../../../../articles/components/ArticleDistributionDatesTable/ArticleDistributionDatesTable";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

import AdminForm from "../../../components/AdminForm/AdminForm";

export default function AdminArticleDistributionDatesForm({ article, channels }) {
	const { t } = useTranslation();

	const form = useSimpleForm({
		schema: Article,
		defaultValues: {
			ref: article.ref,
			distribution: article.distribution || {},
			channels: Object.entries(article.channels || {}).reduce(
				(acc, [id, { distribution }]) => ({
					...acc,
					[id]: { distribution },
				}),
				{},
			),
		},
		onSubmit: (data) => {
			return updateArticle(firebase, data);
		},
	});

	return (
		<AdminForm header={t("dates", "Dates")} {...form}>
			<ArticleDistributionDatesTable channels={channels} form={form} />
		</AdminForm>
	);
}
