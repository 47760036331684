import React from "react";
import { useTranslation } from "react-i18next";

import Flex from "../../../components/Flex/Flex";
import { dropdownMenuColumn, timestampColumn } from "../../ui/utils/columns";

import ArticleCoverImage from "../components/ArticleCoverImage/ArticleCoverImage";
import ArticleDeliveryStatus from "../components/ArticleDeliveryStatus/ArticleDeliveryStatus";

import useArticleDropdownMenuOptions from "./useArticleDropdownMenuOptions";
import useArticleTypeTranslations from "./useArticleTypeTranslations";

export default function useArticlesTableColumns({ bundle, production } = {}) {
	const { t } = useTranslation();

	const dropdownMenuOptions = useArticleDropdownMenuOptions({ bundle, production });

	const articleTypeTranslations = useArticleTypeTranslations();

	return [
		{
			id: "name",
			collapsing: true,
			Header: t("Name", "Name"),
			Cell: ({ value, row }) => {
				return (
					<Flex justifyContent="flex-start">
						<ArticleCoverImage
							thumb
							rounded
							article={row.original}
							style={{ marginRight: "1em", height: 45, width: 45 }}
						/>
						<span>
							{value} {row.original.episodeName && ` - ${row.original.episodeName}`}
						</span>
					</Flex>
				);
			},
		},
		{
			id: "status",
			collapsing: true,
			disableSortBy: true,
			Header: t("status", "Status"),
			Cell: ({ row }) => {
				return <ArticleDeliveryStatus article={row.original} />;
			},
		},
		{
			id: "type",
			filter: "select",
			Header: t("type", "Type"),
			collapsing: true,
			accessor: ({ type }) => articleTypeTranslations[type],
		},
		{
			id: "isbn",
			Header: t("isbn", "ISBN"),
			collapsing: true,
		},
		timestampColumn({ id: "deliveryDate", Header: t("deliveryDate", "Delivery date"), time: false }),
		timestampColumn({ id: "updated", Header: t("updated", "Updated"), time: false }),
		timestampColumn({ id: "created", Header: t("created", "Created"), time: false }),
		dropdownMenuColumn(dropdownMenuOptions),
	];
}
