import React from "react";
import { useTranslation } from "react-i18next";

import PageView from "../../../ui/views/PageView/PageView";

export default function SuperAdminAgreementsView(props) {
	const { t } = useTranslation();

	// const options = useSuperAdminAgreementsMenuOptions();

	return <PageView header={t("agreements", "Agreements")} {...props} />;
}
