import firebase from "firebase/app";

import { useNavigate } from "react-router-dom";

import getDistributionsQuery from "astrid-firestore/src/api/distribution/getDistributionsQuery";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import useDistributionsTableColumns from "../../hooks/useDistributionsTableColumns";

export default function DistributionsCollectionDataTable({ article, bundle, limit, ...props }) {
	const navigate = useNavigate();

	const columns = useDistributionsTableColumns();
	const query = getDistributionsQuery(firebase, { article, bundle, limit });

	const onClick = ({ id, article, publisher }) => {
		navigate(`/publisher/${publisher.id}/admin/articles/${article.id}/distributions/${id}`);
	};

	return <CollectionDataTable query={query} columns={columns} onClick={onClick} {...props} />;
}
