import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../../authorization/hooks/useHasRight";

import SuperAdminApproveOffersView from "../../views/SuperAdminApproveOffersView/SuperAdminApproveOffersView";

export default function SuperAdminApproveOffers() {
	const hasRight = useHasRight();
	const { needsApproval } = useOutletContext();

	if (!hasRight("offers.admin")) {
		return <Unauthorized />;
	}

	const { offers, loading, error } = needsApproval;

	return <SuperAdminApproveOffersView offers={offers} loading={loading} error={error} />;
}
