import useDetectScroll from "@smakss/react-scroll-direction";

import { Grid, Header, Ref } from "semantic-ui-react";

import React from "react";
import useMeasure from "react-use/lib/useMeasure";
import useWindowSize from "react-use/lib/useWindowSize";

import Flex from "../../../../components/Flex/Flex";
import Section from "../../../ui/components/Section/Section";

const offset = 120;

export default function PageView({ header, extra, sidebar, maxWidth, children }) {
	const [headerRef, headerSize] = useMeasure();

	const { width } = useWindowSize();
	const { scrollPosition } = useDetectScroll();

	const large = width > 600;

	const transition = large ? (scrollPosition.top > offset ? 1 : 0) : 0;

	const sidebarStyle = {
		position: "sticky",
		top: 80,
		marginTop: -headerSize.height,
	};

	const sidebarHeaderStyle = {
		opacity: transition,
		transformOrigin: "0 0",
		transition: "opacity 0.2s",
	};

	return (
		<>
			<Section maxWidth={maxWidth || (!sidebar && 1000) || undefined}>
				<Flex justifyContent="space-between">
					<Section.Header as="h1">{header}</Section.Header>

					{extra && (
						<div>
							<Flex gap={15} justifyContent="flex-end">
								{extra}
							</Flex>
						</div>
					)}
				</Flex>
			</Section>
			<Section maxWidth={maxWidth || (!sidebar && 1000) || undefined}>
				<Grid relaxed>
					{sidebar && (
						<Grid.Column width={large ? 3 : 16} style={{ paddingTop: 5 }}>
							<div style={sidebarStyle}>
								<Ref innerRef={headerRef}>
									<Header as="h3" style={sidebarHeaderStyle}>
										{header}
									</Header>
								</Ref>

								{sidebar}
							</div>
						</Grid.Column>
					)}

					<Grid.Column width={large && sidebar ? 13 : 16} style={{ paddingTop: 20 }}>
						{children}
					</Grid.Column>
				</Grid>
			</Section>
		</>
	);
}
