import { useOutletContext, useParams } from "react-router-dom";

import CreateProductionPublisherView from "../views/CreateProductionPublisherView/CreateProductionPublisherView";

export default function CreateProductionPublisher() {
	const { organization } = useOutletContext();
	const { articleId } = useParams();

	return <CreateProductionPublisherView organization={organization} articleId={articleId} />;
}
