import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import CreateSerieForm from "./CreateSerieForm";

export default function CreateSerieButton({ organization }) {
	const { t } = useTranslation();

	const text = t("createSeries", "Create series");

	return (
		<OpenButton text={text} primary>
			{({ setOpen }) => (
				<CreateSerieForm title={text} organization={organization} onClose={() => setOpen(false)} />
			)}
		</OpenButton>
	);
}
