import createDocumentIdAndRef from "./createDocumentIdAndRef";

export default function createImprint({ api, env }, { production, publisher }) {
	const { id, ref } = createDocumentIdAndRef({ api, env }, { collection: "imprints" });
	return {
		id,
		ref,
		name: production?.biblio?.imprintName,
		publisher,
	};
}
