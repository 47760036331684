import useDetectScroll from "@smakss/react-scroll-direction";

import { Image, Segment, Menu as SemanticMenu } from "semantic-ui-react";

import React from "react";
import { Link } from "react-router-dom";
import useWindowSize from "react-use/lib/useWindowSize";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import Flex from "../../../../components/Flex/Flex";
import MenuItems from "../../../ui/components/Menu/components/MenuItems";

import useHasRight from "../../../authorization/hooks/useHasRight";
import { useOrganizationType } from "../../../organizations/state/organizationType";
import QuickSearch from "../../../productions/components/QuickSearch/QuickSearch";

import LanguageMenu from "./components/LanguageMenu";
import OrganizationMenu from "./components/OrganizationsMenu";
import SecondaryMenu from "./components/SecondaryMenu";
import useMainMenuOptions from "./hooks/useMainMenuOptions";

const height = 55;

export default function MainMenu() {
	const orgType = useOrganizationType();

	const hasRight = useHasRight();
	const options = useMainMenuOptions();

	const { width } = useWindowSize();
	const { scrollPosition } = useDetectScroll();

	const transition = width > 600 ? (scrollPosition.top > height ? 1 : 0) : 0;

	const segmentStyle = {
		position: "sticky",
		top: -height,
		left: 0,
		right: 0,
		zIndex: 999,
		height: 96,
		padding: 0,
		borderColor: "rgba(0, 0, 0, 0.1)",
	};

	const iconStyle = {
		transform: transition ? "translateY(48px) scale(0.8)" : "translateY(0) scale(1)",
		transition: transition ? "transform 0.4s ease" : "transform 0.3s ease",
	};

	const menuStyle = {
		margin: 0,
		padding: "0 15px 3px 8px",
		transform: transition ? "translateX(40px)" : "translateX(0)",
		transition: transition ? "transform 0.3s ease" : "transform 0.4s ease",
		borderBottomWidth: 0,
		borderTop: "1px solid rgba(0, 0, 0, 0.05)",
		overflowX: "auto",
		scrollbarWidth: "none",
	};

	const divider = <SemanticMenu.Item style={{ opacity: 0.2, fontSize: 18 }}>/</SemanticMenu.Item>;

	return (
		<Segment attached style={segmentStyle}>
			<Flex style={{ height, padding: "0 15px" }}>
				<SemanticMenu text style={{ margin: 0 }}>
					<SemanticMenu.Item as={Link} to="/" style={iconStyle}>
						<Image src="/img/astrid-icon.svg" size="small" style={{ height: 22, width: "auto" }} />
					</SemanticMenu.Item>

					{divider}

					<OrganizationMenu />

					{hasRight("productions.quickSearch") && orgType !== organizationTypes.ADMIN && (
						<>
							{divider}

							<QuickSearch />
						</>
					)}
				</SemanticMenu>

				<Flex gap={15} justifyContent="flex-end">
					<LanguageMenu />
					<SecondaryMenu />
				</Flex>
			</Flex>

			{options?.length > 0 && (
				<SemanticMenu fluid secondary pointing style={menuStyle}>
					<MenuItems options={options} />
				</SemanticMenu>
			)}
		</Segment>
	);
}
