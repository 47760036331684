import { useNavigate } from "react-router-dom";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import useOrdersQuery from "../../../orders/hooks/useOrdersQuery";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

import useAdminOrdersColumns from "./hooks/useAdminOrdersColumns";

export default function AdminOrdersView({ header, expense, firstPartyId }) {
	const navigate = useNavigate();

	const query = useOrdersQuery({ expense, firstPartyId });
	const columns = useAdminOrdersColumns();

	return (
		<>
			<AdminHeader>{header}</AdminHeader>

			<CollectionDataTable
				persist
				query={query}
				columns={columns}
				onClick={({ id }) => navigate(id)}
				sortBy={{
					id: "created",
					desc: true,
				}}
			/>
		</>
	);
}
