import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import mergeArticleChannelDataWithPresetChannelData from "astrid-firestore/src/api/distribution/utils/mergeArticleChannelDataWithPresetChannelData";

import DataTable from "../../../../ui/components/DataTable/DataTable";

import AdminCard from "../../../components/AdminCard/AdminCard";

import displaySelectedCountries from "../utils/displaySelectedCountries";

import AdminArticleDistributionCountriesForm from "./AdminArticleDistributionCountriesForm";

export default function AdminArticleDistributionCountries({ channels, publisher, article }) {
	const { t } = useTranslation();
	const [selectedChannel, setSelectedChannel] = useState(null);

	const mergedArticleData = mergeArticleChannelDataWithPresetChannelData({
		channelIds: channels?.map((channel) => channel.id),
		article,
		publisher,
	});

	return (
		<AdminCard header={t("countries", "Countries")}>
			<DataTable
				data={channels.map((channel) => {
					return {
						id: channel.id,
						name: channel.name,
						countries: mergedArticleData[channel.id]?.countries,
					};
				})}
				onClick={(channel) => setSelectedChannel(channel)}
				columns={[
					{
						id: "name",
						Header: t("channel", "Channel"),
						collapsing: true,
					},
					{
						id: "countries",
						Header: t("countries", "Countries"),
						collapsing: true,
						accessor: ({ countries }) => {
							return displaySelectedCountries(countries);
						},
					},
				]}
			/>
			{selectedChannel && (
				<AdminArticleDistributionCountriesForm
					selectedChannel={selectedChannel}
					article={article}
					onClose={() => setSelectedChannel(null)}
				/>
			)}
		</AdminCard>
	);
}
