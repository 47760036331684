import { useTranslation } from "react-i18next";

import api from "../../../api";

export default function useArticleDropdownMenuOptions({ bundle, production } = {}) {
	const { t } = useTranslation();

	return (article) => [
		{
			text: t("view", "View"),
			linkTo: `/publisher/${article.publisher.id}/admin/articles/${article.id}`,
		},
		{
			text: t("remove", "Remove"),
			hidden: !bundle,
			action: () => {
				return api.articleBundles.removeArticle({ article, bundle });
			},
		},
		{
			text: t("remove", "Remove"),
			hidden: !production,
			action: () => {
				return api.productions.removeArticle({ article, production });
			},
		},
	];
}
