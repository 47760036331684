import { useNavigate } from "react-router-dom";

import DataTable from "../../../ui/components/DataTable/DataTable";

import useApproveOffersColumns from "./hooks/useApproveOffersColumns";

export default function SuperAdminApproveOffersView({ offers, loading, error }) {
	const navigate = useNavigate();
	const columns = useApproveOffersColumns();

	return (
		<DataTable
			data={offers}
			error={error}
			loading={loading}
			columns={columns}
			onClick={({ production }) => navigate(`/production/${production.id}/team`)}
			sortBy={{ id: "expiresAt", desc: false }}
		/>
	);
}
