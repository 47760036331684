import { useOutletContext, useParams } from "react-router-dom";

import db from "astrid-firebase/src/db";
import { useDocumentData, useLoadingValues } from "astrid-firestore/src/hooks";

import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";
import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import AdminPageView from "../components/AdminPage/AdminPageView";
import AdminStudioView from "../views/AdminStudioView/AdminStudioView";

export default function AdminStudio({ gen }) {
	const { id } = useParams();
	const { organization } = useOutletContext();
	const hasRight = useHasRight();

	const [[studioGen2, studioGen1], loading, error] = useLoadingValues(
		useDocumentData(id && db.collection("studios").doc(id)),
		useDocumentData(
			id && organization.id && db.collection("organizations").doc(organization.id).collection("studios").doc(id),
		),
	);

	const studio = studioGen2 || studioGen1;

	if (!hasRight("studios.view")) {
		return <Unauthorized />;
	}

	console.log({ studio, gen });

	return (
		<LoadingContext data={studio} loading={loading} error={error}>
			<AdminPageView gen={gen} studio={studio}>
				<AdminStudioView />
			</AdminPageView>
		</LoadingContext>
	);
}
