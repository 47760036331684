export default function createDocumentData(firebase, collection, data) {
	if (!firebase) {
		throw new Error("Firebase is required");
	}

	if (!collection) {
		throw new Error("Collection is required");
	}

	const db = firebase.firestore();
	const ref = data.id ? db.collection(collection).doc(data.id) : db.collection(collection).doc();

	return {
		id: ref.id,
		ref,
		exists: false,
		created: firebase.firestore.FieldValue.serverTimestamp(),
		...data,
	};
}
