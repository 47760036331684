import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import DataTable from "../../../ui/components/DataTable/DataTable";

import useProductionPricesTableColumns from "./hooks/useProductionPricesTableColumns";

export default function SuperAdminApproveProductionPricesView({ productionPrices, loading, error }) {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const columns = useProductionPricesTableColumns();

	return (
		<DataTable
			data={productionPrices}
			error={error}
			loading={loading}
			columns={columns}
			onClick={({ id, production }) => navigate(`/admin/costs/${production.id}/${id}`)}
			// sortBy={{ id: "created", desc: false }}
		/>
	);
}
