import { Menu } from "semantic-ui-react";

import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import MenuItemContent from "./MenuItemContent";

export default function MenuItems({ divider, options }) {
	return options
		.filter(({ hidden }) => !hidden)
		.map(({ to, icon, text, content, children, loading, label, labelColor, onClick, ...option }, index) => (
			<Fragment key={index}>
				{divider && index > 0 && <Menu.Item>{divider}</Menu.Item>}

				<Menu.Item
					key={index}
					as={to !== undefined ? Link : undefined}
					to={to}
					className="menu-item"
					onClick={onClick}
					style={{ whiteSpace: "nowrap", flexShrink: 0 }}
					{...option}
				>
					<MenuItemContent
						icon={icon}
						text={text}
						content={content}
						loading={loading}
						label={label}
						labelColor={labelColor}
					>
						{children}
					</MenuItemContent>
				</Menu.Item>
			</Fragment>
		));
}
