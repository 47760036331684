import { zodResolver } from "@hookform/resolvers/zod";
import { fromError } from "zod-validation-error";

import { useForm as useReactHookForm } from "react-hook-form";

import useResetFields from "./useResetFields";

function createResolver(schema, onValidationError) {
	const parseAsync = async (values, schemaOptions) => {
		onValidationError?.(null);

		try {
			return await schema.passthrough().parseAsync(values, schemaOptions);
		} catch (error) {
			onValidationError?.(fromError(error));

			throw error;
		}
	};

	return zodResolver({ parseAsync }, {}, { raw: true });
}

export default function useForm({
	schema,
	partial = true,
	resetFields,
	locked = false,
	alteredValues = {},
	onValidationError,
	...props
} = {}) {
	const form = useReactHookForm({
		resolver: schema ? createResolver(partial ? schema.partial() : schema, onValidationError) : undefined,
		...props,
	});

	form.locked = locked;
	form.alteredValues = alteredValues;

	useResetFields(form, resetFields);

	return form;
}
