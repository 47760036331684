import React from "react";
import { useTranslation } from "react-i18next";

import { languageOptions } from "astrid-helpers/src/languages";

import FormSelect from "../FormSelect/FormSelect";

export default function FormLanguageSelect(props) {
	const { t } = useTranslation();

	return <FormSelect label={t("language", "Language")} options={languageOptions} search {...props} />;
}
