import firebase from "firebase/app";

import React from "react";
import { useTranslation } from "react-i18next";

import updateArticle from "astrid-firestore/src/api/articles/updateArticle";

import DistributionCountriesCheckboxTable from "../../../../distribution/components/DistributionCountriesCheckboxTable/DistributionCountriesCheckboxTable";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

export default function AdminArticleDistributionCountriesForm({ article, selectedChannel, onClose }) {
	const { t } = useTranslation();

	const { form, onSubmit, error } = useSimpleForm({
		defaultValues: {
			ref: article.ref,
			countries: article.channels[selectedChannel.id]?.countries || [],
		},
		onSubmit: ({ ref, countries }) => {
			return updateArticle(firebase, {
				ref,
				channels: {
					[selectedChannel.id]: {
						countries,
					},
				},
			});
		},
		onSuccess: onClose,
	});

	return (
		<Form.Modal
			form={form}
			size="tiny"
			header={t("editCountris", "Edit countries")}
			onSubmit={onSubmit}
			onClose={onClose}
			error={error}
			scrolling
		>
			<DistributionCountriesCheckboxTable name="countries" />
		</Form.Modal>
	);
}
