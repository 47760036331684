import * as Sentry from "@sentry/react";

import "semantic-ui-less/semantic.less";

import { useEffect } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datetime/css/react-datetime.css";
import "react-phone-number-input/style.css";
import { Outlet } from "react-router-dom";

import { isLive } from "astrid-helpers/src/env";

import Footer from "./components/Footer";
import OfflineMessage from "./components/OfflineMessage";
import UpdateMessage from "./components/UpdateMessage";
import "./css/global.css";
import { useProfile } from "./features/authentication/state/profile";
import { useUser } from "./features/authentication/state/user";
import useCrisp from "./features/crisp/hooks/useCrisp";
import useScrollToTop from "./features/ui/hooks/useScrollToTop";
import useStore from "./features/ui/hooks/useStore";

function App() {
	useCrisp();
	useScrollToTop();

	const user = useUser();
	const profile = useProfile();

	// start: handle legacy state
	const { setState } = useStore();

	useEffect(() => {
		setState({ profile });
	}, [setState, profile]);

	useEffect(() => {
		setState({ user });
	}, [setState, user]);
	// stop: handle legacy state
	// force github action to run

	return (
		<div className={isLive ? "" : "stage-server"}>
			<UpdateMessage />
			<OfflineMessage />

			<Outlet />

			<Footer />
		</div>
	);
}

export default Sentry.withProfiler(App);
