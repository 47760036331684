import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function useSuperAdminDistributionChannelMenuOptions({ channel }) {
	const { t } = useTranslation();

	const location = useLocation();

	const { pathname } = location;

	return [
		{
			path: "",
			text: t("settings", "Settings"),
			active: pathname.endsWith(channel.id),
		},
		{
			path: "pipelines",
			text: t("pipelines", "Pipelines"),
			active: pathname.includes("pipelines"),
		},
	];
}
