import createDocumentIdAndRef from "./createDocumentIdAndRef";

export default function createSeries({ api, env }, { name, articles, commonArticleData }) {
	const { id, ref } = createDocumentIdAndRef({ api, env }, { collection: "series" });
	return {
		id,
		ref,
		name,
		titleIds: [],
		articleIds: articles.map((article) => article.id),
		publisher: commonArticleData.publisher,
	};
}
