import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Script } from "astrid-firestore/src/api/scripts/types/Script";
import useBucket from "astrid-hooks/src/useBucket";

import api from "../../../../api";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

export default function CreateScriptForm({ publisher, production, onClose }) {
	const { t } = useTranslation();

	const { putFile, deleteFile } = useBucket("earselect-scripts");

	const { form, watch, setValue, onSubmit, error } = useSimpleForm({
		schema: Script,
		values: {
			script: null,
			targetFile: null,
			pages: null,
		},
		onSubmit: async ({ script, targetFile, pages }) => {
			if (production && script?.exists) {
				return api.productions.addScript({ production, script });
			}

			const file = targetFile ? await putFile(`${script.id}/${targetFile.name}`, targetFile) : null;

			try {
				return await api.scripts.create({ ...script, file, pages }, { production });
			} catch (error) {
				if (file) {
					await deleteFile(file);
				}

				throw error;
			}
		},
		onSuccess: onClose,
	});

	const { script, targetFile } = watch();

	useEffect(() => {
		if (targetFile?.name && !production?.title) {
			setValue("name", targetFile.name.replace(/\.[^/.]+$/, ""));
		}
	}, [production?.title, setValue, targetFile]);

	return (
		<Form.Modal
			size="tiny"
			header={t("uploadScript", "Upload script")}
			form={form}
			error={error}
			onSubmit={onSubmit}
			onClose={onClose}
		>
			{/*<Form.CollectionSelect*/}
			{/*	name="script"*/}
			{/*	label={t("scriptName", "Script name")}*/}
			{/*	collection={api.scripts}*/}
			{/*	query={{ publisher, file: !production ? null : undefined }}*/}
			{/*	search*/}
			{/*	allowEmpty*/}
			{/*	allowAdditions*/}
			{/*	onCreateData={(name) => {*/}
			{/*		return { name, publisher };*/}
			{/*	}}*/}
			{/*/>*/}

			{script && !script.file && (
				<>
					<Form.FileInput name="targetFile" label={t("file", "file")} />

					<Form.Group widths="equal">
						<Form.NumberInput name="pages.start" label={t("startPage", "Start page")} />

						<Form.NumberInput name="pages.end" label={t("endPage", "End page")} />
					</Form.Group>
				</>
			)}
		</Form.Modal>
	);
}
