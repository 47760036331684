import React from "react";
import { useTranslation } from "react-i18next";

import useCountryOptions from "astrid-hooks/src/useCountryOptions";

import Form from "../../../forms/components/Form/Form";

export default function DistributionCountriesCheckboxTable({ name, label }) {
	const { t } = useTranslation();

	const value = Form.useFormContext().watch(name);

	const countryOptions = useCountryOptions();

	return (
		<div>
			{label && (
				<div className="field" style={{ marginBottom: 0 }}>
					<label>{label}</label>
				</div>
			)}

			<Form.FormCheckboxTable
				name={name}
				value={value.includes("WORLD") ? countryOptions.map(({ value }) => value) : value}
				options={countryOptions}
				transformValue={(value) => {
					return value.length === countryOptions.length ? ["WORLD"] : value;
				}}
			/>
		</div>
	);
}
