import { ArticleRef } from "astrid-firestore/src/api/articles/types/ArticleRef";

import createDocumentIdAndRef from "./createDocumentIdAndRef";

export default function createArticleBundle({ api, env }, { production, articles }) {
	const { id, ref } = createDocumentIdAndRef({ api, env }, { collection: "articleBundles" });
	return {
		id,
		ref,
		name: production.title,
		isbns: articles.map((article) => article.isbn),
		articles: articles.map((article) => ArticleRef.parse(article)),
		articleIds: articles.map((article) => article.id),
		publisher: articles[0].publisher,
	};
}
