import React from "react";
import { useTranslation } from "react-i18next";

import { dropdownMenuColumn, timestampColumn } from "../../ui/utils/columns";

import ArtifactDefaultArticleArtifactIcon from "../components/ArtifactIcons/ArtifactDefaultArticleArtifactIcon";

import useArtifactDropdownMenuOptions from "./useArtifactDropdownMenuOptions";

export default function useArtifactsTableColumns({ article } = {}) {
	const { t } = useTranslation();

	return [
		article && {
			id: "default",
			collapsing: true,
			disableSortBy: true,
			Cell: ({ row }) => {
				return <ArtifactDefaultArticleArtifactIcon article={article} artifact={row.original} />;
			},
		},
		{
			id: "name",
			Header: t("Name", "Name"),
			disableSortBy: true,
		},
		{
			id: "files",
			Header: t("parts", "Files"),
			collapsing: true,
			disableSortBy: true,
			accessor: ({ files }) => files.length,
		},
		timestampColumn({ id: "created", Header: t("created", "Created") }),
		dropdownMenuColumn(useArtifactDropdownMenuOptions({ article })),
	];
}
