import firebase from "firebase/app";

import React from "react";
import { useTranslation } from "react-i18next";

import { Article } from "astrid-firestore/src/api/articles/types/Article";
import updateArticle from "astrid-firestore/src/api/articles/updateArticle";

import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

export default function ArticleDistributionChannelsEditForm({ article, channels, onClose }) {
	const { t } = useTranslation();

	const { form, onSubmit, error } = useSimpleForm({
		schema: Article,
		defaultValues: {
			channelIds: article.channelIds || [],
		},
		onSubmit: (data) => {
			return updateArticle(firebase, data);
		},
		onSuccess: onClose,
	});

	return (
		<Form.Modal
			size="tiny"
			header={t("distributionChannels", "Distribution channels")}
			form={form}
			error={error}
			onSubmit={onSubmit}
			onClose={onClose}
		>
			<Form.FormCheckboxTable
				name="channelIds"
				options={channels.map(({ id, name }) => ({ key: id, value: id, text: name }))}
			/>
		</Form.Modal>
	);
}
