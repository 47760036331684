import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import Button from "../../../ui/components/Buttons/Button";
import PageView from "../../../ui/views/PageView/PageView";

import AutoApprovalRules from "./components/AutoApprovalRules";
import OrganizationActions from "./components/OrganizationActions";
import ProducerDistributors from "./components/ProducerDistributors";
import ProducerPublishers from "./components/ProducerPublishers";
import ProducerSettings from "./components/ProducerSettings";

export default function SuperAdminOrganizationView({ organization }) {
	const { t } = useTranslation();
	const { pathname } = useLocation();

	return (
		<PageView
			header={organization.name}
			extra={
				<Button primary as={Link} to={`/${organization.type}/${organization.id}/admin`} state={{ pathname }}>
					{t("manage", "Manage")}
				</Button>
			}
		>
			{organization.type === "producer" && (
				<>
					<ProducerSettings producer={organization} />
					<ProducerPublishers producer={organization} />
					<ProducerDistributors producer={organization} />
					<AutoApprovalRules organization={organization} />
				</>
			)}

			{organization.type === "publisher" && <OrganizationActions organization={organization} />}
		</PageView>
	);
}
