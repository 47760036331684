import { useNavigate } from "react-router-dom";

import { db } from "astrid-firebase";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import useDistributionChannelsTableColumns from "../../hooks/useDistributionChannelsTableColumns";

export default function DistributionChannelsCollectionDataTable({ getQuery = (query) => query, ...props }) {
	const navigate = useNavigate();

	const columns = useDistributionChannelsTableColumns();

	return (
		<CollectionDataTable
			filterable={false}
			query={getQuery(db.collection("distributionChannels").where("deleted", "==", false))}
			columns={columns}
			onClick={({ id }) => navigate(`/admin/distribution/channels/${id}`)}
			sortBy={{
				id: "name",
				desc: false,
			}}
			searchableKeys={["name"]}
			{...props}
		/>
	);
}
