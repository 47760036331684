import { StorageFile } from "../../../types/StorageFile";
import { array, boolean, duration, int, isbn, nullable, object, string } from "../../../types/_types";

import { ImprintRef } from "../../imprints/types/ImprintRef";
import { SerieRef } from "../../series/types/Serie";

import { ArticleContributor } from "./ArticleContributor";
import { ArticlePages } from "./ArticlePages";
import { ArticleRef } from "./ArticleRef";

export const ArticleMetaData = object({
	abridged: boolean().default(false),
	additionalLanguages: array(string()),
	author: nullable(string()),
	contributors: array(ArticleContributor),
	cover: nullable(StorageFile.extend({ thumbUrl: nullable(string()) })),
	duration: nullable(duration()),
	episodeName: nullable(string(3)),
	imprint: nullable(ImprintRef),
	isbn: nullable(isbn()),
	language: nullable(string()),
	linkedArticle: nullable(ArticleRef),
	name: string(3),
	narrator: nullable(string()),
	originalLanguage: nullable(string()),
	originalName: nullable(string()),
	pages: nullable(ArticlePages),
	serie: nullable(SerieRef),
	serieNumber: nullable(int()),
	synopsis: nullable(string()),
	tags: nullable(array(string())),
	themaCodes: array(string()),
	translator: nullable(string()),
});
