import articleDeliveryStatuses from "astrid-firestore/src/api/articles/constants/articleDeliveryStatuses";
import { ArticleChannel, ArticleChannels } from "astrid-firestore/src/api/articles/types/ArticleChannel";
import { getCollectionData } from "astrid-firestore/src/helpers";
import { formatDate } from "astrid-web/src/helpers/date";

const CHANNEL_NAME_MAPPING = {
	bookbeat: "Bookbeat",
	storytel: "Storytel",
	storytelFilesDotCom: "Storytel",
	storytelOld: "Storytel",
	litres: "LitRes",
	nextory: "Nextory",
	nextory_audio: "Nextory",
	nextory_ebook: "Nextory",
	overdrive: "Overdrive",
	ellibs: "Ellibs",
	elisa: "Ellibs",
	fabel: "Fabel",
	audibleDE: "Audible DE",
	audibleUK: "Audible UK",
	bookmate: "Bookmate",
	bokbasen: "Bokbasen",
	bokbasenApi: "Bokbasen",
	axiellFtp: "Axiell",
	publit: "Publit",
	bonnierWav: "Bonnier",
	bonnierMp3: "Bonnier",
	polaris: "Polaris",
	nfg: "NFG",
	bookwire: "Bookwire",
	clipsource: "Clip source",
	kungligaBiblioteket_audio: "Kungliga Biblioteket",
	kungligaBiblioteket_ebook: "Kungliga Biblioteket",
	/*
	// Removed - do we need to handle history?
	storytool,
	storytoolWav,
	storytoolMp3,
	Landsbókasafn
	optimal
	bod
	*/
};

function prioritizeChannel(channel, releaseData) {
	if (channel === "storytel") {
		if (releaseData.storytelFilesDotCom) {
			// if we have storytelFilesDotCom history, skip old stuff.
			return false;
		}
	}

	if (channel === "storytelOld") {
		if (releaseData.storytelFilesDotCom) {
			// if we have storytelFilesDotCom history, skip old stuff.
			return false;
		} else if (releaseData.storytel) {
			// prioritzie storytel over storytelOld
			return false;
		}
	}

	if (channel === "nextory") {
		if (releaseData.nextory_audio || releaseData.nextory_ebook) {
			// if we have nextory_audio/ebook history, skip old stuff.
			return false;
		}
	}

	return true;
}

function mapArtifactReleaseData({ production, articleType }) {
	return Object.entries(production.master[articleType].exports).reduce((acc, [_, exportData]) => {
		return {
			...acc,
			...Object.entries(exportData.distributor).reduce((acc, [channel, distributorData]) => {
				return {
					...acc,
					[channel]: { sent: distributorData.sent },
				};
			}, {}),
		};
	}, {});
}

function mapMetadataReleaseData({ release }) {
	return Object.entries(release).reduce((acc, [channel, metadata]) => {
		return {
			...acc,
			[channel]: {
				sent: metadata.metaSent || null,
				announcementDate: metadata.announcementDate || null,
				releaseDate: metadata.date || null,
				takedownDate: metadata.takedown || null,
				countries: metadata.countries || null,
			},
		};
	}, {});
}

function combineReleaseData({ meta, artifact }) {
	const combined = {};

	const platforms = new Set([...Object.keys(meta), ...Object.keys(artifact)]);

	platforms.forEach((platform) => {
		combined[platform] = {
			meta: meta[platform] || {},
			artifact: artifact[platform] || {},
		};
	});

	return combined;
}

function getReleaseData({ production, articleType }) {
	if (articleType === "total") {
		const meta = mapMetadataReleaseData({ release: production.release });
		const artifact = mapArtifactReleaseData({ production, articleType });
		return combineReleaseData({ meta, artifact });
	} else if (articleType.includes("part")) {
		const partNumber = articleType.split("_")[1];
		const meta = mapMetadataReleaseData({
			release: production.deliveryParts[partNumber].release,
		});
		const artifact = mapArtifactReleaseData({ production, articleType });
		return combineReleaseData({ meta, artifact });
	} else if (articleType === "ebook") {
		const meta = mapMetadataReleaseData({ release: production.deliveryEbook.release });
		const artifact = meta;
		return combineReleaseData({ meta, artifact });
	} else {
		throw new Error(`Unknown article type when fetching release data: ${articleType}`);
	}
}

export default async function createChannels({ api, env }, { production, articleType }) {
	const distributionChannels = api.convertFromLiveToStage
		? await getCollectionData(api.stage.db.collection("distributionChannels"))
		: await getCollectionData(api[env].db.collection("distributionChannels"));
	const releaseData = getReleaseData({ production, articleType });

	const channels = Object.entries(releaseData)
		.filter(([channelKey, _]) => prioritizeChannel(channelKey, releaseData))
		.reduce((acc, [channelKey, releaseData]) => {
			const channelName = CHANNEL_NAME_MAPPING[channelKey];
			if (!channelName) {
				throw new Error(`Unknown channel: ${channelKey}`);
			}
			const { meta, artifact } = releaseData;
			const channelId = distributionChannels.find((channel) => channel.name === channelName)?.id;

			if (!channelId) {
				// means we have not created the distribution channel yet.
				console.warn(`Channel ${channelName} not found in distributionChannels`);
				return acc;
			}

			return {
				...acc,
				[channelId]: ArticleChannel.parse({
					delivery: {
						artifact: {
							status: artifact.sent
								? articleDeliveryStatuses.DELIVERY_COMPLETED
								: articleDeliveryStatuses.DELIVERY_NEEDED,
							sent: artifact.sent ? api.Timestamp(artifact.sent) : null,
						},
						metadata: {
							status: meta.sent
								? articleDeliveryStatuses.DELIVERY_COMPLETED
								: articleDeliveryStatuses.DELIVERY_NEEDED,
							sent: meta.sent ? api.Timestamp(meta.sent) : null,
						},
					},
					distribution: {
						announcementDate: meta.announcementDate
							? formatDate(api.Timestamp(meta.announcementDate)?.toDate())
							: null,
						releaseDate: meta.releaseDate ? formatDate(api.Timestamp(meta.releaseDate)?.toDate()) : null,
						takedownDate: meta.takedownDate ? formatDate(api.Timestamp(meta.takedownDate)?.toDate()) : null,
					},
					countries: meta.countries || null,
				}),
			};
		}, {});

	return ArticleChannels.parse(channels);
}
