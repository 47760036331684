import React from "react";
import ListReaders from "../components/reader/ListReaders";
import Right from "../components/Right";

const Reader = (props) => {
	return (
		<Right can="readerRegistry.view">
			<ListReaders {...props} />
		</Right>
	);
};

export default Reader;
