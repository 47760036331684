import getCollectionData from "../../src/utils/getCollectionData";
import getDocumentData from "../../src/utils/getDocumentData";

function hasNoOtherArticlesReferenced(articleIds, comparisonIds) {
	if (articleIds.length !== comparisonIds.length) {
		return false;
	}

	const first = new Set(articleIds);
	const second = new Set(comparisonIds);

	for (const item of first) {
		if (!second.has(item)) {
			return false;
		}
	}
	return true;
}

export default async function revertMigratedProduction({ api, env }, { production }) {
	const documents = production.migrations?.flattening?.createdDocuments;

	const bundle = documents.find((doc) => doc.collection === "articleBundles");
	const imprint = documents.find((doc) => doc.collection === "imprints");
	const series = documents.find((doc) => doc.collection === "series");
	const articles = documents.filter((doc) => doc.collection === "articles");
	const artifacts = documents.filter((doc) => doc.collection === "artifacts");

	const actualBundle = bundle && (await getDocumentData(api[env].db.collection(bundle.collection).doc(bundle.id)));
	const actualImprint =
		imprint && (await getDocumentData(api[env].db.collection(imprint.collection).doc(imprint.id)));
	const actualSeries = series && (await getDocumentData(api[env].db.collection(series.collection).doc(series.id)));

	const articleIds = articles.map((article) => article.id);
	const deleteBundle = bundle && hasNoOtherArticlesReferenced(articleIds, actualBundle.articleIds) && bundle;
	const deleteImprint = imprint && hasNoOtherArticlesReferenced(articleIds, actualImprint.articleIds) && imprint;
	const deleteSeries = series && hasNoOtherArticlesReferenced(articleIds, actualSeries.articleIds) && series;

	const createdExports = await getCollectionData(
		api[env].db.collection("exports").where(
			"article.id",
			"in",
			articles.map((article) => article.id),
		),
	);
	const exports = createdExports?.map((exportDoc) => ({ ...exportDoc, collection: "exports" })) || [];

	const createdDistributions = await getCollectionData(
		api[env].db.collection("distributions").where(
			"article.id",
			"in",
			articles.map((article) => article.id),
		),
	);

	const distributions =
		createdDistributions?.map((distributionDoc) => ({ ...distributionDoc, collection: "distributions" })) || [];

	const documentsToDelete = [
		deleteBundle,
		deleteImprint,
		deleteSeries,
		...artifacts,
		...articles,
		...exports,
		...distributions,
	].filter(Boolean);

	await api[env].db.runTransaction(async (transaction) => {
		documentsToDelete.forEach((doc) => {
			const docRef = api[env].db.collection(doc.collection).doc(doc.id);
			transaction.delete(docRef);
		});

		transaction.update(api[env].db.collection("productions").doc(production.id), {
			articleIds: null,
			["migrations.flattening"]: null,
		});
	});

	return documentsToDelete;
}
