import { useTranslation } from "react-i18next";

export default function useSuperAdminDistributionMenuOptions() {
	const { t } = useTranslation();

	return [
		{
			path: "/admin/distribution/distributions",
			name: t("distributions", "Distributions"),
		},
		{
			path: "/admin/distribution/channels",
			name: t("channels", "Channels"),
		},
	];
}
