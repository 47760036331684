import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import CreateImprintForm from "./CreateImprintForm";

export default function CreateImprintButton({ organization }) {
	const { t } = useTranslation();

	const text = t("createImprint", "Create imprint");

	return (
		<OpenButton text={text} primary>
			{({ setOpen }) => (
				<CreateImprintForm title={text} organization={organization} onClose={() => setOpen(false)} />
			)}
		</OpenButton>
	);
}
