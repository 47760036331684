import { getFileTags } from "./metadata";

export function chaptersToSplitPoints(chapters) {
	return (
		(chapters &&
			Object.entries(chapters)
				.sort(([a], [b]) => a - b)
				.map(([, c]) => ({
					split: "chapter",
					start: c.position / 1000,
					end: (c.position + c.length) / 1000,
					title: c.text,
				}))) ||
		[]
	);
}

export function enrichSplitPoints(article, totalDuration, getFile) {
	return (point, index, points) => {
		const splitBackoff = 0.2;
		const splitIndex = index + 1;
		const splitStart = index ? points[index - 1].end - splitBackoff : 0;
		const splitEnd = !(article === "cd" && index)
			? point.end
				? point.end - splitBackoff
				: totalDuration
			: point.start + splitBackoff;

		const paddedIndex = (splitIndex + "").padStart(3, 0);
		const file = getFile(paddedIndex);
		const seconds = splitEnd - splitStart;

		return {
			...point,
			file,
			seconds,
			paddedIndex,
			splitBackoff,
			splitIndex,
			splitStart,
			splitEnd,
		};
	};
}

export function applyFileTags(escapedTitle) {
	return (point) => ({
		...point,
		fileTags: getFileTags({
			track: point.paddedIndex,
			title: point.title ? `chapter-title:${point.title}` : point.paddedIndex + "_" + escapedTitle,
		}),
	});
}
