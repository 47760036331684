import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../../authorization/hooks/useHasRight";

import SuperAdminApprovePricesView from "../../views/SuperAdminApprovePricesView/SuperAdminApprovePricesView";

export default function SuperAdminApprovePrices() {
	const hasRight = useHasRight();
	const { needsApproval } = useOutletContext();

	if (!hasRight("prices.admin")) {
		return <Unauthorized />;
	}

	const { prices, loading, error } = needsApproval;

	return <SuperAdminApprovePricesView prices={prices} loading={loading} error={error} />;
}
