import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { version } from "../../helpers/version.json";

export default function Footer() {
	const { t } = useTranslation();

	const year = useMemo(() => new Date().getFullYear(), []);

	return (
		<footer className="site-footer">
			Astrid {version} by Earselect © {year} &bull;{" "}
			<a href="mailto:info@earselect.se" className="site-footer-contact">
				{t("contact", "Contact")}
			</a>{" "}
			/ <Link to="/policy">{t("integrity", "Integrity")} &amp; GDPR</Link>
		</footer>
	);
}
