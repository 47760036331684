import firebase from "firebase/app";

import { Button, Card, Input, Modal } from "semantic-ui-react";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import useAsyncFn from "react-use/lib/useAsyncFn";

import { db } from "astrid-firebase";
import { updateDocument } from "astrid-firestore/src/helpers";
import { getDocumentData } from "astrid-firestore/src/helpers/index";
import useGenderOptions from "astrid-web/src/features/account/views/AccountSettingsView/hooks/useGenderOptions";
import useNotificationOptions from "astrid-web/src/features/account/views/AccountSettingsView/hooks/useNotificationOptions";
import ErrorMessage from "astrid-web/src/features/ui/components/Messages/ErrorMessage";

import { formatDate } from "../../../../../helpers/date";
import Form from "../../../../forms/components/Form/Form";
import useForm from "../../../../forms/hooks/useForm";

import useVisibleContactDetailsOptions from "../hooks/useVisibleContactDetailsOptions";

const adminChangeEmail = firebase.functions().httpsCallable("adminChangeEmail");

async function isSuperAdmin(user) {
	const auth = firebase.auth();
	const currentUserId = auth.currentUser?.uid;
	if (user.id !== currentUserId) {
		const currentUser = await getDocumentData(db.collection("users").doc(currentUserId));
		return ["owner", "admin"].includes(currentUser.permissions?.role);
	}
}

function ConfirmEmailChange({ newEmail, onSubmit, onClose, setError }) {
	const { t } = useTranslation();
	const [currentPassword, setCurrentPassword] = useState("");

	const [{ loading }, updateEmail] = useAsyncFn(async () => {
		try {
			const auth = firebase.auth();
			const currentUser = auth.currentUser;
			const currentEmail = currentUser.email;
			const credential = firebase.auth.EmailAuthProvider.credential(currentEmail, currentPassword);
			await currentUser.reauthenticateWithCredential(credential);
			await currentUser.updateEmail(newEmail);
			try {
				await onSubmit();
			} catch (error) {
				await currentUser.updateEmail(currentEmail);
			}
			onClose();
		} catch (error) {
			console.error(error);
			setError(error);
			onClose();
		}
	}, [currentPassword, onSubmit]);

	return (
		<>
			<Modal.Header>{t("confirmUpdateOfEmail", "Confirm update of email")}</Modal.Header>
			<Modal.Content>
				<p>
					{t("confirmPasswordForEmail", "Please enter your current password to update your email address.")}
				</p>
				<Input
					type="password"
					placeholder="Current Password"
					value={currentPassword}
					onChange={(e) => setCurrentPassword(e.target.value)}
					fluid
				/>
			</Modal.Content>
			<Modal.Actions>
				<Button secondary loading={loading} onClick={onClose} content={t("cancel", "Cancel")} />
				<Button primary loading={loading} onClick={updateEmail} content={t("confirm", "Confirm")} />
			</Modal.Actions>
		</>
	);
}

export default function AccountGeneral({ user }) {
	const { t } = useTranslation();
	const genderOptions = useGenderOptions();
	const notificationOptions = useNotificationOptions();
	const visibleContactDetailsOptions = useVisibleContactDetailsOptions();

	const [confirmEmailChangeOpen, setConfirmEmailChangeOpen] = useState(false);

	const [error, setError] = useState(false);

	const country = Object.values(user.vendors || {})[0]?.country || "SE";

	const form = useForm({
		defaultValues: {
			dateOfBirth: formatDate(user.dateOfBirth) || "",
			email: user.email || "",
			firstName: user.firstName || "",
			gender: user.gender || null,
			img: user.img || "",
			lastName: user.lastName || "",
			notifications: user.notifications || "none",
			phoneNumber: user.phone || "",
			visibleContactDetails: user.visibleContactDetails || false,
		},
	});

	const { handleSubmit, formState, watch, reset } = form;

	const { isSubmitting, isDirty, dirtyFields } = formState;
	const email = watch("email");

	const onSubmit = async ({
		firstName,
		lastName,
		phoneNumber = "",
		email,
		notifications,
		img,
		gender,
		dateOfBirth,
		visibleContactDetails,
	}) => {
		try {
			await updateDocument(user.ref, {
				firstName,
				lastName,
				phone: phoneNumber,
				email,
				notifications,
				img,
				gender,
				"readerData.sex": gender,
				dateOfBirth,
				"readerData.birthday": dateOfBirth ? new Date(dateOfBirth) : null,
				"readerData.contactDetails": visibleContactDetails,
				visibleContactDetails,
			});

			reset({
				firstName,
				lastName,
				phoneNumber,
				email,
				notifications,
				img,
				gender,
				dateOfBirth,
				visibleContactDetails,
			});
		} catch (error) {
			console.error(error);
			setError(error);
			throw error;
		}
	};

	const onSave = handleSubmit(async (form) => {
		setError(false);
		if (dirtyFields?.email) {
			const isSuper = await isSuperAdmin(user);
			if (isSuper) {
				try {
					await adminChangeEmail({ userId: user.id, newEmail: email });
					return onSubmit(form);
				} catch (error) {
					setError(error);
				}
			} else {
				setConfirmEmailChangeOpen(true);
			}
		} else {
			return onSubmit(form);
		}
	});

	return (
		<>
			<Card fluid>
				<Card.Content>
					<Card.Header>{t("generalSettings", "General")}</Card.Header>
				</Card.Content>
				<Card.Content extra>
					<Form form={form}>
						<Form.Group widths="equal">
							<Form.Input name="firstName" label={t("firstName", "First name")} />
							<Form.Input name="lastName" label={t("lastName", "Last name")} />
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Input name="email" label={t("email", "Email")} />
							<Form.PhoneInput
								name="phoneNumber"
								label={t("telephoneNr", "Phone number")}
								defaultCountry={country}
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Select name="gender" label={t("gender", "Gender")} options={genderOptions} />
							<Form.DateInput
								name="dateOfBirth"
								label={t("dateOfBirth", "Date of birth")}
								formatDate={formatDate}
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Select
								name="notifications"
								label={t("sendEmailNotifications", "Send email notifications from Astrid")}
								options={notificationOptions}
							/>
							<Form.Select
								name="visibleContactDetails"
								label={t("visibleContactDetails", "Make my contact details visible")}
								options={visibleContactDetailsOptions}
								rules={{ required: false }}
							/>
						</Form.Group>
					</Form>
					{error && (
						<ErrorMessage error={error}>{t("errorMessageHeader", "Something went wrong")}</ErrorMessage>
					)}
				</Card.Content>
				<Card.Content extra>
					<Button loading={isSubmitting} disabled={isSubmitting || !isDirty} primary onClick={onSave}>
						{t("save", "Save")}
					</Button>
				</Card.Content>
			</Card>

			<Modal
				open={confirmEmailChangeOpen}
				onClose={() => setConfirmEmailChangeOpen(false)}
				size="tiny"
				dimmer="blurring"
			>
				<ConfirmEmailChange
					newEmail={email}
					onSubmit={handleSubmit(onSubmit)}
					onClose={() => setConfirmEmailChangeOpen(false)}
					setError={setError}
				/>
			</Modal>
		</>
	);
}
