import { useDebounce } from "use-debounce";

import React from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline/index";

import ArtifactAudioPlayerFile from "./ArtifactAudioPlayerFile";

export default function ArtifactAudioPlayerTrack({ audioFiles }) {
	const [scope] = useDebounce(Timeline.useScope(), 300);

	return (
		<>
			{audioFiles
				.filter((file) => {
					return file.stop > scope.start && file.start < scope.end;
				})
				.map((file, index) => {
					return <ArtifactAudioPlayerFile key={index} file={file} />;
				})}
		</>
	);
}
