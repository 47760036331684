import eventTarget from "astrid-helpers/src/eventTarget";

import createDocumentRef from "../utils/createDocumentRef";
import getCollectionData from "../utils/getCollectionData";
import getCollectionDataById from "../utils/getCollectionDataById";
import getCollectionQuery from "../utils/getCollectionQuery";
import getDocumentData from "../utils/getDocumentData";

import DataParser from "./DataParser";
import FirestoreTransaction from "./FirestoreTransaction";
import createFirestoreUtils from "./createFirestoreUtils";

export default class FirestoreCollection {
	static Schema;
	static collectionName;
	static softDeletes = true;

	constructor(api) {
		this.Schema = this.constructor.Schema;
		this.softDeletes = this.constructor.softDeletes;
		this.collectionName = this.constructor.collectionName;

		this.api = api;
		this.debug = api.debug;
		this.plugins = api.plugins;
		this.firebase = api.firebase;
		this.firestore = api.firestore;
		this.collection = api.firestore.collection(this.collectionName);

		this.dataParser = new DataParser(this.Schema);

		this.events = eventTarget();
		this.utils = createFirestoreUtils(this.firebase, { debug: this.debug });

		for (const plugin of this.plugins) {
			for (const key of Object.keys(plugin)) {
				this[key] = plugin[key].bind(this);
			}
		}
	}

	// Preprocess

	preProcess(data) {
		return data;
	}

	parseData(data, options) {
		const preProcessedData = this.preProcess(data);

		return this.dataParser.parse(preProcessedData, options);
	}

	parseDocumentData({ data, validate, partial }) {
		return this.parseData(data, { validate, partial });
	}

	// Optimistic Updates

	getOptimisticUpdates(documents, { transaction }) {
		return documents
			.map((document) => transaction.getOptimisticUpdate(document.ref))
			.filter((update) => update?.isUpdated());
	}

	// Side Effects

	sideEffects() {
		return null;
	}

	applySideEffects(documents, { transaction, ...options }) {
		const updates = this.getOptimisticUpdates(documents, { transaction });

		return Promise.all(
			updates.map(async (update) => {
				await this.sideEffects({ update, transaction, ...options });

				return update?.after;
			}),
		);
	}

	// Transactions

	transact(documents, { transaction, ...options } = {}) {
		transaction = new FirestoreTransaction(this.firebase, { transaction, debug: this.debug });

		for (const document of documents) {
			const { ref, method } = document;
			const data = this.parseDocumentData(document);

			transaction.push({ ref, method, data });
		}

		return transaction.run(async (transaction) => {
			// await validateUniqueKeys(documents);

			return this.applySideEffects(documents, { transaction, ...options });
		});
	}

	// Set

	set({ method = "set", ref, data, validate = true, partial = false, ...options }) {
		const documents = this.transact([{ method, ref, data, validate, partial }], options);

		return (
			documents
				?.then?.((documents) => documents?.[0])
				.then((document) => {
					this.events.emit("set", { document });

					return document;
				}) || documents?.[0]
		);
	}

	setAll(documents, { data, method = "set", validate, partial, ...options } = {}) {
		return this.transact(
			documents.map((document) => ({ method, ref: document.ref, data: data || document, partial, validate })),
			options,
		);
	}

	// Create

	createRef(id) {
		return createDocumentRef(this.collection, id);
	}

	createData(data, { parent } = {}) {
		const collection = parent?.ref?.collection(this.collectionName) || this.collection;

		const ref = createDocumentRef(collection, data.id);

		return {
			id: ref.id,
			ref,
			...data,
			created: this.firebase.firestore.FieldValue.serverTimestamp(),
			createdBy: this.api.getUser(),
		};
	}

	create(initialData, { parent, ...options } = {}) {
		const { ref, ...data } = this.createData(initialData, { parent });

		return this.set({ method: "create", ref, data, ...options });
	}

	createAll(documents, { parent, ...options } = {}) {
		return this.setAll(
			documents.map((data) => this.createData(data, { parent })),
			{ method: "create", ...options },
		);
	}

	// Update

	update({ ref, ...data }, { partial = true, ...options } = {}) {
		return this.set({ ref, data, partial, method: "update", ...options });
	}

	updateAll(documents, { data, partial = true, ...options } = {}) {
		return this.setAll(documents, { data, partial, method: "update", ...options });
	}

	// Complex

	createOrUpdate(document, data, options) {
		if (!document.exists) {
			return this.create({ ...document, ...data }, options);
		} else {
			return this.update({ ref: document.ref, ...data }, options);
		}
	}

	// Delete

	delete(document, options) {
		if (this.softDeletes) {
			return this.softDelete(document, options);
		}

		return this.hardDelete(document, options);
	}

	deleteAll(documents, options) {
		if (this.softDeletes) {
			return this.softDeleteAll(documents, options);
		}

		return this.hardDeleteAll(documents, options);
	}

	softDelete(document, options) {
		return this.update(this.getSoftDeleteData(document), options);
	}

	softDeleteAll(documents, options) {
		return this.updateAll(documents, { data: (document) => this.getSoftDeleteData(document), ...options });
	}

	hardDelete({ ref }, options) {
		return this.update({ ref }, { method: "delete", ...options });
	}

	hardDeleteAll(documents, options) {
		return this.updateAll(
			documents.map(({ ref }) => ({ ref })),
			{ method: "delete", ...options },
		);
	}

	getSoftDeleteData(document) {
		return {
			ref: document.ref,
			deleted: true,
			deletedAt: this.firebase.firestore.FieldValue.serverTimestamp(),
			deletedBy: this.api.getUser(),
		};
	}

	// Getters

	getRef(id) {
		return id && this.collection.doc(id);
	}

	getByRef(ref, getter = getDocumentData) {
		return getter(ref);
	}

	getById(id, getter) {
		return this.getByRef(this.getRef(id), getter);
	}

	getQuery(query = (query) => query) {
		if (typeof query === "function") {
			return getCollectionQuery(query(this.collection), { softDeletes: this.softDeletes });
		}

		return getCollectionQuery(this.collection, { fields: query, softDeletes: this.softDeletes });
	}

	getAll(query, getter = getCollectionData, options) {
		return getter(this.getQuery(query), options);
	}

	getAllByIds(ids = [], getter = getCollectionDataById) {
		return getter(this.firebase, this.collection, ids);
	}

	getAllByRefs(refs = []) {
		return this.getAllByIds(refs.map((ref) => ref.id));
	}
}
