import { useTranslation } from "react-i18next";

import themeColors from "../../ui/constants/themeColors";

export default function usePublisherProductionStatistics({ organization, publishers, activePublisherId }) {
	const { t } = useTranslation();

	const publisher = publishers?.find(({ id }) => id === activePublisherId);

	const allPublisherStatistics = publishers
		?.map((publisher) => publisher.producers?.[organization.id]?.statistics?.productions)
		.filter(Boolean);

	const allStatistics = allPublisherStatistics?.reduce(
		(acc, { internal, external }) => ({
			...acc,
			internal: Object.entries(internal || {}).reduce(
				(acc, [key, value]) => ({ ...acc, [key]: (acc[key] || 0) + value }),
				acc.internal || {},
			),
			external: Object.entries(external || {}).reduce(
				(acc, [key, value]) => ({ ...acc, [key]: (acc[key] || 0) + value }),
				acc.external || {},
			),
		}),
		{},
	);

	const statistics = publisher ? publisher.producers[organization.id].statistics.productions : allStatistics;

	if (!statistics) {
		return [];
	}

	return [
		{
			total: statistics.internal?.drafts + statistics.external?.drafts,
			details: [
				{
					text: t("internalPlural", "Internal"),
					icon: "sign-in",
					amount: statistics.internal?.drafts,
				},
				{
					text: t("external", "External"),
					icon: "sign-out",
					amount: statistics.external?.drafts,
				},
			],
			color: "grey",
			detailColor: themeColors.GREY,
			label: t("drafts", "Drafts"),
			link: "productions/drafts",
			organization: organization,
		},
		{
			total: statistics.internal?.requests + statistics.external?.requests,
			details: [
				{
					text: t("internalPlural", "Internal"),
					icon: "sign-in",
					amount: statistics.internal?.requests,
				},
				{
					text: t("external", "External"),
					icon: "sign-out",
					amount: statistics.external?.requests,
				},
			],
			color: "orange",
			detailColor: themeColors.ORANGE,
			label: t("myProductionsRequests", "Requests"),
			link: "productions/requests",
			organization: organization,
		},
		{
			total: statistics.internal?.ongoing + statistics.external?.ongoing,
			details: [
				{
					text: t("internal", "Internal"),
					icon: "sign-in",
					amount: statistics.internal?.ongoing,
				},
				{
					text: t("external", "External"),
					icon: "sign-out",
					amount: statistics.external?.ongoing,
				},
			],
			color: "blue",
			detailColor: themeColors.BLUE,
			label: t("ongoing", "Ongoing"),
			link: "productions/ongoing",
			organization: organization,
		},
		{
			total: statistics.internal?.completed + statistics.external?.completed,
			details: [
				{
					text: t("internal", "Internal"),
					icon: "sign-in",
					amount: statistics.internal?.completed,
				},
				{
					text: t("external", "External"),
					icon: "sign-out",
					amount: statistics.external?.completed,
				},
			],
			color: "green",
			detailColor: themeColors.GREEN,
			label: t("completedPlural", "Completed"),
			link: "productions/completed",
			organization: organization,
		},
		{
			total: statistics.internal?.unfinalized + statistics.external?.unfinalized,
			details: [
				{
					text: t("internal", "Internal"),
					icon: "sign-in",
					amount: statistics.internal?.unfinalized,
				},
				{
					text: t("external", "External"),
					icon: "sign-out",
					amount: statistics.external?.unfinalized,
				},
			],
			color: "black",
			detailColor: themeColors.BLACK,
			label: t("unfinalized", "Unfinalized"),
			link: "productions/unfinalized",
			organization: organization,
		},
	];
}
