import { Loader } from "semantic-ui-react";

import { useEffect } from "react";

import Form from "../Form/Form";

export default function FormAutoSave({ onSubmit }) {
	const { formState } = Form.useFormContext();

	const { isDirty, isSubmitting } = formState;

	useEffect(() => {
		if (isDirty) {
			onSubmit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDirty]);

	if (!isSubmitting) {
		return null;
	}

	return <Loader active size="large" />;
}
