import getCollectionQuery from "../../utils/getCollectionQuery";

export default function getArticlesQuery(firebase, { publisher, production, bundle, imprint, serie, limit }) {
	return getCollectionQuery(firebase.firestore().collection("articles"), {
		fields: {
			publisher,
			production,
			bundle,
			imprint,
			serie,
		},
		limit,
		softDeletes: true,
	});
}
