import VerticalMenu from "../../../ui/components/VerticalMenu/VerticalMenu";
import PageView from "../../../ui/views/PageView/PageView";

import useAdminMenuOptions from "./hooks/useAdminMenuOptions";

export default function AdminView({ organization, children }) {
	const options = useAdminMenuOptions({ organization });

	return (
		<PageView header={organization.name} sidebar={<VerticalMenu options={options} />}>
			{children}
		</PageView>
	);
}
