import { useMemo } from "react";

import contributorRoles from "astrid-firestore/src/api/articles/constants/contributorRoles";

import useArticleContributorRolesTranslations from "./useArticleContributorRolesTranslations";

export default function useArticleContributorRolesOptions() {
	const translations = useArticleContributorRolesTranslations();

	return useMemo(
		() => [
			{
				key: "narrator",
				text: translations[contributorRoles.NARRATOR],
				value: contributorRoles.NARRATOR,
			},
			{
				key: "author",
				text: translations[contributorRoles.AUTHOR],
				value: contributorRoles.AUTHOR,
			},
			{
				key: "translator",
				text: translations[contributorRoles.TRANSLATOR],
				value: contributorRoles.TRANSLATOR,
			},
		],
		[translations],
	);
}
