import FirestoreLifecycle from "../../types/FirestoreLifecycle";

export default function softDeleteDocument(firebase, refOrDocument, user) {
	const ref = refOrDocument.ref || refOrDocument;

	return ref.update(
		FirestoreLifecycle.partial().parse({
			deleted: true,
			deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
			deletedBy: user,
		}),
	);
}
