import { useParams } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import AdminPageView from "../components/AdminPage/AdminPageView";
import AdminAgreementTemplateView from "../views/AdminAgreementTemplateView/AdminAgreementTemplateView";

export default function AdminAgreementTemplate() {
	const { id } = useParams();

	const hasRight = useHasRight();

	if (!hasRight("agreements.view")) {
		return <Unauthorized />;
	}

	return (
		<AdminPageView id={id}>
			<AdminAgreementTemplateView />
		</AdminPageView>
	);
}
