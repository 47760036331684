import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import auth from "astrid-firebase/src/auth";

import { setActAsUser, useActAsUser } from "../../../../authentication/state/actAsUser";
import { useProfile } from "../../../../authentication/state/profile";
import useHasRight from "../../../../authorization/hooks/useHasRight";
import useHasRole from "../../../../authorization/hooks/useHasRole";
import useOrganizationsOnce from "../../../../organizations/hooks/useOrganizationsOnce";
import { useOrganizationId } from "../../../../organizations/state/organizationId";
import { useOrganizationType } from "../../../../organizations/state/organizationType";
import useGroupBy from "../../../../ui/hooks/useGroupBy";

export default function useSecondaryMenuOptions() {
	const { t } = useTranslation();

	const hasRole = useHasRole();
	const profile = useProfile();
	const navigate = useNavigate();
	const hasRight = useHasRight();
	const actAsUser = useActAsUser();
	const orgId = useOrganizationId();
	const orgType = useOrganizationType();

	const publisherIds = useMemo(() => Object.keys(profile?.permissions?.publisher || {}), [profile]);
	const publishersById = useGroupBy(useOrganizationsOnce({ ids: publisherIds }), "id");

	return useMemo(
		() => [
			[
				{
					to: `/profile/${profile?.id}`,
					icon: "user outline",
					text: t("profile"),
				},
				{
					to: `/account/${profile?.id}/settings`,
					icon: "setting outline",
					text: t("account", "Account"),
				},
			],

			[
				...Object.keys(profile?.permissions?.producer || {})
					.filter((id) => hasRight("menuOption.ProducerSettings") && id === orgId)
					.map((id) => ({
						to: `/settings/${id}`,
						text: t("prodComp"),
					})),

				...Object.keys(profile?.permissions?.publisher || {}).map((id, i) => ({
					to: `/publisher/${id}`,
					text: publishersById?.[id]?.[0]?.name || `${t("profilePublisher")} (${i})`,
				})),
			],
			[
				{
					to: "/billing",
					text: t("billing"),
					hidden: !(hasRight("producerBilling.createPublisherInvoice") && orgId === "HfvG4FQfXWpWv6dzqM5E"),
				},
				{
					to: "/storytool-archive",
					text: `${t("storytoolArchive", "Storytool archive")}`,
					hidden: !profile?.permissions?.storytoolArchive?.enableArchiveAccess && !hasRole("owner", "admin"),
				},
				{
					to: "/distribution",
					text: t("distribution"),
					hidden: !hasRight("distribution.editDistributors"),
				},
				{
					to: "/upload",
					text: t("uploading"),
					hidden: !hasRight("distribution.editDistributors"),
				},
				{
					to: "/production",
					text: `${t("productions")} (old)`,
					hidden: !(hasRight("listProducerProductions") || hasRight("listPublisherProductions")),
				},
			],
			[
				{
					to: "/studio-redirect",
					icon: "headphones outline",
					text: t("astridStudio"),
					hidden: !hasRight("studioSession.connect"),
				},
				{
					to: "/apps",
					icon: "download outline",
					text: t("apps"),
					hidden: !hasRight("apps.view"),
				},
			],
			[
				{
					text: t("profileLogout"),
					hidden: !!actAsUser,
					onClick: () => {
						auth.signOut();
						navigate("/");
					},
				},
				{
					text: t("actAsUserLogout", "Stop acting as user"),
					hidden: !actAsUser,
					onClick: () => {
						setActAsUser();
						navigate(`/admin/users/${actAsUser}`);
					},
				},
			],
		],
		[t, profile, hasRight, orgType, orgId, hasRole, actAsUser, publishersById, navigate],
	);
}
