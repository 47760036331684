import firebase from "firebase/app";

import React from "react";
import { useTranslation } from "react-i18next";

import updateDistributionChannel from "astrid-firestore/src/api/distribution/updateDistributionChannel";
import { Pipeline } from "astrid-firestore/src/api/pipelines/types/Pipeline";

import AdminForm from "../../../admin/components/AdminForm/AdminForm";
import { useProfile } from "../../../authentication/state/profile";
import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

import PipelineSteps from "./components/PipelineSteps";

export default function PipelineForm({ header, channel, pipeline }) {
	const user = useProfile();
	const { t } = useTranslation();

	const form = useSimpleForm({
		schema: Pipeline,
		defaultValues: {
			name: pipeline.name || "",
			steps: pipeline.steps || null,
		},
		onSubmit: (data) => {
			return updateDistributionChannel(firebase, {
				ref: channel.ref,
				pipelines: { [pipeline.id]: data },
				updatedBy: user,
			});
		},
	});

	return (
		<AdminForm header={header} {...form}>
			<Form.Input name="name" label={t("name", "Name")} />

			<PipelineSteps name="steps" label={t("steps", "Steps")} />
		</AdminForm>
	);
}
