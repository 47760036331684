import AdminPageView from "../../components/AdminPage/AdminPageView";

import AdminArticleHeader from "./components/AdminArticleHeader";

export default function AdminArticleView({ organization, article }) {
	return (
		<AdminPageView organization={organization} article={article}>
			<AdminArticleHeader article={article} />
		</AdminPageView>
	);
}
