import React from "react";
import { useTranslation } from "react-i18next";

import VerticalMenu from "../../../ui/components/VerticalMenu/VerticalMenu";
import PageView from "../../../ui/views/PageView/PageView";

import useSuperAdminApproveMenuOptions from "./hooks/useSuperAdminApproveMenuOptions";

export default function SuperAdminApproveView({ needsApproval, ...props }) {
	const { t } = useTranslation();

	const options = useSuperAdminApproveMenuOptions({ needsApproval });

	return (
		<PageView
			header={t("needsApproval", "Needs approval")}
			sidebar={<VerticalMenu options={options} />}
			{...props}
		/>
	);
}
