import React from "react";
import { useTranslation } from "react-i18next";

import { useProfile } from "../../authentication/state/profile";

import AccountOrdersView from "../views/AccountOrdersView/AccountOrdersView";

export default function AccountOrders({ expense }) {
	const { t } = useTranslation();

	const user = useProfile();

	return <AccountOrdersView header={t("POs")} user={user} expense={expense} />;
}
