import { OrganizationRef } from "astrid-firestore/src/api/organizations/types/OrganizationRef";
import { ProductionRef } from "astrid-firestore/src/api/productions/types/Production";
import { getDocumentData } from "astrid-firestore/src/helpers";
import arrayChunk from "astrid-helpers/src/arrayChunk";

import createDocumentIdAndRef from "./createDocumentIdAndRef";
import createMarkers from "./createMarkers";
import createStorageFile from "./createStorageFile";

export default async function createAudioArtifact({ api, env }, { production, article, articleType = "total" }) {
	const master = await getDocumentData(
		api[env].db.collection("productions").doc(production.id).collection("meta").doc("master"),
	);

	let artifactSize = 0;
	let artifactFiles = [];
	for (const chunk of arrayChunk(production.master[articleType].files, 10)) {
		const files = await Promise.all(
			chunk.map(async (fileId) => {
				const file = master[fileId];
				const fallbackBucket = env === "live" ? "earselect-static" : "stage-earselect-static";
				const bucketName = file.mastered?.bucket || file.original.bucket || fallbackBucket;
				const [metadata] = await api[env].getStorageMetadata(
					bucketName,
					file.mastered?.path || file.original.path,
				);
				return createStorageFile({
					file: {
						name: file.name,
						fullPath: file.mastered?.path || file.original.path,
						url: file.mastered?.url || file.original.url,
						size: Number(metadata.size),
						duration: file.duration,
					},
					metadata,
				});
			}),
		);
		artifactSize += files.reduce((size, file) => size + Number(file.size), 0);
		artifactFiles = artifactFiles.concat(files);
	}

	const markers = await createMarkers({ api, env }, { production, articleType });

	const { id, ref } = createDocumentIdAndRef({ api, env }, { collection: "artifacts" });

	return {
		id,
		ref,
		article,
		files: artifactFiles,
		markers: markers || [],
		isDefault: true,
		name: production.title,
		producer: OrganizationRef.parse(article.producer),
		production: ProductionRef.parse(article.production),
		publisher: OrganizationRef.parse(article.publisher),
		size: artifactSize,
	};
}
