import articleTypes from "astrid-firestore/src/api/articles/constants/articleTypes";
import { ArticleRef } from "astrid-firestore/src/api/articles/types/ArticleRef";

import createAudioArtifact from "./createAudioArtifact";
import createChannels from "./createChannels";
import createCover from "./createCover";
import createDocumentIdAndRef from "./createDocumentIdAndRef";
import secondsToDuration from "./secondsToDuration";

export default async function createAudiobook({ api, env }, { production, commonArticleData, ebookArticle }) {
	const { id, ref } = createDocumentIdAndRef({ api, env }, { collection: "articles" });
	const article = {
		id,
		ref,
		type: articleTypes.AUDIOBOOK,
		name: production.title,
		isbn: production.isbn,
		linkedArticle: ebookArticle ? ArticleRef.parse(ebookArticle) : null,
		synopsis: production.synopsis || null,
		...commonArticleData,
	};
	const cover = await createCover({ api, env }, { img: production.img, imgThumb: production.imgThumb });
	const artifact = await createAudioArtifact({ api, env }, { production, article });
	const duration = secondsToDuration(artifact.files.reduce((sum, file) => sum + file.duration, 0));
	const channels = await createChannels({ api, env }, { production, articleType: "total" });

	return {
		...article,
		artifact,
		cover,
		duration,
		channels,
		channelIds: Object.keys(channels),
	};
}
