import { Button } from "semantic-ui-react";

import useAsyncFn from "react-use/lib/useAsyncFn";

import { firebase } from "astrid-firebase";
import pipelineStatuses from "astrid-firestore/src/api/distribution/constants/pipelineStatuses";

const retryPipeline = firebase.functions().httpsCallable("distribution-retryPipeline");

export default function RetryPipeline({ document, collectionId }) {
	const [{ loading }, onClickRetry] = useAsyncFn(async () => {
		return await retryPipeline({ documentId: document.id, collectionId });
	}, []);

	return document?.jobId && document?.status === pipelineStatuses.FAILED ? (
		<Button basic color="green" size="mini" content={"Retry"} loading={loading} onClick={onClickRetry} />
	) : null;
}
