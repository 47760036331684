import { Segment } from "semantic-ui-react";

import msToTime from "astrid-helpers/src/msToTime";

import Accordion from "../../../../ui/components/Accordion/Accordion";

import PipelineSteps from "./PipelineSteps";

export default function PipelineStepContent({ step, progress }) {
	const args = step.args
		? JSON.stringify(
				Object.keys(step.args)
					.sort()
					.reduce((orderedObj, key) => {
						orderedObj[key] = step.args[key];
						return orderedObj;
					}, {}),
		  )
		: "n/a";

	const stepProgress = progress?.[step.key];
	return (
		<Accordion.Content active>
			<div style={{ marginBottom: 10 }}>
				<Segment inverted style={{ marginBottom: 7 }}>
					<pre style={{ margin: 0 }}>
						<>
							{`Args: ${args}`}
							<br />
							{`Execution time: ${msToTime(stepProgress?.executionTime || 0)}`}
							<br />
							{!!stepProgress?.speed && `Speed: ${stepProgress?.speed?.toFixed(3) || 0} Mbit/s`}
							<br />
							{!!stepProgress?.processed &&
								`Processed chunks: ${(stepProgress?.processed / 1024 / 1024)?.toFixed(2)} MB`}
							<br />
							{stepProgress?.error && `Error: ${stepProgress.error}`}
						</>
					</pre>
				</Segment>

				{step.steps && <PipelineSteps steps={step.steps} progress={progress} />}
			</div>
		</Accordion.Content>
	);
}
