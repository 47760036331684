import dot from "dot-object";
import uniqid from "uniqid";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import Accordion from "../../../../ui/components/Accordion/Accordion";

import PipelineStep from "./PipelineStep";

function recursivelyDuplicateKeys(steps) {
	if (steps.steps) {
		return {
			...steps,
			key: uniqid(),
			steps: steps.steps.map((step) => {
				return recursivelyDuplicateKeys(step);
			}),
		};
	}
	return {
		...steps,
		key: uniqid(),
	};
}

export default function PipelineSteps({ name, label }) {
	const { t } = useTranslation();
	const [activeIndexes, setActiveIndexes] = useState([]);

	const { formState, getValues, setValue } = Form.useFormContext();
	const { fields, update, append, remove, swap } = Form.useFieldArray({ name });

	const errors = dot.pick(name, formState.errors);

	const indexes = name.split(".").filter((i) => i !== "steps");

	const id = [...indexes.map((index) => Number(index) + 1), fields.length + 1].join("-");

	const onClick = (index) => {
		if (activeIndexes.includes(index)) {
			setActiveIndexes(activeIndexes.filter((activeIndex) => activeIndex !== index));
		} else {
			setActiveIndexes(activeIndexes.concat(index));
		}
	};

	const onReorder = (from, to) => {
		if (to >= 0 && to < fields.length) {
			swap(from, to);
		} else if (to === -1) {
			// Move up one level
			const pathLevels = name.split(".");
			const currentPath = `${name}.${from}`;
			const grandparentPath = pathLevels.slice(0, -2).join(".");
			const stepToMove = getValues(currentPath);
			const grandparentStep = getValues(grandparentPath);
			const updatedGrandparentStep = [...(grandparentStep || []), stepToMove];
			remove(from);
			setValue(grandparentPath, updatedGrandparentStep);
		} else if (to === fields.length) {
			// Move down one level
			const currentPath = `${name}.${from}`;
			const stepToMove = getValues(currentPath);
			const grandchildPath = `${name}.0.steps`;
			const grandchildStep = getValues(grandchildPath);
			const updatedGrandchildStep = [stepToMove, ...(grandchildStep || [])];
			remove(from);
			setValue(grandchildPath, updatedGrandchildStep);
		}
	};

	const onAppend = () => {
		append({
			key: uniqid(),
			action: null,
			args: null,
			name: `step-${id}`,
			needs: null,
			condition: null,
			steps: null,
			disabled: false,
		});
	};

	const onRemove = (index) => {
		remove(index);
	};

	const onDisabled = (index) => {
		const data = getValues(`${name}.${index}`);
		update(index, { ...data, disabled: !data.disabled });
	};

	const onDuplicate = (index) => {
		const data = getValues(`${name}.${index}`);
		const duplicatedData = recursivelyDuplicateKeys(data);
		update(fields.length, duplicatedData);
	};

	const style = {
		border: `2px solid ${activeIndexes !== null ? "#2185d0" : "grey"}`,
		boxShadow: "0 1px 2px 0 rgba(34,36,38,.15)",
		marginTop: 0,
	};

	return (
		<div className="field">
			{label && <label>{label}</label>}

			<Accordion styled fluid style={style}>
				{fields.map((field, index) => (
					<PipelineStep
						key={field.id}
						id={field.id}
						index={index}
						fields={fields}
						error={errors?.[index]}
						name={`${name}.${index}`}
						active={activeIndexes.includes(index)}
						onClick={onClick}
						onRemove={onRemove}
						onReorder={onReorder}
						onDisabled={onDisabled}
						onDuplicate={onDuplicate}
					/>
				))}

				<Accordion.Footer icon="plus" onClick={onAppend}>
					{t("addStep", "Add step")}
				</Accordion.Footer>
			</Accordion>
		</div>
	);
}
