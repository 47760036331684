import { Card } from "semantic-ui-react";

import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";

export default function AdminCard({ header, error, children, extra }) {
	return (
		<Card fluid>
			{header && (
				<Card.Content>
					<Card.Header style={{ fontSize: "1.2em" }}>{header}</Card.Header>
				</Card.Content>
			)}

			<Card.Content>
				{children}

				{error && <ErrorMessage error={error} />}
			</Card.Content>

			{extra && <Card.Content>{extra}</Card.Content>}
		</Card>
	);
}
