import getArticlePreset from "./getArticlePreset";

function mergeTargetData({ preset, articleChannelData }) {
	return Object.keys(preset?.targetData || {}).reduce((acc, presetTargetDataKey) => {
		const overriddenSetting = articleChannelData?.[presetTargetDataKey];

		if (overriddenSetting) {
			return {
				...acc,
				[presetTargetDataKey]: overriddenSetting,
			};
		}
		return {
			...acc,
			[presetTargetDataKey]: preset?.targetData?.[presetTargetDataKey],
		};
	}, {});
}

function mergeDistributionDates({ articleGlobalDates, channelSpecificDates }) {
	const result = { ...(articleGlobalDates || {}) };

	for (const key in channelSpecificDates) {
		if (channelSpecificDates[key] !== null) {
			result[key] = channelSpecificDates[key];
		}
	}

	return result;
}

export default function mergeArticleChannelDataWithPresetChannelData({ channelIds, article, publisher }) {
	return channelIds.reduce((acc, channelId) => {
		const articleChannelData = article.channels[channelId];
		const mergedDistributionDates = mergeDistributionDates({
			articleGlobalDates: article?.distribution,
			channelSpecificDates: articleChannelData?.distribution,
		});
		const preset = getArticlePreset({ channelId, article, publisher });
		const mergedTargetData = mergeTargetData({ preset, articleChannelData });

		return {
			...acc,
			[channelId]: {
				...articleChannelData,
				...mergedTargetData,
				distribution: mergedDistributionDates,
			},
		};
	}, {});
}
