import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useAsyncFn from "react-use/lib/useAsyncFn";
import { Button, Message, Modal } from "semantic-ui-react";

import { firebase } from "astrid-firebase";
import copyWordsToOtherProduction from "astrid-firestore/src/api/words/copyWordsToOtherProduction";

import FlexRow from "../../../../components/Flex/FlexRow";
import InstantSearchQuery from "../../../ui/components/InstantSearch/InstantSearchQuery";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import { useAlgoliaSearchClient } from "../../../algolia/state/algoliaSearchClient";
import { useOrganizationId } from "../../../organizations/state/organizationId";

export default function CopyWordsToOtherProductionModal({ fromProduction, words, onClose }) {
	const { t } = useTranslation();
	const orgId = useOrganizationId();
	const searchClient = useAlgoliaSearchClient();
	const [copyToProduction, setCopyToProduction] = useState(undefined);
	const [message, setMessage] = useState(undefined);

	const [{ loading }, doCopy] = useAsyncFn(async () => {
		try {
			setMessage(undefined);
			await copyWordsToOtherProduction(firebase, {
				fromProductionId: fromProduction.id,
				copyToProductionId: copyToProduction.id,
				words,
			});
			setMessage({
				type: "success",
				title: copyToProduction.title,
				url: `/production/${copyToProduction?.id}/audiobook`,
			});
		} catch (error) {
			setMessage({ type: "error" });
			console.error(error);
		} finally {
			setCopyToProduction(null);
		}
	}, [fromProduction.id, copyToProduction, words]);

	return (
		<LoadingContext loading={!searchClient}>
			<Modal.Header>{t("copyPronunciationList", "Copy pronunciation list to other production")}</Modal.Header>
			<Modal.Content>
				<FlexRow style={{ gap: 10, alignItems: "center" }}>
					<div>
						{`${t("copy", "Copy")} ${t("from", "From").toLowerCase()}`}
						<strong>&nbsp;{fromProduction.title}</strong>
						&nbsp;{t("to", "to")}
					</div>
					<InstantSearchQuery
						indexName="productions"
						placeholder={t("search", "Search...")}
						getOption={({ objectID, title }) => {
							return {
								key: objectID,
								value: objectID,
								title: title,
							};
						}}
						configure={{
							restrictSearchableAttributes: ["title"],
						}}
						filters={`producer:${orgId}`}
						onResultSelect={(e, { result }) => {
							setCopyToProduction({ id: result.value, title: result.title });
							setMessage(undefined);
						}}
					/>
				</FlexRow>
				{message && (
					<Message
						success={message.type === "success"}
						error={message.type === "error"}
						content={
							message.type === "success" ? (
								<>
									{`${t(
										"successfullyCopiedPronunciationList",
										"Successfully copied pronunciation list to: ",
									)} ${t("to", "to")}: `}
									<Link to={message.url}>{message.title}</Link>
								</>
							) : (
								t("errorMessageHeader")
							)
						}
					/>
				)}
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={onClose}>{t("close")}</Button>
				<Button primary loading={loading} disabled={!copyToProduction} onClick={doCopy}>
					{t("copy", "Copy")}
				</Button>
			</Modal.Actions>
		</LoadingContext>
	);
}
