import merge from "lodash/merge";

import { Button, Dropdown, Form, Header, Modal } from "semantic-ui-react";

import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import distConfig from "astrid-config/src/distributors";
import prodGenres from "astrid-config/src/genres/storytel";
import languages from "astrid-config/src/languages";
import { db, firebase } from "astrid-firebase";
import { getDistOptions } from "astrid-web/src/components/production/fnc";
import { MetaPopup } from "astrid-web/src/components/production/parts/MetaGuide";
import { withStore } from "astrid-web/src/helpers/context";

import Tags from "../../../features/productions/components/Tags/Tags";

import PersonDropdown from "../PersonDropdown";
import ThemaField from "../ThemaField";

class ProductionInformationEdit extends Component {
	state = {};

	componentDidMount() {
		this.getDistributors();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.production.language !== this.props.production.language ||
			prevProps.production.publisher !== this.props.production.publisher ||
			prevProps.production.producer !== this.props.production.producer
		) {
			this.getDistributors();
		}
	}

	getDistributors = () => {
		// check if publisher has access to distributor (in the cloud, for privacy)
		const { production } = this.props;

		if (production.producer) {
			const publisherHasDistributors = firebase.functions().httpsCallable("publisherHasDistributors");
			this.setState({ distributors: false });
			publisherHasDistributors({
				producer: production.producer,
				publisher: production.publisher,
				language: production.language,
			}).then(({ data }) => {
				this.setState({ distributors: data });
			});
		}
	};

	handleChange = (e, data, meta) => {
		if (e) {
			// cache keystroke events, firestore update is debounced
			this.setState({ cachedUpdate: { [data.name]: data.value } });
		}

		const metaUpdated = meta || (!Array.isArray(data) && !["languageExtra"].includes(data.name));

		this.props.handleChange(e, data, { metadata: metaUpdated });
	};

	render() {
		const { cachedUpdate, series, distributors } = this.state;
		const { t, onClose, alerts, publisher, permissions } = this.props;

		const production = cachedUpdate ? merge(this.props.production, cachedUpdate) : this.props.production;
		const { rights: productionRights } = permissions;

		// get relevant distributors based on current articles and distribution options
		const distOptions = getDistOptions({ production, publisher }) || {};
		let relevantDistributors = [];
		if (distOptions) {
			if (typeof production.isbn === "string") relevantDistributors.push(...(distOptions.total || []));
			if (production.deliveryEbook) relevantDistributors.push(...(distOptions.ebook || []));
			if (production.deliveryParts) relevantDistributors.push(...(distOptions.part || []));

			relevantDistributors = [...new Set(relevantDistributors)];
		}

		// figure out extra meta fields
		const extraMetaFields =
			distributors &&
			Object.entries(distConfig)
				.filter(
					([dist, { meta, disabled }]) =>
						distributors[dist] &&
						meta &&
						meta.sharedFields &&
						!disabled &&
						relevantDistributors.includes(dist),
				)
				.reduce((prev, curr) => {
					const fields = (curr[1].meta && curr[1].meta.sharedFields) || [];
					return [...prev, ...fields];
				}, []);

		// get translated genres if available
		const prodGenresLanguage = (production.language && prodGenres[production.language]) || prodGenres.en;
		const prodGenresThema = prodGenres.thema;

		return (
			<Modal open size="large" centered={false} onClose={onClose}>
				<Modal.Content>
					<Form as="div">
						<Header as="h4" icon="info circle" content={t("information")} />

						{/* only show production language if no default exists OR multiple options exists */}
						{publisher &&
							((!publisher.languageDefault && !publisher.languageOptions) ||
								(publisher.languageOptions && !!publisher.languageOptions.length)) && (
								<MetaPopup id="language" text={t("specifyProdMainLanguage")}>
									<Dropdown
										button
										className="icon"
										floating
										pointing
										labeled
										inline
										icon="flag"
										style={{ marginBottom: "1em" }}
										search
										deburr
										name={"language"}
										value={production.language}
										error={!!alerts.language}
										text={
											t("prodLanguage") +
											(production.language
												? ": " +
												  t("language:" + production.language) +
												  //(languages[production.language].sv ||
												  //languages[production.language].name) +
												  " (" +
												  languages[production.language].nativeName +
												  ")"
												: "")
										}
										options={Object.keys(languages)
											.filter(
												(code) =>
													!publisher.languageOptions ||
													!publisher.languageOptions.length ||
													publisher.languageOptions.includes(code) ||
													publisher.languageDefault === code,
											)
											.map((code) => ({
												key: code,
												value: code,
												text:
													t("language:" + code) +
													//(languages[code].sv || languages[code].name) +
													" (" +
													languages[code].nativeName +
													")",
											}))}
										onChange={(e, data) => {
											this.handleChange(null, data);
										}}
										onOpen={(e) => {
											// stupid hack to maintain width while selecting
											e.currentTarget.style.minWidth = e.currentTarget.offsetWidth + "px";
										}}
										onClose={(e) => {
											if (e && e.currentTarget)
												e.currentTarget.closest(".dropdown").style.minWidth = "";
										}}
									/>
								</MetaPopup>
							)}

						<Form.Group widths="equal">
							<Form.Input
								fluid
								label={t("title")}
								name={"title"}
								type={"text"}
								disabled={!productionRights.includes("createProduction")}
								className={!productionRights.includes("createProduction") ? "locked-field" : null}
								value={production["title"] || ""}
								error={!!alerts.title}
								onChange={this.handleChange}
								onBlur={(e) => {
									this.handleChange(e, {
										name: "title",
										value: e.target.value || "",
										type: "text",
									});
								}}
							/>
							<MetaPopup id="author" text={t("specifyAuthor")}>
								{production.publisher && (
									<PersonDropdown
										label={t("author")}
										name={"author"}
										value={production["author"]}
										error={!!alerts.author}
										options={production["authorOptions"]}
										limitPublisher={production.publisher}
										disabled={!productionRights.includes("createProduction")}
										onChange={(value, options) => {
											const newData = [
												{ name: "author", value },
												{ name: "authorOptions", value: options },
											];

											if (value) {
												const authorWithLanguage =
													options &&
													options.length &&
													options.find((option) => option.language);

												if (authorWithLanguage) {
													const authorLanguage = authorWithLanguage.language;

													if (!production.languageOriginal) {
														newData.push({
															name: "languageOriginal",
															value: authorLanguage,
														});
													}
													if (
														!production.languageExtra &&
														production.language !== authorLanguage
													) {
														newData.push({
															name: "languageExtra",
															value: [authorLanguage],
														});
													}
												}
											}
											this.handleChange(null, newData, true);
										}}
									/>
								)}
							</MetaPopup>

							<Form.Dropdown
								selection
								search
								deburr
								name={"languageOriginal"}
								value={production.languageOriginal || ""}
								label={t("languageOriginal")}
								options={Object.keys(languages).map((code) => ({
									key: code,
									value: code,
									text:
										t("language:" + code) +
										//(languages[code].sv || languages[code].name) +
										" (" +
										languages[code].nativeName +
										")",
								}))}
								onChange={this.handleChange}
							/>

							{production.language &&
								production.languageOriginal &&
								production.language !== production.languageOriginal && (
									<MetaPopup id="titleOriginal" text={t("specifyTitleOriginal")}>
										<Form.Input
											fluid
											label={t("titleOriginal")}
											name={"titleOriginal"}
											type={"text"}
											disabled={!productionRights.includes("createProduction")}
											className={
												!productionRights.includes("createProduction") ? "locked-field" : null
											}
											value={production["titleOriginal"] || ""}
											error={!!alerts.titleOriginal}
											onChange={this.handleChange}
											onBlur={(e) => {
												this.handleChange(e, {
													name: "titleOriginal",
													value: e.target.value || "",
													type: "text",
												});
											}}
										/>
									</MetaPopup>
								)}

							{production.language &&
								production.languageOriginal &&
								production.language !== production.languageOriginal && (
									<MetaPopup id="translator" text={t("specifyBookTranslator")}>
										<PersonDropdown
											label={t("translator")}
											name={"translator"}
											value={production["translator"]}
											error={!!alerts.translator}
											options={production["translatorOptions"]}
											limitPublisher={production.publisher}
											disabled={!productionRights.includes("createProduction")}
											onChange={(value, options) => {
												const newData = [
													{ name: "translator", value },
													{ name: "translatorOptions", value: options },
												];

												if (value) {
													const authorWithLanguage =
														options &&
														options.length &&
														options.find((option) => option.language);

													if (authorWithLanguage) {
														const authorLanguage = authorWithLanguage.language;

														if (!production.languageOriginal) {
															newData.push({
																name: "languageOriginal",
																value: authorLanguage,
															});
														}
														if (
															!production.languageExtra &&
															production.language !== authorLanguage
														) {
															newData.push({
																name: "languageExtra",
																value: [authorLanguage],
															});
														}
													}
												}
												this.handleChange(null, newData, true);
											}}
										/>
									</MetaPopup>
								)}
						</Form.Group>
						<Form.Group widths="equal">
							{[
								{ label: t("serie"), key: "series" },
								{ label: t("part"), key: "part", type: "number" },
							].map((inp) =>
								inp.key !== "series" ? (
									<Form.Input
										fluid
										label={inp.label}
										key={inp.key}
										name={inp.key}
										type={inp.type || "text"}
										disabled={!productionRights.includes("createProduction")}
										className={
											!productionRights.includes("createProduction") ? "locked-field" : null
										}
										value={production[inp.key] !== undefined ? production[inp.key] : ""}
										onChange={this.handleChange}
										onBlur={(e) => {
											this.handleChange(e, {
												name: inp.key,
												value: e.target.value || "",
												type: inp.type || "text",
											});
										}}
									/>
								) : (
									<Form.Select
										label={inp.label}
										search
										allowAdditions
										additionLabel={t("createSerie") + ": "}
										key={inp.key}
										name={inp.key}
										value={production[inp.key] || ""}
										disabled={!productionRights.includes("createProduction")}
										className={
											!productionRights.includes("createProduction") ? "locked-field" : null
										}
										options={[
											{ key: "DELETE", value: "DELETE", text: t("noSerie") },
											...(production[inp.key] && !series?.includes(production[inp.key])
												? [
														{
															// fake key value to avoid error message while loading production data
															key: production[inp.key] || "FAKE",
															value: production[inp.key],
															text: production[inp.key],
														},
												  ]
												: []),
											...(series || []).map((serie) => ({
												key: serie,
												value: serie,
												text: serie,
											})),
										].sort()}
										loading={this.state.loadingSeries}
										onOpen={(e) => {
											if (!production?.author?.length) return;

											this.setState({ loadingSeries: true });

											db.collection("productions")
												.where("author", "array-contains-any", production.author)
												.get()
												.then((data) => {
													const series = data.docs
														.filter((d) => d.data().series)
														.map((d) => d.data().series);

													const uniqueSeries = [...new Set(series)];
													this.setState({ loadingSeries: false, series: uniqueSeries });
												});
										}}
										onChange={(e, data) => {
											if (!data.value) return;
											if (data.value === "DELETE") data.value = "";
											this.handleChange(null, data);
										}}
										onAddItem={(e, data) => {
											this.handleChange(null, data);
										}}
									/>
								),
							)}

							<MetaPopup id="genre" text={t("specifyGenre")}>
								<Form.Select
									label={t("genre")}
									search
									multiple
									name={"genre"}
									value={production.genre || []}
									error={!!alerts.genre}
									disabled={!productionRights.includes("createProduction")}
									className={!productionRights.includes("createProduction") ? "locked-field" : null}
									options={Object.keys(prodGenresLanguage).map((key) => ({
										key: key,
										value: key,
										text: t(key),
									}))}
									onChange={(e, { name, value }) => {
										const newData = [{ name, value }];

										// set thema mapping
										const themaArray = production.genreEditeur || [];
										value.forEach((genre) => {
											const thema = prodGenresThema[genre];
											if (!themaArray.includes(thema)) {
												themaArray.push(thema);
											}
										});
										if (themaArray.length) {
											newData.push({ name: "genreEditeur", value: themaArray });
										}

										this.handleChange(null, newData);
									}}
								/>
							</MetaPopup>
							<Form.Dropdown
								selection
								search
								deburr
								multiple
								name={"languageExtra"}
								value={production.languageExtra || []}
								label={t("otherLanguages")}
								options={Object.keys(languages).map((code) => ({
									key: code,
									value: code,
									text:
										t("language:" + code) +
										//(languages[code].sv || languages[code].name) +
										" (" +
										languages[code].nativeName +
										")",
								}))}
								onChange={(e, data) => {
									this.handleChange(null, data);
								}}
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<MetaPopup id="synopsis" text={t("specifySynopsis")}>
								<Form.TextArea
									label={t("backsideTextOrSynopsis")}
									value={production.synopsis}
									name="synopsis"
									onChange={this.handleChange}
									error={!!alerts.synopsis}
								/>
							</MetaPopup>
						</Form.Group>
						{extraMetaFields && !!extraMetaFields.length && (
							<>
								{extraMetaFields?.includes?.("abridged") && (
									<Form.Group>
										<div className={"inline-toggle " + (!!production.abridged ? "on" : "off")}>
											{t("abridgedPublication")} :{" "}
											<span className="inline-toggle-label off">{t("unAbrigaded")}</span>
											<Form.Checkbox
												toggle
												name={"abridged"}
												checked={!!production.abridged}
												onChange={(e, data) => {
													this.handleChange(e, { name: data.name, value: data.checked });
												}}
											/>
											<span className="inline-toggle-label on">{t("abrigaded")}</span>
										</div>
									</Form.Group>
								)}
								<Form.Group widths="equal">
									{extraMetaFields.includes("tags") && (
										<MetaPopup id="tags" text={t("specifyTagsForSearch")}>
											<Tags production={production} />
										</MetaPopup>
									)}
									{(extraMetaFields?.includes?.("thema") || extraMetaFields?.includes?.("bisac")) && (
										<ThemaField
											production={production}
											productionRights={productionRights}
											handleChange={this.handleChange}
											bisac={extraMetaFields.includes("bisac")}
										/>
									)}
								</Form.Group>
							</>
						)}
					</Form>
				</Modal.Content>
				<Modal.Actions>
					<Button content={t("close")} onClick={onClose} />
				</Modal.Actions>
			</Modal>
		);
	}
}

export default withTranslation(["common", "language"])(withStore(ProductionInformationEdit));
