import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { firebase } from "astrid-firebase";
import articleTypes from "astrid-firestore/src/api/articles/constants/articleTypes";
import linkedArticleTypes from "astrid-firestore/src/api/articles/constants/linkedArticleTypes";
import { Article } from "astrid-firestore/src/api/articles/types/Article";
import updateArticle from "astrid-firestore/src/api/articles/updateArticle";
import { createImprintData } from "astrid-firestore/src/api/imprints/createImprintData";
import { createSerieData } from "astrid-firestore/src/api/series/createSerieData";

import api from "../../../../../api";
import ArticleTags from "../../../../articles/components/ArticleTags/ArticleTags";
import ArticlesInstantSearchSelect from "../../../../articles/components/ArticlesInstantSearchSelect/ArticlesInstantSearchSelect";
import useArticleAbridgedOptions from "../../../../articles/hooks/useArticleAbridgedOptions";
import useArticleContributorRolesOptions from "../../../../articles/hooks/useArticleContributorRolesOptions";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";
import ThemaModal from "../../../../thema/components/ThemaModal";
import useNumberOptions from "../../../../ui/hooks/useNumberOptions";

import AdminForm from "../../../components/AdminForm/AdminForm";

export default function AdminArticleMetaDataForm({ article, locked, ...props }) {
	const { publisher } = article;

	const { t } = useTranslation();

	const numberOptions = useNumberOptions();
	const abridgedOptions = useArticleAbridgedOptions();
	const contributorRolesOptions = useArticleContributorRolesOptions();

	const [seriesOptions] = api.series.useOptions({ publisher });
	const [imprintOptions] = api.imprints.useOptions({ publisher });

	const [openModal, setOpenModal] = useState(false);

	const form = useSimpleForm({
		schema: Article,
		values: {
			ref: article.ref,
			abridged: article.abridged || false,
			additionalLanguages: article.additionalLanguages || null,
			contributors: article.contributors || [],
			duration: article.duration || null,
			episodeName: article.episodeName || null,
			imprint: article.imprint || null,
			isbn: article.isbn || null,
			language: article.language || null,
			linkedArticle: article.linkedArticle || null,
			name: article.name || null,
			originalLanguage: article.originalLanguage || null,
			originalName: article.originalName || null,
			pages: article.pages || null,
			serie: article.serie || null,
			serieNumber: article.serieNumber || null,
			synopsis: article.synopsis || null,
			tags: article.tags || [],
			themaCodes: article.themaCodes || null,
		},
		onSubmit: (data) => {
			return updateArticle(firebase, data);
		},
		...props,
	});

	const { watch, setValue } = form;

	const [themaCodes, language] = watch(["themaCodes", "language"]);

	return (
		<AdminForm header={t("metaData", "Meta data")} {...form}>
			<Form.Group widths="equal">
				<Form.Input name="isbn" label={t("isbn", "ISBN")} />

				<Form.Input name="name" label={t("name", "Name")} />

				<Form.Input name="originalName" label={t("originalName", "Original name")} />
			</Form.Group>

			<Form.Group widths="equal">
				<Form.DocumentSelect
					name="imprint"
					label={t("imprint", "Imprint")}
					search
					allowEmpty
					allowAdditions
					options={imprintOptions}
					onAddDocument={(name) => {
						return createImprintData(firebase, { name, publisher });
					}}
				/>

				<ArticlesInstantSearchSelect
					name="linkedArticle"
					label={t("linkedArticle", "Linked article")}
					fluid
					allowEmpty
					clearable
					publisher={publisher}
					type={linkedArticleTypes[article.type]}
				/>

				<Form.Select name="abridged" label={t("abridged", "Abridged")} options={abridgedOptions} />
			</Form.Group>

			<Form.TextArea name="synopsis" label={t("synopsis", "Description")} />

			<Form.Group widths="equal">
				<Form.DocumentSelect
					name="serie"
					label={t("serie", "Series")}
					search
					allowEmpty
					allowAdditions
					options={seriesOptions}
					onAddDocument={(name) => {
						return createSerieData(firebase, { name, publisher });
					}}
				/>

				<Form.Select
					name="serieNumber"
					search
					label={t("seriesPart", "Series (part)")}
					allowEmpty
					options={numberOptions}
				/>

				<Form.Input name="episodeName" label={t("episodeName", "Episode name")} />
			</Form.Group>

			<Form.Group widths="equal">
				<Form.LanguageSelect name="language" label={t("language", "Language")} clearable />

				<Form.LanguageSelect
					name="originalLanguage"
					label={t("originalLanguage", "Original language")}
					clearable
				/>

				<Form.LanguageSelect
					name="additionalLanguages"
					label={t("otherLanguages", "Other occuring languages")}
					filterOptions={(option) => option.value !== language}
					multiple
					clearable
				/>
			</Form.Group>

			<Form.Group widths="equal">
				<Form.DurationInput
					name="duration"
					label={t("duration", "Duration") + " (hhh:mm:ss)"}
					disabled={article.type === articleTypes.EBOOK}
				/>

				<Form.NumberInput name="pages.start" label={t("startPage", "Start page")} />

				<Form.NumberInput name="pages.end" label={t("endPage", "End page")} />
			</Form.Group>

			<Form.Group widths="equal">
				<Form.Select
					multiple
					name="themaCodes"
					label={t("themaCodes", "Thema codes")}
					options={themaCodes?.map((code) => ({
						key: code,
						text: code,
						value: code,
					}))}
					allowEmpty
					onClick={() => !locked && setOpenModal(true)}
				/>

				<Form.Field name="tags">
					<ArticleTags article={article} />
				</Form.Field>
			</Form.Group>

			<Form.FormFieldArrayTable name="contributors" header={t("contributors", "Contributors")}>
				{({ role, firstName, lastName }, index) => (
					<Form.Group widths="equal" style={{ margin: 0 }}>
						<Form.Select
							name={`contributors.${index}.role`}
							value={role}
							placeholder={t("role", "Role")}
							options={contributorRolesOptions}
						/>
						<Form.Input
							name={`contributors.${index}.firstName`}
							value={firstName}
							placeholder={t("firstName", "First name")}
						/>
						<Form.Input
							name={`contributors.${index}.lastName`}
							value={lastName}
							placeholder={t("lastName", "Last name")}
						/>
					</Form.Group>
				)}
			</Form.FormFieldArrayTable>

			{openModal && (
				<ThemaModal
					value={themaCodes}
					onClose={({ themaCodes }) => {
						setValue("themaCodes", themaCodes, { shouldDirty: true });
						setOpenModal(false);
					}}
				/>
			)}

			<Form.Blocker />
		</AdminForm>
	);
}
