import { Outlet, useParams } from "react-router-dom";

import { db } from "astrid-firebase";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import SuperAdminDistributionChannelView from "../../views/SuperAdminDistributionChannelView/SuperAdminDistributionChannelView";

export default function SuperAdminDistributionChannel() {
	const params = useParams();

	const [channel, loading, error] = useDocumentData(db.collection("distributionChannels").doc(params.channel));

	return (
		<LoadingContext data={channel} loading={loading} error={error}>
			<SuperAdminDistributionChannelView channel={channel}>
				<Outlet context={{ channel }} />
			</SuperAdminDistributionChannelView>
		</LoadingContext>
	);
}
