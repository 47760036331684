import PipelineStepContent from "./PipelineStepContent";
import PipelineStepTitle from "./PipelineStepTitle";

export default function PipelineStep({
	id,
	name,
	index,
	fields,
	active,
	error,
	onClick,
	onRemove,
	onReorder,
	onDisabled,
	onDuplicate,
}) {
	return (
		<>
			<PipelineStepTitle
				name={name}
				index={index}
				active={active}
				error={error}
				onClick={onClick}
				onRemove={onRemove}
				onReorder={onReorder}
				onDisabled={onDisabled}
				onDuplicate={onDuplicate}
			/>

			{active && <PipelineStepContent id={id} name={name} fields={fields} error={error} />}
		</>
	);
}
